import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import moment from 'moment';
import { XeleTable, TableColumnsFilter } from '../../../../Components';
import {
  TableActions,
  ActionsEnum,
  TableFilterTypesEnum,
} from '../../../../Enums';
import { TableColumnsFilterActions } from '../../../../store/TableColumnsFilter/TableColumnsFilterActions';
import { ActivityDeleteDialog } from './Dialogs/ActivityDeleteDialog/ActivityDeleteDialog';
import { ActivityHistory } from '../ActivityHistory/ActivityHistory';
import { isEmptyObject } from '../../../../Helper';
import { ReplyActivityDialog } from '../ReplyActivitesView/ReplyActivityDialog';
import { ActivitiesManagementDialog } from '../../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import { CopyToClipboard } from '../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboard';
import {
  GetActivityById,
  GetAllRelatedActivitiesByActivityId,
} from '../../../../Services/ActivitiesServices';

const parentTranslationPath = 'ActivitiesView';

export const ActivitiesTableView = ({
  data,
  onPageIndexChanged,
  onPageSizeChanged,
  filter,
  activitiesCount,
  isTableRelatedView,
  isLoading,
  setIsLoading,
  getTableActionsByPermissions,
  setSortBy,
  pathName,
  activeActionType,
  setIsReplay,
  onFilterValuesChanged,
  localStorageKey,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  let listFlattenObject = [];

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openReplyActivityDialog, setOpenReplyActivityDialog] = useState(false);
  const [activitesRelatedChildren, setActivitesRelatedChildren] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openActivityHistoryDialog, setOpenActivityHistoryDialog] =
    useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const tableColumnsFilterResponse = useSelector(
    (state) => state.TableColumnsFilterReducer
  );
  const [tableColumns, setTableColumns] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);

  const dispatch = useDispatch();
  const GetActivityAPI = useCallback(async (activityId) => {
    const res = await GetActivityById(activityId);
    if (!(res && res.status && res.status !== 200)) setActiveItem(res);
  }, []);
  const flattenObject = (obj) => {
    // eslint-disable-next-line prefer-const
    const flattened = {};
    Object.keys(obj).forEach((key) => {
      if (
        typeof obj[key] === 'object' &&
        obj[key] !== null &&
        key === 'relatedActivityTo'
      ) {
        if (obj[key]) {
          if (!isEmptyObject(obj[key])) listFlattenObject.push({ ...obj[key] });
          Object.assign(flattened, flattenObject(obj[key]));
        }
      } else flattened[key] = obj[key];
    });
  };

  const focusedRowChanged = (activeRow) => {
    const item = data[activeRow];
    if (!item) return;
    setIsReplay(item.isReplyAble);
  };

  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData, type) => async (event) => {
      dispatch(
        ActiveItemActions.activeItemRequest({
          id: activeData.relatedLeadNumberId || '',
          ...activeData,
        })
      );
      event.stopPropagation();
      if (actionEnum === ActionsEnum.folder.key) {
        if (activeData.relatedLeadNumberId && type === 'lead') {
          const leadForm = [];
          const leadOprationType = [];
          if (leadOprationType === 'Sale')
            window
              .open(
                `/home/lead-sales/lead-profile-edit?formType=${leadForm}&id=${activeData.relatedLeadNumberId}`,
                '_blank'
              )
              .focus();
          else
            window
              .open(
                `/home/lead-lease/lead-profile-edit?formType=${leadForm}&id=${activeData.relatedLeadNumberId}`,
                '_blank'
              )
              .focus();
        } else if (activeData.relatedUnitNumberId && type === 'unit')
          window
            .open(
              `units-sales/unit-profile-edit?formType=1&id=${activeData.relatedUnitNumberId}&operationType=${activeData.operationType}`,
              '_blank'
            )
            .focus();
        else if (activeData.relatedContactNumberId && type === 'contact') {
          const contactType = (activeData && activeData.contactTypeId) || 1;
          window
            .open(
              `Contacts-CRM/contact-profile-edit?formType=${contactType}&id=${activeData.relatedContactNumberId}`,
              '_blank'
            )
            .focus();
        }
      }
    },
    [dispatch]
  );

  const GetRelatedActivitiesByActivityId = useCallback(
    async (activityId) => {
      setIsLoading(true);
      const res = await GetAllRelatedActivitiesByActivityId(activityId);
      if (!(res && res.status && res.status !== 200)) {
        listFlattenObject = [];
        flattenObject(res);
        setActivitesRelatedChildren(
          listFlattenObject.filter((item) => item.activityId)
        );
      } else setActivitesRelatedChildren([]);
      setIsLoading(false);
    },
    [activeItem]
  );
  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      if (actionEnum === TableActions.deleteText.key) {
        setOpenConfirmDialog(true);
        setActiveItem(item);
      } else if (actionEnum === TableActions.editText.key) {
        GetActivityAPI(item.activityId);
        setOpenDialog(true);
      } else if (actionEnum === TableActions.replyText.key) {
        setActiveItem(item);
        setOpenReplyActivityDialog(true);
      }
    },
    [GetActivityAPI]
  );

  const allActivitiesTableHeaderData = [
    {
      id: 1,
      label: 'activity-Date',
      input: 'activityDate',
      isDate: true,
      isSortable: true,
      dateFormat: 'DD/MM/YYYY',
      isDefaultFilterColumn: true,
    },
    {
      id: 2,
      label: 'created-Date',
      isDate: true,
      input: 'createdOn',
      isSortable: true,
      dateFormat: 'DD/MM/YYYY',
      isDefaultFilterColumn: true,
    },
    {
      id: 3,
      label: 'created-time',
      input: 'createdOn',
      isDate: true,
      dateFormat: ' hh:mm A',
      isDefaultFilterColumn: true,
      isHiddenFilter: true,
    },
    {
      id: 4,
      label: 'created-By',
      input: 'createdBy',
      component: (item) => <span>{item.createdByName}</span>,
      isDefaultFilterColumn: true,
    },
    {
      id: 5,
      label: 'related-to',
      input: 'relatedTo',
      isHiddenFilter: true,
      component: (item) => (
        <span className='c-primary'>
          {(item.relatedLeadNumberId &&
            item.relatedUnitNumberId &&
            t(`lead/unit`)) ||
            (item.relatedLeadNumberId && t(`lead`)) ||
            (item.relatedContactNumberId && t(`contact`)) ||
            (item.relatedUnitNumberId && t(`unit`)) ||
            (item && item.relatedPortfolioId && t(`Portfolio`)) ||
            (item.relatedMaintenanceContractId && t(`MaintenanceContract`)) ||
            (item.relatedWorkOrderRefNumber && t(`WorkOrder`)) ||
            (item.relatedUnitPropertyName && t(`Property`)) ||
            'N/A'}
        </span>
      ),
      isDefaultFilterColumn: true,
    },
    {
      id: 6,
      label: 'Related-to-Id',
      isHiddenFilter: true,
      component: (item) =>
        (item && item.relatedLeadNumberId && (
          <ButtonBase
            className='c-primary newTabActivites'
            onClick={detailedCardSideActionClicked(
              ActionsEnum.folder.key,
              item,
              'lead'
            )}
          >
            {(item && item.relatedLeadNumberId && (
              <CopyToClipboard
                data={item.relatedLeadNumberId}
                childrenData={item.relatedLeadNumberId}
                CustomizationClassName='block-container'
              />
            )) ||
              'N/A'}
          </ButtonBase>
        )) ||
        (item && item.relatedContactNumberId && (
          <a
            className='c-primary newTabActivites'
            onClick={detailedCardSideActionClicked(
              ActionsEnum.folder.key,
              item,
              'contact'
            )}
          >
            {(item && item.relatedContactNumberId && (
              <CopyToClipboard
                data={item.relatedContactNumberId}
                childrenData={item.relatedContactNumberId}
                CustomizationClassName='block-container'
              />
            )) ||
              'N/A'}
          </a>
        )) ||
        (item && item.relatedUnitNumberId && (
          <a
            className='c-primary newTabActivites'
            onClick={detailedCardSideActionClicked(
              ActionsEnum.folder.key,
              item,
              'unit'
            )}
          >
            {(item && item.relatedUnitNumberId && (
              <CopyToClipboard
                data={item.relatedUnitNumberId}
                childrenData={item.relatedUnitNumberId}
                CustomizationClassName='block-container'
              />
            )) ||
              'N/A'}
          </a>
        )) ||
        (item && item.relatedUnitNumberId && (
          <ButtonBase className='c-primary newTabActivites'>
            {(item && item && (
              <CopyToClipboard
                data={
                  (item && item.relatedUnitPropertyName) ||
                  (item && item.relatedPortfolioId) ||
                  (item && item.relatedMaintenanceContractId) ||
                  (item && item.relatedWorkOrderRefNumber) ||
                  (item && item.relatedWorkOrderId) ||
                  'N/A'
                }
                childrenData={
                  (item && item.relatedUnitPropertyName) ||
                  (item && item.relatedPortfolioId) ||
                  (item && item.relatedMaintenanceContractId) ||
                  (item && item.relatedWorkOrderRefNumber) ||
                  (item && item.relatedWorkOrderId) ||
                  'N/A'
                }
                CustomizationClassName='block-container'
              />
            )) ||
              'N/A'}
          </ButtonBase>
        )),
      isDefaultFilterColumn: true,
    },
    {
      id: 7,
      label: 'related-to-name',
      input: 'relatedToName',
      isHiddenFilter: true,
      component: (item) => (
        <span className='c-primary'>
          {(item.relatedLeadNumberId && item.relatedLeadFullName) ||
            (item.relatedContactNumberId && item.relatedContactFullName) ||
            (item.relatedUnitNumberId && item.relatedUnitName) ||
            (item.relatedUnitPropertyName && item.relatedUnitNumber) ||
            (item.relatedPortfolioId && item.relatedPortfolioName) ||
            (item.relatedWorkOrderId && item.relatedWorkOrderRefNumber) ||
            item.relatedMaintenanceContractId ||
            'N/A'}
        </span>
      ),
      isDefaultFilterColumn: true,
    },
    {
      id: 8,
      label: 'assign-to',
      input: 'assignedTo',
      isDefaultFilterColumn: true,
    },
    {
      id: 9,
      label: 'stage',
      input: 'stage',
      isDefaultFilterColumn: true,
      isHiddenFilter: false,
      component: (item) => <span>{(item && item.leadStage) || ''}</span> || '',
    },
    {
      id: 10,
      label: 'category',
      input: 'category',
      isDefaultFilterColumn: true,
      component: (item) =>
        <span>{(item && item.categoryName) || ''}</span> || '',
    },

    {
      id: 11,
      label: 'activity-type',
      input: 'activityType',
      isDefaultFilterColumn: true,
      component: (item) => <span>{(item && item.activityTypeName) || ''}</span>,
    },
    {
      id: 12,
      label: 'status',
      input: 'status',
      withSelectFilter: true,
      cellClasses: 'py-0',
      component: (item, index) =>
        (item.isOpen && (
          <div className='globel-open-style' index={index}>
            {t(`open`)}
          </div>
        )) || (
          <div className='globel-closed-style' index={index}>
            {t(`closed`)}
          </div>
        ),
      isDefaultFilterColumn: true,
    },
    {
      id: 13,
      label: 'copyTo',
      input: 'copyTo',
      isDefaultFilterColumn: true,
      component: (item) =>
        <span>{(item && item.copyToFullName) || ''}</span> || '',
    },
    {
      id: 14,
      label: 'comments',
      input: 'comments',
      isHiddenFilter: true,
      isDefaultFilterColumn: true,
    },
    {
      id: 15,
      label: 'propertyName',
      input: 'propertyName',
      isHiddenFilter: true,
      isDefaultFilterColumn: true,
    },
  ];

  const relatedActivitiesTableHeaderData = [
    {
      id: 1,
      label: 'history',
      input: 'relatedActivityToId',
      cellClasses: 'py-0',
      component: (item) =>
        item.relatedActivityToId && (
          <ButtonBase
            className='MuiButtonBase-root btns-icon  mt-1 mx-2'
            onClick={() => {
              GetRelatedActivitiesByActivityId(item.activityId);
              setActiveItem(item);
              setOpenActivityHistoryDialog(true);
            }}
          >
            <span className='table-action-icon mdi mdi-undo-variant' />
            <span className='MuiTouchRipple-root' />
          </ButtonBase>
        ),
      isHiddenFilter: true,
      isDefaultFilterColumn: true,
    },
    {
      id: 2,
      label: 'activity-Date',
      input: 'activityDate',
      isDate: true,
      isSortable: true,
      dateFormat: 'DD/MM/YYYY',
      isDefaultFilterColumn: true,
    },
    {
      id: 3,
      label: 'created-Date',
      isDate: true,
      isSortable: false,
      input: 'createdDate',
      dateFormat: 'DD/MM/YYYY',
      isDefaultFilterColumn: true,
      component: (item) =>
        (
          <span>
            {(item && moment(item.createdOn).format('YYYY-MM-DD')) || ''}
          </span>
        ) || '',
    },
    {
      id: 4,
      label: 'created-time',
      input: 'createdOn',
      isDate: true,
      dateFormat: ' hh:mm A',
      isDefaultFilterColumn: true,
      isHiddenFilter: true,
    },
    {
      id: 5,
      label: 'created-By',
      input: 'createdBy',
      component: (item) => <span>{item.createdByName}</span>,
      isDefaultFilterColumn: true,
    },
    {
      id: 6,
      label: 'related-to',
      input: 'relatedTo',
      isHiddenFilter: true,
      component: (item) => (
        <span className='c-primary'>
          {(item.relatedLeadNumberId &&
            item.relatedUnitNumberId &&
            t(`lead/unit`)) ||
            (item.relatedLeadNumberId && t(`lead`)) ||
            (item.relatedContactNumberId && t(`contact`)) ||
            (item.relatedUnitNumberId && t(`unit`)) ||
            (item.relatedPortfolioId && t(`Portfolio`)) ||
            (item.relatedMaintenanceContractId && t(`MaintenanceContract`)) ||
            (item.relatedWorkOrderRefNumber && t(`WorkOrder`)) ||
            (item.relatedUnitPropertyName && t(`Property`)) ||
            (item.relatedWorkOrderId && t(`WorkOrder`)) ||
            'N/A'}
        </span>
      ),
      isDefaultFilterColumn: true,
    },
    {
      id: 7,
      label: 'Related-to-Id',
      isHiddenFilter: true,
      component: (item) =>
        (item && item.relatedLeadNumberId && (
          <ButtonBase
            className='c-primary newTabActivites'
            onClick={detailedCardSideActionClicked(
              ActionsEnum.folder.key,
              item,
              'lead'
            )}
          >
            {(item && item.relatedLeadNumberId && (
              <CopyToClipboard
                data={item.relatedLeadNumberId}
                childrenData={item.relatedLeadNumberId}
                CustomizationClassName='block-container'
              />
            )) ||
              'N/A'}
          </ButtonBase>
        )) ||
        (item && item.relatedContactNumberId && (
          <ButtonBase
            className='c-primary newTabActivites'
            onClick={detailedCardSideActionClicked(
              ActionsEnum.folder.key,
              item,
              'contact'
            )}
          >
            {(item && item.relatedContactNumberId && (
              <CopyToClipboard
                data={item.relatedContactNumberId}
                childrenData={item.relatedContactNumberId}
                CustomizationClassName='block-container'
              />
            )) ||
              'N/A'}
          </ButtonBase>
        )) ||
        (item && item.relatedUnitNumberId && (
          <ButtonBase
            className='c-primary newTabActivites'
            onClick={detailedCardSideActionClicked(
              ActionsEnum.folder.key,
              item,
              'unit'
            )}
          >
            {(item && item.relatedUnitNumberId && (
              <CopyToClipboard
                data={item.relatedUnitNumberId}
                childrenData={item.relatedUnitNumberId}
                CustomizationClassName='block-container'
              />
            )) ||
              'N/A'}
          </ButtonBase>
        )) ||
        (item && item.relatedUnitNumberId && (
          <ButtonBase className='c-primary newTabActivites'>
            {(item && item && (
              <CopyToClipboard
                data={
                  (item && item.relatedUnitPropertyName) ||
                  (item && item.relatedPortfolioId) ||
                  (item && item.relatedMaintenanceContractId) ||
                  (item && item.relatedWorkOrderRefNumber) ||
                  (item && item.relatedWorkOrderId) ||
                  'N/A'
                }
                childrenData={
                  (item && item.relatedUnitPropertyName) ||
                  (item && item.relatedPortfolioId) ||
                  (item && item.relatedMaintenanceContractId) ||
                  (item && item.relatedWorkOrderRefNumber) ||
                  (item && item.relatedWorkOrderId) ||
                  'N/A'
                }
                CustomizationClassName='block-container'
              />
            )) ||
              'N/A'}
          </ButtonBase>
        )),
      isDefaultFilterColumn: true,
    },
    {
      id: 8,
      label: 'related-to-name',
      input: 'relatedToName',
      isHiddenFilter: true,
      component: (item) => (
        <span className='c-primary'>
          {(item.relatedLeadNumberId && item.relatedLeadFullName) ||
            (item.relatedContactNumberId && item.relatedContactFullName) ||
            (item.relatedUnitNumberId && item.relatedUnitName) ||
            (item.relatedUnitPropertyName && item.relatedUnitNumber) ||
            (item.relatedPortfolioId && item.relatedPortfolioName) ||
            (item.relatedWorkOrderId && item.relatedWorkOrderRefNumber) ||
            item.relatedMaintenanceContractId ||
            'N/A'}
        </span>
      ),
      isDefaultFilterColumn: true,
    },
    {
      id: 9,
      label: 'assign-to',
      input: 'assignedTo',
      isDefaultFilterColumn: true,
    },
    {
      id: 10,
      label: 'stage',
      input: 'stage',
      isDefaultFilterColumn: true,
      isHiddenFilter: false,
      component: (item) => <span>{(item && item.leadStage) || ''}</span> || '',
    },
    {
      id: 11,
      label: 'category',
      input: 'category',
      isDefaultFilterColumn: true,
      component: (item) =>
        <span>{(item && item.categoryName) || ''}</span> || '',
    },

    {
      id: 12,
      label: 'activity-type',
      input: 'activityType',
      isDefaultFilterColumn: true,
      component: (item) => <span>{(item && item.activityTypeName) || ''}</span>,
    },
    {
      id: 13,
      label: 'status',
      input: 'status',
      withSelectFilter: true,
      cellClasses: 'py-0',
      component: (item, index) =>
        (item.isOpen && (
          <div className='globel-open-style' index={index}>
            {t(`open`)}
          </div>
        )) || (
          <div className='globel-closed-style' index={index}>
            {t(`closed`)}
          </div>
        ),
      isDefaultFilterColumn: true,
      isHiddenFilter: false,
    },
    {
      id: 14,
      label: 'comments',
      input: 'comments',
      isHiddenFilter: true,
      isDefaultFilterColumn: true,
    },
    {
      id: 15,
      label: 'Copy-To',
      input: 'copyTo',
      isDefaultFilterColumn: true,
      component: (item) =>
        <span>{(item && item.copyToFullName) || ''}</span> || '',
    },
    {
      id: 16,
      label: 'propertyName',
      input: 'propertyName',
      isHiddenFilter: true,
      isDefaultFilterColumn: true,
    },
  ];

  const [activitiesTableHeaderData, setActivitiesTableHeaderData] = useState(
    relatedActivitiesTableHeaderData
  );

  const [selectedTableFilterColumns, setSelectedTableFilterColumns] = useState({
    tableRelatedView: relatedActivitiesTableHeaderData
      .filter((item) => item.isDefaultFilterColumn)
      .map((column) => column.id),
    tableView: allActivitiesTableHeaderData
      .filter((item) => item.isDefaultFilterColumn)
      .map((column) => column.id),
  });

  useEffect(() => {
    if (activeActionType && activeActionType === 'tableRelatedView')
      setActivitiesTableHeaderData(relatedActivitiesTableHeaderData);
    else if (activeActionType && activeActionType === 'tableView')
      setActivitiesTableHeaderData(allActivitiesTableHeaderData);
  }, [activeActionType]);

  useEffect(() => {
    setTableColumns([
      ...activitiesTableHeaderData.filter(
        (item) =>
          selectedTableFilterColumns[activeActionType].findIndex(
            (element) => element === item.id
          ) !== -1
      ),
    ]);
  }, [selectedTableFilterColumns, activeActionType, activitiesTableHeaderData]);

  useEffect(() => {
    setTableFilterData(
      tableColumns.map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType:
          (column.isDate && TableFilterTypesEnum.datePicker.key) ||
          (column.withSelectFilter && TableFilterTypesEnum.selectOption.key) ||
          TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        isHidden: column.isHidden,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        displayPath: column.input,
      }))
    );
  }, [tableColumns]);

  return (
    <div className='w-100 px-2'>
      <div className='w-100 px-3'>
        <TableColumnsFilter
          columns={activitiesTableHeaderData
            .filter((item) => !item.isHidden)
            .map((item) => ({
              key: item.formFieldId || item.id,
              value:
                (item.formFieldTitle && item.formFieldTitle.replace('*', '')) ||
                item.label,
            }))}
          isLoading={isLoading}
          selectedColumns={selectedTableFilterColumns[activeActionType]}
          onSelectedColumnsChanged={(newValue) => {
            let localTableColumnsFilterResponse = tableColumnsFilterResponse;

            setSelectedTableFilterColumns({
              ...selectedTableFilterColumns,
              [activeActionType]: newValue,
            });

            dispatch(
              TableColumnsFilterActions.TableColumnsFilterRequest(
                localTableColumnsFilterResponse
              )
            );
          }}
          parentTranslationPath={parentTranslationPath}
        />

        {!isTableRelatedView && (
          <XeleTable
            data={data}
            headerData={tableColumns}
            setSortBy={setSortBy}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            defaultActions={getTableActionsByPermissions || []}
            focusedRowChanged={focusedRowChanged}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            parentTranslationPath={parentTranslationPath}
            totalItems={activitiesCount}
            isWithFilter
            FilterDisabledButton
            onFilterValuesChanged={onFilterValuesChanged}
            localStorageKey={localStorageKey}
            filterData={tableFilterData}
            optionFilterName={[
              {
                key: '1',
                value: 'open',
              },
              {
                key: '0',
                value: 'close',
              },
            ]}
          />
        )}

        {isTableRelatedView && (
          <XeleTable
            data={data}
            headerData={tableColumns}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            defaultActions={getTableActionsByPermissions || []}
            focusedRowChanged={focusedRowChanged}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            parentTranslationPath={parentTranslationPath}
            totalItems={activitiesCount}
            setSortBy={setSortBy}
            isWithFilter
            FilterDisabledButton
            onFilterValuesChanged={onFilterValuesChanged}
            filterData={tableFilterData}
            optionFilterName={[
              {
                key: '1',
                value: 'open',
              },
              {
                key: '0',
                value: 'close',
              },
            ]}
            localStorageKey={localStorageKey}
          />
        )}
      </div>
      {openDialog && (
        <ActivitiesManagementDialog
          open={openDialog}
          activeItem={activeItem}
          onSave={() => {
            setOpenDialog(false);
            setActiveItem(null);
            onPageIndexChanged(0);
          }}
          close={() => {
            setActiveItem(null);
            setOpenDialog(false);
          }}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {openConfirmDialog && (
        <ActivityDeleteDialog
          isOpen={openConfirmDialog}
          activeItem={activeItem}
          reloadData={() => {
            setOpenDialog(false);
            setActiveItem(null);
            onPageIndexChanged(0);
          }}
          isOpenChanged={() => {
            setActiveItem(null);
            setOpenDialog(false);
          }}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {openActivityHistoryDialog && (
        <ActivityHistory
          isLoading={isLoading}
          open={openActivityHistoryDialog}
          close={() => {
            listFlattenObject = [];
            setActivitesRelatedChildren([]);
            setOpenActivityHistoryDialog(false);
          }}
          data={activitesRelatedChildren.reverse()}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {openReplyActivityDialog && (
        <ReplyActivityDialog
          open={openReplyActivityDialog}
          close={() => {
            setActiveItem(null);
            setOpenReplyActivityDialog(false);
          }}
          activeItem={activeItem}
          onSave={() => {
            setOpenReplyActivityDialog(false);
            setActiveItem(null);
            onPageIndexChanged(0);
          }}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};

ActivitiesTableView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  onPageIndexChanged: PropTypes.string.isRequired,
  onPageSizeChanged: PropTypes.string.isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  activitiesCount: PropTypes.number.isRequired,
  isTableRelatedView: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  getTableActionsByPermissions: PropTypes.arrayOf(PropTypes.instanceOf(Object))
    .isRequired,
  setSortBy: PropTypes.func.isRequired,
};

ActivitiesTableView.defaultProps = {
  isTableRelatedView: false,
};
