import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { XeleAutocomplete } from '../../../../../../Components';
import { GetAllRoles } from '../../../../../../Services/roleServices';

export const AssignedToComponent = ({
  parentTranslationPath,
  translationPath,
  helperText,
  setState,
  state,
  isSubmitted,
}) => {
  const { t } = useTranslation(parentTranslationPath,  {
    keyPrefix: translationPath,
  });
  const [Roles, setRoles] = useState({});
  const [searchedItem] = useState('');
  const [loadings, setLoadings] = useState(false);
  const [selected, setSelected] = useState([]);
  const searchTimer = useRef(null);
  const getRoles = useCallback(async (value) => {
    setLoadings(true);
    const response = await GetAllRoles(1, 25, value);
    setRoles({
      result: response?.result || [],
      totalCount: response?.totalCount || 0,
    });
    setLoadings(false);
  }, []);

  const searchHandler = (e) => {
    const { value } = e.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      getRoles(value);
    }, 700);
  };

  useEffect(() => {
    getRoles(searchedItem);
  }, [getRoles, searchedItem]);

  useEffect(() => {
    setSelected(
      state?.assignedToActivityTypeRoles?.map((x) => ({
        rolesId: x.rolesId,
        rolesName: x.rolesName || x.roleName,
      }))
    );
  }, [state]);

  return (
    <div>
      <XeleAutocomplete
        idRef='Assigned-ToRef'
        labelValue='Assigned-To'
        labelClasses='Requierd-Color'
        selectedValues={selected || []}
        multiple
        data={Roles?.result || []}
        chipsLabel={(option) => option?.rolesName || ''}
        displayLabel={(option) => t(`${option?.rolesName || ''}`)}
        getOptionSelected={(option) =>
          selected.findIndex((item) => item.rolesId === option.rolesId) !==
            -1 || ''
        }
        withoutSearchButton
        inputPlaceholder={t(`Select-multiple`)}
        isLoading={loadings}
        helperText={helperText}
        error={!!helperText}
        isSubmitted={isSubmitted}
        onInputKeyUp={(e) => searchHandler(e)}
        isWithError
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(event, newValue) => {
          const roles = newValue?.map((x) => ({
            rolesId: x.rolesId,
            rolesName: x.rolesName,
          }));
          setState(roles);
          setSelected(roles);
        }}
      />
    </div>
  );
};
AssignedToComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
};
