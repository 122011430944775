import React, {
  useRef,
  useReducer,
  useState,
  useCallback,
  useEffect,
} from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../../../../SharedComponents';
import { Avatar, ButtonBase } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {
  XeleInput,
  XeleTable,
  XeleAutocomplete,
  XeleDatePicker,
} from '../../../../../../Components';
import {
  getDownloadableLink,
  getFirstLastNameLetters,
  showError,
  showSuccess,
  GlobalNavigate,
} from '../../../../../../Helper';
import { UserActivationRejectDialog } from '../../Dialogs';
import { UpdatedUserStatus } from '../../../../../../Enums';
import {
  ApproveUserActivation,
  OrganizationUserSearch,
} from '../../../../../../Services/UsersServices';
import { GetAllBranches } from '../../../../../../Services/BranchesServices';

export const PendingActivationView = ({
  parentTranslationPath,
  translationPath,
  usersData,
  totalCount,
  setFilter,
  filter,
  reloadData,
  onPageSizeChanged,
  onPageIndexChanged,
  setSelected,
  selected,
  dateRangeDefault,
  initialFilter,
}) => {
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });
  const typingTimer = useRef(null);

  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [data, setData] = useReducer(reducer, {
    systemUsers: [],
    branches: [],
  });
  const [activeItem, setActiveItem] = useState(null);

  const getSystemUsers = async ({ name, userName }) => {
    try {
      const res = await OrganizationUserSearch({
        pageIndex: 0,
        pageSize: 10,
        name: name || '',
        userName: userName || '',
      });
      const localValue = (res && res.result) || [];
      setData({ id: 'systemUsers', value: localValue });
    } catch (error) {
      showError('something went wrong');
    }
  };

  const getBranches = async (branchName) => {
    try {
      const res = await GetAllBranches({
        pageSize: 10,
        pageIndex: 1,
        branchName,
      });
      setData({ id: 'branches', value: res.result || [] });
    } catch (error) {
      showError('something went wrong ');
    }
  };
  const onClearedAllFiltersClick = useCallback(() => {
    setFilter((currentFilter) => ({
      ...initialFilter,
      status: currentFilter.status,
    }));
    setSelected({
      id: 'edit',
      value: {
        lineManager: null,
        branch: null,
        userName: null,
        dateFilter: dateRangeDefault,
      },
    });
  }, []);
  const approveUserActivation = async (userId) => {
    const res = await ApproveUserActivation(userId);
    if (!(res && res.status && res.status !== 200)) {
      reloadData();
      showSuccess(t(`activate-approval-success`));
    } else showError(t(`activate-approval-failed`));
  };

  useEffect(() => {
    setFilter((items) => ({
      ...items,
      status: UpdatedUserStatus.PendingActivation.value || 1,
    }));
  }, []);
  return (
    <div className='w-80'>
      <div className='d-flex-column'>
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={'PendingActivation'}
          subTitle={'Pending-Activation-heading'}
          wrapperClasses={'px-4 w-50'}
          headerTitleClasses={'fz-16 mb-1'}
          subTitleClasses={'fz-14 m-0 ml-0'}
        />

        <div className='d-flex'>
          <div className='w-100 px-4 d-flex flex-wrap'>
            <div className='w-100 d-flex fa-center fa-start'>
              <XeleInput
                idRef='user-nameRef'
                labelClasses='mt-4'
                wrapperClasses='w-100 mt-2 mr-3'
                value={filter.userName || ''}
                onInputChanged={(event) => {
                  const userName = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    userName: userName || null,
                    userId: null,
                  }));
                }}
                inputPlaceholder={t(`full-Name`)}
              />
              <XeleInput
                idRef='mobileNumber-ref'
                labelClasses='mt-4'
                wrapperClasses='w-100 mt-2 mr-3'
                value={filter.mobileNumber || ''}
                onInputChanged={(event) => {
                  const mobileNumber = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    mobileNumber: mobileNumber || null,
                  }));
                }}
                inputPlaceholder={t(`mobile-Number`)}
              />
              <XeleInput
                idRef='Createdby-ref'
                labelClasses='mt-4'
                wrapperClasses='w-100 mt-2 mr-3'
                value={filter.createdBy || ''}
                onInputChanged={(event) => {
                  const createdBy = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    createdBy: createdBy || null,
                  }));
                }}
                inputPlaceholder={t(`created-by`)}
              />
              <XeleDatePicker
                wrapperClasses='mb-3 mr-3 w-100'
                labelValue={t(`Creation-Date`)}
                idRef='creationDateRef'
                placeholder='DD/MM/YYYY'
                value={filter.creationDate || null}
                onDateChanged={(newValue) => {
                  const creationDate =
                    (newValue &&
                      moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) ||
                    null;
                  setFilter((items) => ({
                    ...items,
                    creationDate: creationDate || null,
                  }));
                }}
              />
            </div>
            <div className='w-100 d-flex-center fa-start'>
              <XeleInput
                idRef='email-ref'
                labelClasses='mt-4'
                wrapperClasses='w-100 mt-2 mr-3'
                value={filter.email || ''}
                onInputChanged={(event) => {
                  const email = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    email: email || null,
                  }));
                }}
                inputPlaceholder={t(`email`)}
              />
              <XeleInput
                idRef='branch-NameRef'
                labelClasses='mt-4'
                wrapperClasses='w-100 mt-2 mr-3'
                value={filter.branchName || ''}
                onInputChanged={(event) => {
                  const branchName = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    branchName: branchName || null,
                  }));
                }}
                inputPlaceholder={t(`branch-Name`)}
              />
              <XeleInput
                idRef='Createdby-ref'
                labelClasses='mt-4'
                wrapperClasses='w-100 mt-2 mr-3'
                value={filter.staffId || ''}
                onInputChanged={(event) => {
                  const staffId = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    staffId: staffId || null,
                  }));
                }}
                inputPlaceholder={t(`staff-ID`)}
              />
            </div>
            <div className='w-100 d-flex flex-wrap fa-start'>
              <XeleAutocomplete
                idRef='branchRef'
                inputPlaceholder={t(`branch`)}
                wrapperClasses='w-25 mt-2 mr-3'
                selectedValues={selected.branch}
                data={data.branches || []}
                chipsLabel={(option) =>
                  (option.branchName && option.branchName) || ''
                }
                displayLabel={(option) =>
                  (option.branchName && option.branchName) || ''
                }
                multiple={false}
                withoutSearchButton
                onOpen={() => {
                  if (data.branches && data.branches.length === 0)
                    getBranches();
                }}
                onChange={(event, newValue) => {
                  setSelected({ id: 'branch', value: newValue });
                  setFilter((items) => ({
                    ...items,
                    branchId: newValue?.branchId || null,
                  }));
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (typingTimer.current) clearTimeout(typingTimer.current);
                  typingTimer.current = setTimeout(() => {
                    getBranches(value);
                  }, 700);
                }}
              />
              <XeleAutocomplete
                idRef='lineManagerRef'
                labelClasses='Requierd-Color'
                inputPlaceholder={t(`line-manager`)}
                selectedValues={selected.lineManager}
                wrapperClasses='w-25 mt-2 mr-3'
                data={data.systemUsers || []}
                withoutSearchButton
                multiple={false}
                displayLabel={(option) => option?.fullName || ''}
                onChange={(event, newValue) => {
                  setSelected({
                    id: 'lineManager',
                    value: newValue,
                  });
                  setFilter((items) => ({
                    ...items,
                    lineManagerId: newValue?.id || null,
                  }));
                }}
                onOpen={() => {
                  if (data.systemUsers && data.systemUsers.length === 0)
                    getSystemUsers({});
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (typingTimer) clearTimeout(typingTimer.current);
                  typingTimer.current = setTimeout(() => {
                    getSystemUsers({ name: value });
                  }, 1200);
                }}
              />
              <XeleAutocomplete
                idRef='user-NameRef'
                labelClasses='Requierd-Color'
                inputPlaceholder={t(`user-Name`)}
                selectedValues={selected.userName}
                wrapperClasses='w-25 mt-2'
                data={data.systemUsers || []}
                withoutSearchButton
                multiple={false}
                displayLabel={(option) => option?.userName || ''}
                onChange={(event, newValue) => {
                  setSelected({
                    id: 'userName',
                    value: newValue,
                  });
                  setFilter((items) => ({
                    ...items,
                    userUserName: newValue?.userName || null,
                  }));
                }}
                onOpen={() => {
                  if (data.systemUsers && data.systemUsers.length === 0)
                    getSystemUsers({});
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (typingTimer) clearTimeout(typingTimer.current);
                  typingTimer.current = setTimeout(() => {
                    getSystemUsers({ userName: value });
                  }, 1200);
                }}
              />
            </div>
          </div>
          <div className='mt-3'>
            <ButtonBase
              onClick={onClearedAllFiltersClick}
              id='onClearedAllFiltersref'
              // disabled={isAllFormFieldsLoading}
              className='btns theme-solid bg-danger clear-all-btn px-4'
            >
              <span className='mdi mdi-filter-remove m-1' />
              {t(`clear-filters`)}
            </ButtonBase>
          </div>
        </div>
      </div>
      <div className='propx-view-container sm m-3'>
        <XeleTable
          data={usersData || []}
          headerData={[
            {
              id: 1,
              label: 'user',
              component: (item) =>
                (item?.name ? (
                  <div className='d-flex-v-center'>
                    <div className='campaign-team-f1'>
                      <Avatar
                        className='avatars-wrapper team-avatar fz-12 sm-theme'
                        src={
                          item?.profileImage
                            ? getDownloadableLink(item?.profileImage)
                            : ''
                        }
                      >
                        {getFirstLastNameLetters(item?.name || '')}
                      </Avatar>
                    </div>
                    <div className='d-flex-column'>
                      <span className='fz-12 fw-simi-bold'>
                        {item?.name || '-'}
                      </span>
                      <span className='fz-10 text-tertiary'>
                        {item?.email || '-'}
                      </span>
                    </div>
                  </div>
                ) : (
                  '-'
                )) || '',
            },
            {
              id: 2,
              label: 'branch',
              input: 'branch',
              component: (item) => <span>{item?.branch}</span> || '',
            },
            {
              id: 3,
              label: 'Line-manager',
              input: 'lineManager',
            },
            {
              id: 4,
              label: 'Creation-date',
              input: 'creationDate',
              component: (item) =>
                (
                  <span>
                    {item?.creationDate
                      ? moment(item?.creationDate).format('LL')
                      : null}
                  </span>
                ) || '',
            },
            {
              id: 5,
              label: 'Created-by',
              input: 'createdByName',
            },
            {
              id: 6,
              label: 'actions',
              component: (item) =>
                (
                  <span className='d-flex fj-start'>
                    {item?.showRejectButton && (
                      <Button
                        className='btns propx-action-btn theme-propx outlined danger'
                        onClick={() => {
                          setActiveItem(item);
                          setIsRejectDialogOpen(true);
                        }}
                      >
                        {t(`reject`)}
                      </Button>
                    )}
                    {item?.showConfirmButton && (
                      <Button
                        className='btns propx-action-btn p-1 theme-propx outlined gold'
                        onClick={() =>
                          item?.id ? approveUserActivation(item?.id) : null
                        }
                      >
                        {t(`confirm`)}
                      </Button>
                    )}
                    <Button
                      className='btns propx-action-btn p-1 theme-propx gold-text'
                      onClick={() =>
                        item.id
                          ? GlobalNavigate(`/home/Users/edit?id=${item.id}`)
                          : null
                      }
                    >
                      {t(`show-details`)}
                    </Button>
                  </span>
                ) || '',
            },
          ]}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          totalItems={totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          defaultActions={[]}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      {isRejectDialogOpen && (
        <UserActivationRejectDialog
          activeItem={activeItem}
          isDialogOpen={isRejectDialogOpen}
          setIsDialogOpen={setIsRejectDialogOpen}
          reloadData={reloadData}
          onCloseClicked={() => {
            setIsRejectDialogOpen(false);
            setActiveItem(null);
          }}
          onCancelClicked={() => {
            setIsRejectDialogOpen(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
};
