import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeletePolicyService } from '../../../../../Services/PoliciesServices';
import { XeleDialog, Spinner } from '../../../../../Components';
import { showSuccess, showError } from '../../../../../Helper';

export const DeletePolicyDialog = ({
  parentTranslationPath,
  translationPath,
  close,
  onSave,
  activeItem,
}) => {
  const { t } = useTranslation('Policies');
  const [isLoading, setIsLoading] = useState(false);

  const deleteHandler = async () => {
    setIsLoading(true);
    const res = await DeletePolicyService(
      activeItem && activeItem.policyDataId
    );

    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`delete-policy-succes`));
      close();
      onSave();
    } else showError(t(`delete-policy-failed`));
    setIsLoading(false);
  };

  return (
    <XeleDialog
      titleText='confirm-message'
      saveText={t(`confirm`)}
      SmothMove
      saveType='button'
      maxWidth='sm'
      dialogContent={
        <div className='d-flex-column-center'>
          <Spinner isActive={isLoading} isAbsolute />
          <span className='mdi mdi-close-octagon c-danger mdi-48px' />
          <span className='fz-18px fw-bold'>
            {`${
              `${t(`are-you-sure-to-delete-this-policy`)}` ||
              ''
            }`}
            <span className='d-flex-center  fz-22px fw-bold mt-2'>
              {' '}
              ( {activeItem && activeItem.policyDataName} ){' '}
            </span>
          </span>
        </div>
      }
      saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
      isOpen={true}
      onSaveClicked={() => deleteHandler()}
      onCloseClicked={() => close()}
      onCancelClicked={() => close()}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
