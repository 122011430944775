import React, { useCallback, useReducer, useState, useEffect } from 'react';
import '../TaskConfiguration.scss';
import listIcon from '../../../../assets/images/icons/list.svg';
import xCloseIcon from '../../../../assets/images/icons/x-close-dialog.svg';
import { DialogActions, DialogContent, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { XeleDialog, XeleInput } from '../../../../Components';
import { ActivityFilesUploader } from '../../../../Components/UploaderActivitieFileComponent/ActivityFilesUploader';
import { TaskDynamicForm } from './TaskDynamicForm';
import {
  GetProjectTaskById,
  GetTaskConfigById,
} from '../../../../Services/TaskConfiguration/TaskConfiguration';
import { ChangeProjectTaskStatus } from '../../../../Services/ProjectTask/ProjectTask';

export const TaskDetailsDialog = ({
  isDialogOpen,
  onClose,
  activeTask,
  isKanbanView,
  reloadKanban,
}) => {
  const parentTranslationPath = 'TaskConfiguration';
  const { t } = useTranslation(parentTranslationPath);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [data, setData] = useReducer(reducer, {
    taskConfig: {},
    task: {},
  });
  const [selected, setSelected] = useReducer(reducer, {
    remarks: null,
    files: [],
  });

  const fillDataToSelected = (data) => {
    const mappedAttachments = data.projectTaskAttachments
      ? data.projectTaskAttachments.map((item) => ({
          uuid: item.fileId,
          fileName: item.urlDescription,
        }))
      : [];

    setSelected({
      id: 'edit',
      value: {
        remarks: data?.remarks || null,
        files: mappedAttachments || [],
      },
    });
  };

  const getProjectTaskById = async (projectTaskId) => {
    const res = await GetProjectTaskById(projectTaskId);
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'task', value: res || {} });
      fillDataToSelected(res);
    }
  };

  const getTaskConfigById = async (taskConfigId) => {
    const res = await GetTaskConfigById(taskConfigId);
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'taskConfig', value: res || {} });
    }
  };

  const changeProjectTaskStatus = async (isCompleted) => {
    const mappedAttachmentFiles =
      (selected.files &&
        selected.files.map((item) => ({
          fileId: item.uuid,
          urlDescription: item.fileName,
        }))) ||
      [];

    const body = {
      projectTaskId: activeTask?.projectTaskId,
      remarks: selected.remarks,
      projectTaskAttachmentDto: mappedAttachmentFiles,
    };

    const res = await ChangeProjectTaskStatus(body, isCompleted);
    if (!(res && res.status && res.status !== 200)) {
      if (res) {
        onClose();
        reloadKanban();
      }
    }
  };

  const changeTaskStatusHandler = async (isCompleted) => {
    if (activeTask?.projectTaskId) changeProjectTaskStatus(isCompleted);
  };

  const getIsActionsAllowed = () => {
    return isKanbanView && activeTask?.taskStatus === 'ToDo';
  };

  useEffect(() => {
    if (activeTask.projectTaskId) getProjectTaskById(activeTask.projectTaskId);
    if (activeTask.taskConfigurationId || activeTask.taskConfigId)
      getTaskConfigById(
        activeTask.taskConfigurationId || activeTask.taskConfigId
      );
  }, [activeTask]);

  return (
    <div>
      <XeleDialog
        isOpen={isDialogOpen}
        maxWidth='sm'
        contentClasses='p-0'
        dialogTitle={
          <div className='add-task-dialog task-details w-100'>
            <div className='d-flex-v-center-h-between'>
              <div className='dialog-title-icon'>
                <img src={listIcon} />
              </div>
              <div className='xCloseIcon pointer' onClick={onClose}>
                <img src={xCloseIcon} />
              </div>
            </div>
            <div className='pt-3 font-b'>{t(`task-details`)}</div>
          </div>
        }
        dialogContent={
          <div className='add-task-dialog task-details'>
            <DialogContent>
              <div className='details-dialog-wrap'>
                <div className='details-container container-frame '>
                  <div className='details-item'>
                    <p className='details-title'>Task category</p>
                    <p className='details-value'>{data?.task?.taskCategory}</p>
                  </div>

                  <div className='details-item'>
                    <p className='details-title'>Task name</p>
                    <p className='details-value'>{data?.task?.taskConfig}</p>
                  </div>
                  <div className='details-item'>
                    <p className='details-title'>Created by</p>
                    <p className='details-value'>{data?.task?.createdByName}</p>
                  </div>
                  <div className='details-item'>
                    <p className='details-title'>Assign to</p>
                    <p className='details-value'>
                      {data?.task?.projectTaskAssignTo &&
                        data?.task?.projectTaskAssignTo.map((item) => (
                          <>
                            <span className='fz-10 text-tertiary fw-simi-bold'>
                              {item.user}
                            </span>
                            {data?.task?.projectTaskAssignTo.length > 1 && (
                              <span className='mr-1'>,</span>
                            )}
                          </>
                        ))}
                    </p>
                  </div>

                  <div className='details-item'>
                    <p className='details-title'>Priority</p>
                    <p className='details-value'>
                      {data?.taskConfig?.priorityName}
                    </p>
                  </div>
                  <div className='details-item'>
                    <p className='details-title'>Prerequisite Task/Activity</p>
                    <p className='details-value'>
                      {data?.taskConfig?.taskConfigurationPrerequisites &&
                        data?.taskConfig?.taskConfigurationPrerequisites.map(
                          (item) => (
                            <>
                              <span className='fz-10 text-tertiary fw-simi-bold'>
                                {item.prerequisiteTaskConfigurationName}
                              </span>
                              {data?.taskConfig?.taskConfigurationPrerequisites
                                .length > 1 && <span className='mr-1'>,</span>}
                            </>
                          )
                        )}
                    </p>
                  </div>
                  <div className='details-item'>
                    <p className='details-title'>Specific Branch</p>
                    <p className='details-value'>
                      {data?.taskConfig?.taskConfigurationBranches &&
                        data?.taskConfig?.taskConfigurationBranches.map(
                          (item) => (
                            <>
                              <span className='fz-10 text-tertiary fw-simi-bold'>
                                {item.branchName}
                              </span>
                              {data?.taskConfig?.taskConfigurationBranches
                                .length > 1 && <span className='mr-1'>,</span>}
                            </>
                          )
                        )}
                    </p>
                  </div>

                  <div className='details-item'>
                    <p className='details-title'>Specific Module</p>
                    <p className='details-value'>
                      {data?.taskConfig?.taskConfigurationRelatedTo &&
                        data?.taskConfig?.taskConfigurationRelatedTo.map(
                          (item) => (
                            <>
                              <span className='fz-10 text-tertiary fw-simi-bold'>
                                {item.relatedTo}
                              </span>
                              {data?.taskConfig?.taskConfigurationRelatedTo
                                .length > 1 && <span className='mr-1'>,</span>}
                            </>
                          )
                        )}
                    </p>
                  </div>

                  <div className='details-item'>
                    <p className='details-title'>Specify</p>
                    <p className='details-value'>
                      {data?.taskConfig?.isTaskRequireApproval
                        ? 'With Approval'
                        : 'Without Approval'}
                    </p>
                  </div>
                </div>
                {data?.taskConfig?.formName && data?.task?.formData ? (
                  <div className='container-frame mt-4'>
                    <TaskDynamicForm
                      dynamicFormOptions={{
                        formName: data.taskConfig.formName,
                        formData: JSON.parse(data.task.formData),
                        isReadOnly: true,
                      }}
                    />
                  </div>
                ) : null}
                <div className='status-change-form'>
                  <div className='mt-4'>
                    <p className='fw-simi-bold'>Additional Informations</p>
                  </div>
                  <div>
                    <XeleInput
                      idRef='remarksRef'
                      labelValue='remarks'
                      value={selected.remarks || ''}
                      multiline
                      rows={4}
                      isDisabled={!getIsActionsAllowed()}
                      parentTranslationPath={parentTranslationPath}
                      onInputChanged={(event) => {
                        setSelected({
                          id: 'remarks',
                          value: event.target.value,
                        });
                      }}
                    />
                    <div className='w-100 mt-3'>
                      <ActivityFilesUploader
                        state={selected}
                        setState={setSelected}
                        isDisabled={!getIsActionsAllowed()}
                        chipsDisabled={() => !getIsActionsAllowed()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              {getIsActionsAllowed() && (
                <div className='d-flex-v-center-h-between px-4 py-0 mt-4 mb-2'>
                  <ButtonBase
                    className='btns theme-propx outlined'
                    onClick={() => changeTaskStatusHandler(false)}
                  >
                    {t(`reject`)}
                  </ButtonBase>

                  <ButtonBase
                    className='btns theme-propx solid'
                    onClick={() => changeTaskStatusHandler(true)}
                  >
                    {activeTask?.isWithApproval
                      ? t(`approve`)
                      : t(`move-to-complete`)}
                  </ButtonBase>
                </div>
              )}
            </DialogActions>
          </div>
        }
      />
    </div>
  );
};
