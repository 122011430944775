import { lazy } from 'react';

const TaskConfigurationFormView = lazy(() =>
  import('../../Views/Home').then((module) => ({
    default: module.TaskConfigurationFormView,
  }))
);
const TaskConfigurationsView = lazy(() =>
  import('../../Views/Home').then((module) => ({
    default: module.TaskConfigurationsView,
  }))
);

export const TaskConfigurationRoutes = [
  {
    path: '/view',
    name: 'TaskConfiguration:task-configuration',
    component: TaskConfigurationsView,
    layout: '/home/task-configuration',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'TaskConfiguration:task-configuration',
        isDisabled: true,
        route: '/home/task-configuration/view',
        groupName: 'system-parameters',
      },
    ],
  },
  {
    path: '/configuration-management',
    name: 'TaskConfiguration:task-configuration',
    component: TaskConfigurationFormView,
    layout: '/home/task-configuration',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'TaskConfiguration:task-configuration',
        isDisabled: false,
        route: '/home/task-configuration/view',
        groupName: 'system-parameters',
      },
      {
        name: 'TaskConfiguration:configuration-management',
        isDisabled: false,
        route: '/home/task-configuration/configuration-management',
      },
    ],
  },
];
