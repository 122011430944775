import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useReducer,
} from 'react';
import { useTranslation } from 'react-i18next';
import { KenbanCardsLayout } from './Utilities';
import './ProjectTasksKanbanBoard.scss';
import '../TaskConfiguration.scss';
import { GroupedAvatarComponent } from '../../../../SharedComponents';
import { useTitle } from '../../../../Hooks';
import Button from '@material-ui/core/Button';
import { AddTaskDialog } from '../Dialogs/AddTaskDialog/AddTaskDialog';
import {
  GetMyTasks,
  GetProjectTaskAssignees,
} from '../../../../Services/ProjectTask/ProjectTask';

export const ProjectTasksKanbanBoard = () => {
  const parentTranslationPath = 'TaskConfiguration';
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t('my-tasks'));

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [isAddTaskDialogOpen, setIsAddTaskDialogOpen] = useState(false);

  const defaultBoardTasks = {
    toDoTasks: {
      result: [],
      totalCount: 0,
    },
    completedTasks: {
      result: [],
      totalCount: 0,
    },
    canceledTasks: {
      result: [],
      totalCount: 0,
    },
  };
  const [boardTasks, setBoardTasks] = useState(defaultBoardTasks);
  const [paginationFilter, setPaginationFilter] = useState({
    pageIndex: 1,
    pageSize: 10,
  });
  const [selected, setSelected] = useReducer(reducer, {
    assignedToId: null,
  });
  const [isLoading, setIsLoading] = useReducer(reducer, {
    departments: false,
    campaigns: false,
  });
  const [data, setData] = useReducer(reducer, {
    assignees: [],
    departments: [],
    campaigns: [],
  });

  const getBoardTasks = useCallback(async () => {
    const body = {
      ...paginationFilter,
      assignedToId: selected.assignedToId || null,
    };

    const res = await GetMyTasks(body);
    if (!(res && res.status && res.status !== 200)) {
      if (paginationFilter.pageIndex === 1)
        setBoardTasks(res || defaultBoardTasks);
      else addToPreviousData(res);
    } else addToPreviousData(null);
  }, [paginationFilter, selected]);

  const getTasksAssignees = async () => {
    const res = await GetProjectTaskAssignees();
    if (!(res && res.status && res.status !== 200)) {
      const groupedAvatarData = res?.map((item) => ({
        applicationUserId: item.assignedToId,
        fullName: item.assignedTo,
        profileImg: item.assignedToProfileImg,
      }));
      setData({ id: 'assignees', value: groupedAvatarData || [] });
    } else setData({ id: 'assignees', value: [] });
  };

  const addToPreviousData = (newData) => {
    if (newData) {
      const mappedBoardTasks = {};

      for (const key of Object.keys(defaultBoardTasks)) {
        const previousResult = boardTasks?.[key]?.result || [];
        const newResult = newData?.[key]?.result || [];

        mappedBoardTasks[key] = {
          result: [...previousResult, ...newResult],
          totalCount: newData?.[key]?.totalCount || 0,
        };
      }

      setBoardTasks(mappedBoardTasks);
    }
  };

  const paginationChangeHandler = (pageIndex) => {
    setPaginationFilter((prevState) => ({ ...prevState, pageIndex }));
  };

  useEffect(() => {
    getTasksAssignees();
  }, []);

  useEffect(() => {
    getBoardTasks();
  }, [getBoardTasks]);

  return (
    <div
      className='my-tasks-view
     board-overflow d-flex-column-center px-4 pb-4'
    >
      <div className='d-flex-v-center-h-between w-100'>
        <div className='w-50 mx-4 px-4'>
          <h1 className='fw-simi-bold fz-30'>Kanban Board</h1>
          <p className='fz-12 text-tertiary'>This Is A View Of My Tasks . </p>
        </div>
        <div className='w-50 px-4 d-flex-center fj-end'>
          <GroupedAvatarComponent
            data={data.assignees || []}
            selectedUserId={selected.assignedToId}
            userItemClickHandler={(userId) => {
              paginationChangeHandler(1);
              setSelected({ id: 'assignedToId', value: userId });
            }}
            maxAvatarsNumber={5}
          />
          <Button
            className='btns theme-propx solid ml-3'
            id='btn-save'
            disabled={isLoading.saving}
            onClick={() => setIsAddTaskDialogOpen(true)}
          >
            <span className='mdi mdi-plus' /> <span>{t(`add-task`)}</span>
          </Button>
        </div>
      </div>
      <div>
        {boardTasks && (
          <KenbanCardsLayout
            data={boardTasks || {}}
            reloadKanban={() => {
              getBoardTasks();
            }}
            paginationChangeHandler={paginationChangeHandler}
            paginationFilter={paginationFilter}
          />
        )}
      </div>
      {isAddTaskDialogOpen && (
        <AddTaskDialog
          isDialogOpen={isAddTaskDialogOpen}
          onSave={() => {
            setIsAddTaskDialogOpen(false);
          }}
          onClose={() => {
            setIsAddTaskDialogOpen(false);
          }}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};
