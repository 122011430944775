import { config } from '../config/config';
import { GlobalTranslate, showError, HttpServices } from '../Helper';

export const GetTeam = async (pageIndex, pageSize, searchItem) => {
  const result = await HttpServices.get(
    `${config.server_address}/Authorization/Teams/${pageIndex}/${pageSize}?search=${searchItem}`
  )
    .then((data) => data)
    .catch((error) => {
      showError(GlobalTranslate.t('TeamView:NotificationErrorView'));
      return undefined;
    });
  return result;
};
export const GetUser = async (pageIndex, pageSize) => {
  const result = await HttpServices.get(
    `${config.server_address}/Identity/Account/GetAllOrganizationUser/${pageIndex}/${pageSize}`
  )
    .then((data) => data)
    .catch((error) => {
      showError(GlobalTranslate.t('TeamView:NotificationErrorView'));
      return undefined;
    });
  return result;
};
export const GetUserInTeam = async (teamsId, pageIndex, pageSize) => {
  const result = await HttpServices.get(
    `${config.server_address}/Authorization/Teams/GetAllTeamMember/${teamsId}/${pageIndex}/${pageSize}`
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};
export const PostTeamUser = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/Authorization/Teams/AddUserForTeams`,
    body
  )
    .then((data) => data)
    .catch((error) => {
      showError(GlobalTranslate.t('TeamView:null'));
      return undefined;
    });
  return result;
};
export const DeleteTeamUser = async (teamUsersId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/Authorization/Teams/RemoveUserFromTeams/${teamUsersId}`
  )
    .then((data) => data)
    .catch((error) => {
      showError(
        GlobalTranslate.t('TeamView:DeleteDialog.NotificationErrorDelete')
      );
      return undefined;
    });
  return result;
};
export const DeleteTeam = async (teamsId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/Authorization/Teams/${teamsId}`
  )
    .then((data) => data)
    .catch((error) => {
      showError(
        GlobalTranslate.t('TeamView:DeleteDialog.NotificationErrorDelete')
      );
      return undefined;
    });
  return result;
};

export const PostTeam = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/Authorization/Teams`,
    body
  )
    .then((data) => data)
    .catch((error) => {
      showError(
        GlobalTranslate.t('TeamView:AddTeamDialog.NotificationErrorAdd')
      );
      return undefined;
    });
  return result;
};

export const EditTeam = async (teamsId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/Authorization/Teams/${teamsId}`,
    body
  )
    .then((data) => data)
    .catch((error) => {
      showError(
        GlobalTranslate.t('TeamView:EditTeamDialog.NotificationEditTeam')
      );
      return undefined;
    });
  return result;
};
export const GetallBusinessGroups = async (body) => {
  const result = await HttpServices.get(
    `${config.server_address}/Authorization/BusinessGroups`,
    body
  )
    .then((data) => data)
    .catch((error) => {
      showError(
        GlobalTranslate.t('BusinessGroupsView:AddDialog.NotificationErrorAdd')
      );
      return undefined;
    });
  return result;
};
export const SearchTeam = async (pageIndex, pageSize, search) => {
  const result = await HttpServices.get(
    `${config.server_address}/Authorization/Teams/${pageIndex}/${pageSize}/${search}`
  )
    .then((data) => data)
    .catch((error) => {
      showError(GlobalTranslate.t('TeamView:search'));
      return undefined;
    });
  return result;
};

// /////////////////////////////////////////////////////////////////////

// /--Services

export const GetTeamServices = async (pageIndex, pageSize, searchItem) => {
  const result = await HttpServices.get(
    `${config.server_address}/Authorization/Teams/${pageIndex}/${pageSize}?search=${searchItem}`
  )
    .then((data) => data)
    .catch((error) => {
      showError(GlobalTranslate.t('TeamView:NotificationErrorView'));
      return undefined;
    });
  return result;
};
export const GetUserServices = async (pageIndex, pageSize) => {
  const result = await HttpServices.get(
    `${config.server_address}/Identity/Account/GetAllOrganizationUser/${pageIndex}/${pageSize}`
  )
    .then((data) => data)
    .catch((error) => {
      showError(GlobalTranslate.t('TeamView:NotificationErrorView'));
      return undefined;
    });
  return result;
};
export const GetUserInTeamServices = async (teamsId, pageIndex, pageSize) => {
  const result = await HttpServices.get(
    `${config.server_address}/Authorization/Teams/GetAllTeamMember/${teamsId}/${pageIndex}/${pageSize}`
  )
    .then((data) => data)
    .catch((error) => {
      showError(GlobalTranslate.t('TeamView:null'));
      return undefined;
    });
  return result;
};
export const PostTeamUserServices = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/Authorization/Teams/AddUserForTeams`,
    body
  )
    .then((data) => data)
    .catch((error) => {
      showError(GlobalTranslate.t('TeamView:null'));
      return undefined;
    });
  return result;
};
export const SetUserAsTeamLead = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/Authorization/Teams/SetUserAsTeamLead?userId=${body.userId}&teamId=${body.teamId}`,
    body
  )
    .then((data) => data)
    .catch((error) => {
      showError(
        GlobalTranslate.t('TeamView:EditTeamDialog.selectTeamLeadfaild')
      );
      return undefined;
    });
  return result;
};
export const DeleteTeamUserServices = async (teamUsersId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/Authorization/Teams/RemoveUserFromTeams/${teamUsersId}`
  )
    .then((data) => data)
    .catch((error) => {
      showError(
        GlobalTranslate.t('TeamView:DeleteDialog.NotificationErrorDelete')
      );
      return undefined;
    });
  return result;
};
export const DeleteTeamServices = async (teamsId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/Authorization/Teams/${teamsId}`
  )
    .then((data) => data)
    .catch((error) => {
      showError(
        GlobalTranslate.t('TeamView:DeleteDialog.NotificationErrorDelete')
      );
      return undefined;
    });
  return result;
};

export const PostTeamServices = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/Authorization/Teams`,
    body
  )
    .then((data) => data)
    .catch((error) => {
      showError(
        GlobalTranslate.t('TeamView:AddTeamDialog.NotificationErrorAdd')
      );
      return undefined;
    });
  return result;
};

export const EditTeamServices = async (teamsId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/Authorization/Teams/${teamsId}`,
    body
  )
    .then((data) => data)
    .catch((error) => {
      showError(
        GlobalTranslate.t('TeamView:EditTeamDialog.NotificationEditTeam')
      );
      return undefined;
    });
  return result;
};
export const GetallBusinessGroupsServices = async (body) => {
  const result = await HttpServices.get(
    `${config.server_address}/Authorization/BusinessGroups`,
    body
  )
    .then((data) => data)
    .catch((error) => {
      showError(
        GlobalTranslate.t('BusinessGroupsView:AddDialog.NotificationErrorAdd')
      );
      return undefined;
    });
  return result;
};
export const SearchTeamServices = async (pageIndex, pageSize, search) => {
  const result = await HttpServices.get(
    `${config.server_address}/Authorization/Teams/${pageIndex}/${pageSize}/${search}`
  )
    .then((data) => data)
    .catch((error) => {
      showError(GlobalTranslate.t('TeamView: ... search'));
      return undefined;
    });
  return result;
};

export const GetTeamsByBusinessGroupsId = async ({
  businessGroupsId,
  pageIndex,
  pageSize,
}) => {
  const result = await HttpServices.get(
    `${config.server_address}/Authorization/Teams/${businessGroupsId}/${pageIndex}/${pageSize}`
  )
    .then((data) => data)
    .catch((error) => {
      return undefined;
    });
  return result;
};

export const GetAllTeamsByTeamLeaderId = async (payLoad) => {
  const result = await HttpServices.get(
    `${config.server_address}/Authorization/Teams/GetAllTeamsByTeamLeaderId/${payLoad.pageIndex}/${payLoad.pageSize}?teamLeaderUserId=${payLoad.userId}&search=${payLoad.search}`
  )
    .then((data) => data)
    .catch((error) => {
      return error.response;
    });
  return result;
};
