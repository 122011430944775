import React, { useRef } from 'react';
import {
  XeleAutocomplete,
  XeleDatePicker,
  XeleInput,
  XelePhone,
  UploaderComponentCircular,
} from '../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getErrorByName } from '../../../../../../../Helper';
import { DefaultImagesEnum } from '../../../../../../../Enums';

export const DialogTopFields = ({
  translationPath,
  parentTranslationPath,
  setSelected,
  selected,
  data,
  schema,
  isSubmitted,
  isExist,
  functions,
}) => {
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });
  const searchTimer = useRef(null);

  return (
    <>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>
            {t(`first-name*`)}
          </span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <XeleInput
            idRef='firstNameRef'
            inputPlaceholder='enter'
            value={selected.firstName}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            isWithError
            isSubmitted={isSubmitted}
            helperText={getErrorByName(schema, 'firstName').message}
            error={getErrorByName(schema, 'firstName').error}
            onInputChanged={(event) => {
              const firstName = event?.target?.value;
              setSelected({ id: 'firstName', value: firstName });
            }}
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>
            {t(`last-name*`)}
          </span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <XeleInput
            idRef='lastNameRef'
            inputPlaceholder='enter'
            value={selected.lastName || ''}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            isWithError
            isSubmitted={isSubmitted}
            helperText={getErrorByName(schema, 'lastName').message}
            error={getErrorByName(schema, 'lastName').error}
            onInputChanged={(event) => {
              const lastName = event?.target?.value;
              setSelected({ id: 'lastName', value: lastName });
            }}
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 mb-4 pt-1'>
          <span className='fw-simi-bold'>{t(`photo`)}</span>
          <span className='d-block fz-10 mt-1'>
            {t(`photo-field-label`)}
          </span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <UploaderComponentCircular
            idRef='profileImgRef'
            circleDefaultImage={DefaultImagesEnum.man.defaultImg}
            initUploadedFiles={
              (selected.profileImg && [
                { uuid: selected.profileImg, fileName: 'user-image' },
              ]) ||
              []
            }
            uploadedChanged={(files) => {
              const uploadedFileGUID = files.length > 0 ? files[0].uuid : null;
              setSelected({
                id: 'profileImg',
                value: uploadedFileGUID,
              });
            }}
            isPropxTheme
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>
            {t(`username*`)}
          </span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <XeleInput
            idRef='usernameRef'
            inputPlaceholder='enter'
            value={selected.userName || ''}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            isWithError
            isSubmitted={isSubmitted}
            helperText={getErrorByName(schema, 'userName').message}
            error={getErrorByName(schema, 'userName').error}
            afterIconClasses={
              (isExist?.userName === true &&
                'mdi mdi-close-outline c-danger mt-1') ||
              (!getErrorByName(schema, 'userName').error &&
                'mdi mdi-check-outline c-success mt-1') ||
              null
            }
            withLoader
            onInputChanged={(event) => {
              const userName = event?.target?.value;
              setSelected({ id: 'userName', value: userName });
            }}
            onKeyUp={(event) => {
              const userName = event?.target?.value;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                functions.getIsExistUserName(userName);
              }, 500);
            }}
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>
            {t(`staff-id`)}
          </span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <XeleInput
            idRef='staffCodeRef'
            inputPlaceholder='enter'
            isWithError
            value={selected.staffCode || ''}
            isSubmitted={isSubmitted}
            helperText={getErrorByName(schema, 'staffCode').message}
            error={getErrorByName(schema, 'staffCode').error}
            afterIconClasses={
              (isExist?.staffCode === true &&
                'mdi mdi-close-outline c-danger mt-1') ||
              (selected.staffCode &&
                !getErrorByName(schema, 'staffCode').error &&
                'mdi mdi-check-outline c-success mt-1') ||
              null
            }
            withLoader
            onInputChanged={(event) => {
              const staffCode = event?.target?.value;
              setSelected({ id: 'staffCode', value: staffCode });
            }}
            onKeyUp={(event) => {
              const staffCode = event?.target?.value;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                if (staffCode) functions.getIsExistStaffCode(staffCode);
              }, 500);
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>{t(`branch`)}</span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <XeleAutocomplete
            idRef='branchRef'
            inputPlaceholder={t(`select`)}
            selectedValues={selected.branch}
            data={data.branches || []}
            displayLabel={(option) => option.branchName || ''}
            multiple={false}
            withoutSearchButton
            onOpen={() => {
              if (data.branches && data.branches.length === 0)
                functions.getBranches();
            }}
            onChange={(event, newValue) => {
              setSelected({ id: 'branch', value: newValue });
            }}
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                functions.getBranches(value);
              }, 700);
            }}
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>
            {t(`joining-date`)}
          </span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <XeleDatePicker
            idRef='joiningDateRef'
            placeholder='DD/MM/YYYY'
            value={selected.dateOfJoining || null}
            onDateChanged={(newValue) => {
              setSelected({
                id: 'dateOfJoining',
                value:
                  (newValue &&
                    moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) ||
                  null,
              });
            }}
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>{t(`email*`)}</span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <XeleInput
            idRef='emailRef'
            inputPlaceholder='enter'
            value={selected.email || ''}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            isWithError
            isSubmitted={isSubmitted}
            helperText={getErrorByName(schema, 'email').message}
            error={getErrorByName(schema, 'email').error}
            afterIconClasses={
              (isExist?.email === true &&
                'mdi mdi-close-outline c-danger mt-1') ||
              (!getErrorByName(schema, 'email').error &&
                'mdi mdi-check-outline c-success mt-1') ||
              null
            }
            withLoader
            onInputChanged={(event) => {
              const email = event?.target?.value;
              setSelected({ id: 'email', value: email });
            }}
            onKeyUp={(event) => {
              const email = event?.target?.value;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                functions.getIsExistEmail(email);
              }, 500);
            }}
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>{t(`mobile*`)}</span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <XelePhone
            idRef='phoneNumberRef'
            inputPlaceholder='enter'
            value={selected.phoneNumber}
            country='ae'
            isWithError
            isSubmitted={isSubmitted}
            helperText={getErrorByName(schema, 'phoneNumber').message}
            error={getErrorByName(schema, 'phoneNumber').error}
            afterIconClasses={
              (isExist?.phoneNumber === true &&
                'mdi mdi-close-outline c-danger mt-1') ||
              (!getErrorByName(schema, 'phoneNumber').error &&
                'mdi mdi-check-outline c-success mt-1') ||
              null
            }
            onInputChanged={(value) => {
              setSelected({ id: 'phoneNumber', value: value });
            }}
            onKeyUp={(event) => {
              const phoneNumber = event?.target?.value;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                functions.getIsExistPhoneNumber(phoneNumber);
              }, 500);
            }}
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>
            {t(`whatsapp*`)}
          </span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <XelePhone
            country='ae'
            idRef='whatsappNumberRef'
            value={selected.whatsAppNumber}
            isWithError
            isSubmitted={isSubmitted}
            helperText={getErrorByName(schema, 'whatsAppNumber').message}
            error={getErrorByName(schema, 'whatsAppNumber').error}
            inputPlaceholder='enter'
            onInputChanged={(value) => {
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                setSelected({ id: 'whatsAppNumber', value });
              }, 500);
            }}
          />
        </div>
      </div>
    </>
  );
};
