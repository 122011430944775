export const QuickAddEnum = {
  Activity: {
    key: 5,
    icon: 'icons i-activity',
    id: 'quickActivityId',
    value: 'activity',
    permissionsId:'d6d74217-23f8-4f4a-9618-08da36560604',
  },
  Task: {
    key: 6,
    icon: 'icons i-task',
    id: 'quickTaskId',
    value: 'task',
    permissionsId:'e95f02e0-9f43-4888-19be-08dc313da57c',
  },
};
