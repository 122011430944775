import { lazy } from 'react';

const PoliciesView = lazy(() =>
  import('../../Views/Home/PoliciesView/PoliciesView').then((module) => ({
    default: module.PoliciesView,
  }))
);
export const PoliciesRoutes = [
  {
    path: '/view',
    name: 'Policies:policies',
    component: PoliciesView,
    layout: '/home/policies',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'Policies:policies',
        isDisabled: true,
        route: '/home/policies/view',
        groupName: 'system-admin',
      },
    ],
  },
];
