import React from 'react';
import PropTypes from 'prop-types';
import { XeleInput } from '../../../../../../Components';

export const ActivityNameComponent = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
  helperText,
  isSubmitted,
}) => (
  <div>
    <XeleInput
      idRef='ActivityNameRef'
      labelClasses='Requierd-Color'
      labelValue='activity-name'
      value={state.activityTypeName}
      inputPlaceholder={`Add-text`}
      isWithError
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      onInputChanged={(event) => {
        setState(event.target.value);
      }}
      helperText={helperText}
      error={!!helperText}
      isSubmitted={isSubmitted}
    />
  </div>
);

ActivityNameComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  setState: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
};
