import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { TabsComponent } from '../../../Components';
import { GetAllBranches } from '../../../Services/BranchesServices';
import { GetAllRoles } from '../../../Services/roleServices';
import { ApplicationUserSearch } from '../../../Services/userServices';

const parentTranslationPath = 'ApprovalsConfiguration';
const translationPath = '';
export const ApprovalsConfigurationView = () => {
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useReducer(reducer, {
    branches: [],
    roles: [],
    users: [],
  });

  const [isLoading, setIsLoading] = useReducer(reducer, {
    branches: false,
    roles: false,
    users: false,
    usersApproval: false,
  });

  const ApprovalDataList = [];

  const onTabChanged = (e, newTab) => {
    setActiveTab(newTab);
  };

  const getAllBranches = useCallback(async () => {
    setIsLoading({ id: 'branches', value: true });
    try {
      const res = await GetAllBranches({ pageSize: 1000, pageIndex: 0 });
      setData({ id: 'branches', value: res.result });
    } catch (error) {
      showError('something went wrong ');
    } finally {
      setIsLoading({ id: 'branches', value: false });
    }
  }, []);

  const getRoles = useCallback(async () => {
    setIsLoading({ id: 'roles', value: true });
    const pageIndex = 1;
    const pageSize = 1000;
    const res = await GetAllRoles(pageIndex, pageSize);
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'roles', value: res.result });
    }
    setIsLoading({ id: 'roles', value: false });
  }, []);

  const getAllUsers = useCallback(async () => {
    setIsLoading({ id: 'users', value: true });

    const res = await ApplicationUserSearch({
      pageIndex: 0,
      pageSize: 1000,
      name: '',
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'users', value: res.result });
      setData({ id: 'usersApproval', value: res.result });
    }
    setIsLoading({ id: 'users', value: false });
  }, []);

  useEffect(() => {
    getAllBranches();
    getRoles();
    getAllUsers();
  }, []);

  return (
    <>
      <TabsComponent
        wrapperClasses='w-100'
        data={ApprovalDataList}
        labelInput='label'
        themeClasses='theme-curved'
        currentTab={activeTab}
        onTabChanged={onTabChanged}
        withDynamicComponents
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        dynamicComponentProps={{
          translationPath,
          parentTranslationPath,
          data,
          setData,
          isLoading,
          reducer,
          setIsLoading,
        }}
      />
    </>
  );
};
