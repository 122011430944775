import { Fab, Tooltip } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { XeleInput } from '../../../../../../../Components';

export const LookupTreeFieldsView = ({
  translationPath,
  parentTranslationPath,
  setDragItem,
  Data,
}) => {
  const { t } = useTranslation('ReportsBuilderg', {
    keyPrefix: translationPath,
  });
  const [isCollapseData, setIsCollapseData] = useState(false);
  const [initialState, setInitialState] = useState([]);
  const [openClose, setOpenClose] = useState([]);

  const onClick = (item, index) => {
    if (!openClose.includes(index)) {
      setOpenClose((data) => [...data, index]);
    } else {
      setOpenClose((data) => data.filter((i) => i !== index));
    }
  };

  const countSheep = (limit) => {
    for (let i = 1; i < limit; i += 1) {
      if (openClose.find((element) => element === i + 1)) return;
      setOpenClose((data) => [...data, i]);
    }
  };

  const CollapseData = () => {
    setIsCollapseData(!isCollapseData);
    if (!isCollapseData) countSheep(initialState.length + 1);
    else setOpenClose([]);
  };

  const searchHandler = (value) => {
    const filteredData = Data.map((item) => {
      const filteredItems = item.LookupItems
        ? item.LookupItems.filter((lookupItem) =>
            lookupItem.LookupItemName.toLowerCase().includes(
              value.toLowerCase()
            )
          )
        : [];

      return {
        ...item,
        LookupItems: filteredItems,
      };
    });

    setInitialState(filteredData);
    countSheep(filteredData.length + 1);
  };

  useEffect(() => {
    setInitialState(Data);
  }, [Data]);

  return (
    <div
      className='TreeFieldsView-container templet-w w-100'
      onMouseEnter={() => {
        setDragItem(false);
      }}
    >
      <div className='d-flex-column-center mt-2 title-h'>
        <span className='main-title'>{t(`Fields-Lookup`)}</span>
      </div>
      <XeleInput
        idRef='DatasystemReportFormsRef'
        inputPlaceholder={t(`search-fields`)}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        onKeyUp={(e) => searchHandler(e.target.value)}
        startAdornment={<span className='mdi mdi-magnify mdi-24px c-gray' />}
        endAdornment={
          <div className='mx-2'>
            {!isCollapseData ? (
              <Tooltip
                size='small'
                title={t(`open`)}
                onClick={CollapseData}
              >
                <Fab>
                  <span className='mdi mdi-arrow-expand ' />
                </Fab>
              </Tooltip>
            ) : (
              <Tooltip
                size='small'
                title={t(`close`)}
                onClick={CollapseData}
              >
                <Fab>
                  <span className='mdi mdi-arrow-collapse' />
                </Fab>
              </Tooltip>
            )}
          </div>
        }
      />
      <div />
      <div className='d-flex-column TreeFieldsView istemplet'>
        <div className='c-danger'>{t(`drag-and-drop`)}</div>
        <div>
          <p>{t(`Caption-Here`)}</p>
          <ul className='tree'>
            {initialState.map((item, index) => (
              <li
                onClick={() => onClick(item.name, index + 1)}
                index={index + 1}
                key={index}
                className='li-tree-container'
              >
                {openClose.includes(index + 1) ? (
                  <span className='mdi mdi-minus-box-outline pointercursor' />
                ) : (
                  <span className='mdi mdi-plus-box-outline pointercursor' />
                )}
                <span
                  className={`${
                    (item &&
                      item.LookupItems.length === 0 &&
                      'form-name form-not-have-data') ||
                    'form-name'
                  } `}
                >
                  {(item && item.LookupType) || 'N/A'}
                </span>
                <ul
                  className={`item-in ${
                    openClose.includes(index + 1) ? 'is-open' : ''
                  }`}
                >
                  {item.LookupItems.map((itemData, idx) => (
                    <li
                      key={idx}
                      index={idx + 1}
                      className='file'
                      draggable
                      // onDoubleClick={() => {
                      //   const ctl = document.getElementById("refs");
                      //   const startPos = ctl.selectionStart;
                      //   // onStateChanged(typeInTextarea(oldval, startPos, (` [  ${MergedfieldsNamehHandler(mergedfieldsName)}.${items.formFieldTitle} ]  `)));
                      // }}
                      onMouseEnter={() => {
                        const sel = document.getSelection();
                        sel.removeAllRanges();
                      }}
                      onDragStart={(e) => {
                        e.dataTransfer.setData(
                          'text',
                          ` ${(itemData && itemData.LookupItemName) || ''} `
                        );
                        setDragItem(true);
                      }}
                      onDragEnd={() => setDragItem(false)}
                    >
                      {(itemData && itemData.LookupItemName) || ''}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

LookupTreeFieldsView.defaultProps = {
  translationPath: '',
  parentTranslationPath: '',
  setDragItem: () => {},
  setDragItemDragItemValue: () => {},
  Data: [],
};

LookupTreeFieldsView.propTypes = {
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  setDragItem: PropTypes.func,
  setDragItemDragItemValue: PropTypes.func,
  Data: PropTypes.array,
};
