import { lazy } from 'react';

import {
  UserLoginPermissions,
  BusinessGroupsPermissions,
  TeamPermissions,
  RolesPermissions,
  AdminDashboardPermissions,
  BranchesPermissions,
  ReportBuilderPermissions,
  PolicyPermissions,
  RotationConfigurationPermissions,
  GroupsPermissions,
  UsersManagementPermissions,
  AutoCorrespondencePermissions,
  ApprovalsConfigurationPermissions,
} from '../../Permissions';
import {
  LookupsPermissions,
  ActivityTypePermissions,
  TemplatesPermissions,
  FormBuilderPermissions,
} from '../../Permissions/SystemParametersPermissions';
import LookupsLayout from '../../Layouts/Home/LookupsLayout/LookupsLayout';
import { BranchesLayout } from '../../Layouts/Home/BranchesLayout/BranchesLayout';
import { FormBuilderLayout } from '../../Layouts/Home/FormBuilder/FormBuilderLayout';
import { RolesLayout } from '../../Layouts/Home/RolesLayout/RolesLayout';

import { TemplatesLayout } from '../../Layouts/Home/TemplatesLayout/TemplatesLayout';
import { TaskConfigurationLayout } from '../../Layouts/Home/TaskConfigurationLayout/TaskConfigurationLayout';
import { ReportsBuilderLayout } from '../../Layouts/Home/ReportsBuilderLayout/ReportsBuilderLayout';

import { DashboardLayout } from '../../Layouts/Home/DashboardLayout/DashboardLayout';
import { PoliciesLayout } from '../../Layouts/Home/PoliciesLayout/PoliciesLayout';
import { UsersManagementLayout } from '../../Views/Settings/UserManagement/UsersManagementLayout';
import UsersLayout from '../../Layouts/Home/UsersLayout/UsersLayout.jsx';

const BusinessGroupsView = lazy(() =>
  import(
    '../../Views/Home/Administration/BusinessGroupsView/BusinessGroups.View'
  )
);
const Team = lazy(() =>
  import('../../Views/Home/Administration/TeamView/Team.View')
);
const UserView = lazy(() =>
  import('../../Views/Home/Administration/UsersView/User.View').then(
    (module) => ({ default: module.UserView })
  )
);
const GroupsView = lazy(() =>
  import('../../Views/Home/Administration/GroupsView/GroupsView').then(
    (module) => ({ default: module.GroupsView })
  )
);
const UsersManagementView = lazy(() =>
  import(
    '../../Views/Home/Administration/UsersManagement/UsersManagementView'
  ).then((module) => ({ default: module.UsersManagementView }))
);
const UserApprovalsView = lazy(() =>
  import(
    '../../Views/Home/Administration/UserApprovals/UserApprovalsView'
  ).then((module) => ({ default: module.UserApprovalsView }))
);
const ImportDetailsView = lazy(() =>
  import(
    '../../Views/Home/Common/ImportDetailsView/ImportDetails.View.jsx'
  ).then((module) => ({ default: module.ImportDetailsView }))
);
const ActivitiesView = lazy(() =>
  import('../../Views/Home/ActivitiesView/ActivitiesView.jsx').then(
    (module) => ({ default: module.ActivitiesView })
  )
);
const MonthlyCalendarView = lazy(() =>
  import('../../Views/Home/MonthlyCalendarView/MonthlyCalendarView.jsx').then(
    (module) => ({ default: module.MonthlyCalendarView })
  )
);
const ProjectTasksKanbanBoard = lazy(() =>
  import(
    '../../Views/Home/TaskConfiguration/ProjectTasks/ProjectTasksKanbanBoard.jsx'
  ).then((module) => ({ default: module.ProjectTasksKanbanBoard }))
);
const ActivityBuilderView = lazy(() =>
  import('../../Views/Home/ActivityBuilderView/ActivityBuilderView.jsx').then(
    (module) => ({ default: module.ActivityBuilderView })
  )
);
const ActivitiesTypesBulkUpdateView = lazy(() =>
  import(
    '../../Views/Home/ActivitiesTypeView/ActivityTypeUpdateBulkView/ActivitiesTypesBulkUpdateView.jsx'
  ).then((module) => ({ default: module.ActivitiesTypesBulkUpdateView }))
);
const ActivitiesTypeView = lazy(() =>
  import('../../Views/Home/ActivitiesTypeView/ActivitiesTypeView.jsx').then(
    (module) => ({ default: module.ActivitiesTypeView })
  )
);
const AutoCorrespondenceView = lazy(() =>
  import(
    '../../Views/Home/AutoCorrespondenceView/AutoCorrespondenceView.jsx'
  ).then((module) => ({ default: module.AutoCorrespondenceView }))
);

const ApprovalsConfigurationView = lazy(() =>
  import('../../Views/Home/ApprovalsConfigurationView/ApprovalsView.jsx').then(
    (module) => ({ default: module.ApprovalsConfigurationView })
  )
);

const SystemConfigurationView = lazy(() =>
  import(
    '../../Views/Home/Administration/SystemConfigrationView/SystemConfigrationView'
  ).then((module) => ({ default: module.SystemConfigurationView }))
);

export const HomeRoutes = [
  {
    id: 30,
    path: '/Users/*',
    name: 'UsersView:UsersManagementView.edit-user',
    component: UsersLayout,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(UserLoginPermissions),
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.Users',
        isDisabled: false,
        route: '/home/Users',
        groupName: 'system-admin',
      },
      {
        name: 'UsersView:UsersManagementView.edit-user',
        isDisabled: false,
        route: '/home/Users/edit',
      },
    ],
  },
  {
    id: 30,
    path: '/Users',
    name: 'SideMenuView.Administration.Users',
    component: UserView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(UserLoginPermissions),
    groupId: 4,
    order: 1,
    icon: 'mdi mdi-badge-account-outline',
    iconActive: 'mdi mdi-badge-account',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.Users',
        isDisabled: false,
        route: '/home/Users',
        groupName: 'system-admin',
      },
    ],
  },
  {
    id: 31,
    path: '/Administration/BusinessGroups',
    name: 'SideMenuView.Administration.BusinessGroups',
    component: BusinessGroupsView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(BusinessGroupsPermissions),
    groupId: 4,
    order: 3,
    icon: 'mdi mdi-file-tree-outline',
    iconActive: 'mdi mdi-file-tree-outline c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.BusinessGroups',
        isDisabled: false,
        route: '/home/Administration/BusinessGroups',
        groupName: 'system-admin',
      },
    ],
  },
  {
    id: 33,
    path: '/Roles/*',
    name: 'SideMenuView.Administration.Roles',
    component: RolesLayout,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(RolesPermissions),
    groupId: 4,
    order: 8,
    icon: 'mdi mdi-checkbox-multiple-marked',
    iconActive: 'mdi mdi-checkbox-multiple-marked c-primary',
    // routerLink: '/home/contacts',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [],
  },
  {
    id: 32,
    path: '/Administration/Team',
    name: 'SideMenuView.Administration.Team',
    component: Team,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(TeamPermissions),
    groupId: 4,
    order: 3,
    icon: 'mdi mdi-account-outline c-white',
    iconActive: 'mdi mdi-account-outline c-blue-dark',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.Team',
        isDisabled: false,
        route: '/home/Administration/Team',
        groupName: 'system-admin',
      },
    ],
  },
  {
    id: 11,
    path: '/Activities',
    name: 'SideMenuView.ActivitiesView.ActivitiesView',
    component: ActivitiesView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 2,
    order: 2,
    icon: 'mdi mdi-format-list-checks c-white',
    iconActive: 'mdi mdi-format-list-checks c-blue-dark',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'SideMenuView.ActivitiesView.ActivitiesView',
        isDisabled: false,
        route: '/home/Activities',
        groupName: 'contact-center',
      },
    ],
  },
  {
    id: 37,
    path: '/FormBuilder/*',
    name: 'SideMenuView.FormBuilder',
    component: FormBuilderLayout,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(FormBuilderPermissions)],
    groupId: 16,
    order: 10,
    icon: 'mdi mdi-account-outline c-white',
    iconActive: 'mdi mdi-account-outline c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [],
  },
  {
    id: 90,
    path: '/import-details',
    name: 'ImportDetailsView:import-details',
    component: ImportDetailsView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 2,
    order: 3,
    icon: 'mdi mdi-account-outline c-white',
    iconActive: 'mdi mdi-account-outline c-primary',
    isDisabled: false,
    showInMenu: false,
    isExact: false,
    breadcrumbs: [
      {
        name: 'ImportDetailsView:import-details',
        isDisabled: false,
        route: '/home/import-details',
      },
    ],
  },
  {
    id: 89,
    path: '/monthly-calendar-view',
    name: 'MonthlyCalendarView:monthly-calendar-view',
    component: MonthlyCalendarView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 11,
    order: 3,
    icon: 'mdi mdi-home-outline c-white',
    iconActive: 'mdi mdi-home-outline c-primary',
    isDisabled: false,
    showInMenu: false,
    isExact: false,
    breadcrumbs: [
      {
        name: 'MonthlyCalendarView:monthly-calendar-view',
        isDisabled: false,
        route: '/home/monthly-calendar-view',
      },
    ],
  },
  {
    id: 34,
    path: '/lookups/*',
    name: 'homeLayout.lookupsView.lookups',
    component: LookupsLayout,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LookupsPermissions)],
    groupId: 16,
    order: 1,
    icon: 'mdi mdi-menu -multiple-marked',
    iconActive: 'mdi mdi-menu -multiple-marked c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'homeLayout.lookupsView.lookups',
        isDisabled: false,
        route: '/home/lookups',
        groupName: 'system-parameters',
      },
    ],
  },
  {
    id: 36,
    path: '/templates/*',
    name: 'TemplatesView:templates-builder',
    component: TemplatesLayout,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(TemplatesPermissions)],
    groupId: 16,
    order: 3,
    icon: 'mdi mdi-newspaper-variant-multiple c-blue-lighter',
    iconActive: 'mdi mdi-newspaper-variant-multiple c-white',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'TemplatesView:templates-builder',
        isDisabled: false,
        route: '/home/templates/view',
        groupName: 'system-parameters',
      },
    ],
  },
  {
    id: 203,
    path: '/task-configuration/*',
    name: 'TaskConfiguration:task-configuration',
    component: TaskConfigurationLayout,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 16,
    order: 5,
    icon: 'mdi mdi-view-list-outline c-blue-lighter',
    iconActive: 'mdi mdi-view-list-outline c-white',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'TaskConfiguration:task-configuration',
        isDisabled: false,
        route: '/home/task-configuration/view',
        groupName: 'system-parameters',
      },
    ],
  },
  {
    id: 204,
    path: '/my-tasks',
    name: 'TaskConfiguration:my-tasks',
    component: ProjectTasksKanbanBoard,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: '',
    iconActive: '',
    isDisabled: false,
    isExact: false,
    breadcrumbs: [
      {
        name: 'TaskConfiguration:my-tasks',
        isDisabled: false,
        route: '/home/my-tasks',
      },
    ],
  },
  {
    id: 35,
    path: '/activity-type',
    name: 'homeLayout.activity-type.activity-type',
    component: ActivitiesTypeView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ActivityTypePermissions)],
    groupId: 16,
    order: 1,
    icon: 'mdi mdi-upload-network',
    iconActive: 'mdi mdi-upload-network c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'homeLayout.activity-type.activity-type',
        isDisabled: false,
        route: '/home/activity-type',
        groupName: 'system-parameters',
      },
    ],
  },
  {
    id: 47,
    path: '/dashboard/*',
    name: 'Dashboard:admin-dashboard',
    component: DashboardLayout,
    layout: '/home',
    default: true,
    isRoute: true,
    authorize: true,
    icon: 'mdi mdi-chart-bell-curve-cumulative c-white',
    iconActive: 'mdi mdi-chart-bell-curve-cumulative c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(AdminDashboardPermissions)],
    groupId: 1,
    order: 1,
    breadcrumbs: [
      {
        name: 'Dashboard:admin-dashboard',
        isDisabled: false,
        route: '/home/dashboard',
        groupName: 'dashboard',
      },
    ],
  },
  // keep this for power-bi config
  // {
  //   id: 176,
  //   path: '/userlogin-dashboard',
  //   name: 'Dashboard:userlogin-dashboard',
  //   component: UserDashboard,
  //   layout: '/home',
  //   default: false,
  //   isRoute: true,
  //   authorize: true,
  //   icon: 'mdi mdi-login-variant c-white',
  //   iconActive: 'mdi mdi-login-variant c-blue-dark',
  //   isDisabled: false,
  //   showInMenu: true,
  //   isExact: false,
  //   roles: [],
  //   groupId: 1,
  //   order: 2,
  //   breadcrumbs: [
  //     {
  //       name: 'Dashboard:userlogin-dashboard',
  //       isDisabled: true,
  //       route: '/home',
  //       groupName: 'dashboard',
  //     },
  //   ],
  // },
  {
    id: 80,
    path: '/branches/*',
    name: 'BranchView:branches',
    component: BranchesLayout,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(BranchesPermissions)],
    groupId: 4,
    order: 2,
    icon: 'mdi mdi-badge-account-outline',
    iconActive: 'mdi mdi-badge-account',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'Branches.Branches:branches',
        isDisabled: false,
        route: '/home/branches',
        groupName: 'system-admin',
      },
    ],
  },
  {
    id: 87,
    path: '/rotation-configuration',
    name: 'general-configration',
    component: SystemConfigurationView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(RotationConfigurationPermissions)],
    groupId: 31,
    order: 1,
    icon: 'mdi mdi-badge-account-outline',
    iconActive: 'mdi mdi-badge-account',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'general-configration',
        isDisabled: false,
        route: '/home/rotation-configuration',
        groupName: 'system-configuration',
      },
    ],
  },
  {
    id: 86,
    path: '/Reports-Builder/*',
    name: 'ReportsBuilderg:reportsbuilder',
    component: ReportsBuilderLayout,
    layout: '/home',
    default: false,
    icon: 'mdi mdi-chart-box-plus-outline c-white',
    iconActive: 'mdi mdi-chart-box-plus-outline c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(ReportBuilderPermissions)],
    groupId: 23,
    order: 2,
    breadcrumbs: [
      {
        name: 'ReportsBuilderg:reportsbuilder',
        isDisabled: false,
        route: '/home/country',
        groupName: 'Reports',
      },
    ],
  },
  {
    id: 65,
    path: '/policies/*',
    name: 'Policies:policies',
    component: PoliciesLayout,
    layout: '/home',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(PolicyPermissions)],
    groupId: 4,
    order: 9,
    icon: 'mdi mdi-shield-check-outline',
    iconActive: 'mdi mdi-shield-check-outline c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: 'Policies:policies',
        isDisabled: false,
        route: '/home/policies/view',
        groupName: 'system-admin',
      },
    ],
  },
  {
    id: 201,
    path: '/approvals-configuration',
    name: 'ApprovalsConfiguration:approvals-configuration',
    component: ApprovalsConfigurationView,
    roles: [...Object.values(ApprovalsConfigurationPermissions)],
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    groupId: 31,
    order: 2,
    icon: 'mdi mdi-home-outline c-white',
    iconActive: 'mdi mdi-home-outline c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: 'ApprovalsConfiguration:approvals-configuration',
        isDisabled: true,
        route: '/home/rotation-configuration',
        groupName: 'system-configuration',
      },
    ],
  },
  {
    id: 205,
    path: '/user-groups',
    name: 'UsersView:UserGroups.user-groups',
    component: GroupsView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(GroupsPermissions)],
    icon: '',
    iconActive: '',
    isDisabled: false,
    isExact: false,
    breadcrumbs: [
      {
        name: 'UsersView:UserGroups.user-groups',
        isDisabled: false,
        route: '/home/user-groups',
      },
    ],
  },
  {
    id: 206,
    path: '/users-management',
    name: 'UsersView:UsersManagement.users-management',
    component: UsersManagementView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(UsersManagementPermissions)],
    icon: '',
    iconActive: '',
    isDisabled: false,
    isExact: false,
    breadcrumbs: [
      {
        name: 'UsersView:UsersManagement.users-management',
        isDisabled: false,
        route: '/home/users-management',
      },
    ],
  },
  {
    id: 208,
    path: '/user-approvals',
    name: 'UsersView:UserApprovals.user-approvals',
    component: UserApprovalsView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: '',
    iconActive: '',
    isDisabled: false,
    isExact: false,
    breadcrumbs: [
      {
        name: 'UsersView:UserApprovals.user-approvals',
        isDisabled: false,
        route: '/home/user-approvals',
      },
    ],
  },
  {
    id: 303,
    path: '/activity-type-bulk-update',
    name: 'activity-type-bulk-update',
    component: ActivitiesTypesBulkUpdateView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'Activity-type',
        isDisabled: false,
        route: '/home/activity-type',
        groupName: 'system-parameters',
      },
      {
        name: 'activity-type-bulk-update',
        isDisabled: false,
        route: '/home/AML/BranchAML',
        groupName: 'AML',
      },
    ],
  },
  {
    id: 205,
    path: '/user-groups',
    name: 'UsersView:UserGroups.user-groups',
    component: GroupsView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(GroupsPermissions)],
    icon: '',
    iconActive: '',
    isDisabled: false,
    isExact: false,
    breadcrumbs: [
      {
        name: 'UsersView:UserGroups.user-groups',
        isDisabled: false,
        route: '/home/user-groups',
      },
    ],
  },
  {
    id: 206,
    path: '/users-management',
    name: 'UsersView:UsersManagement.users-management',
    component: UsersManagementView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(UsersManagementPermissions)],
    icon: '',
    iconActive: '',
    isDisabled: false,
    isExact: false,
    breadcrumbs: [
      {
        name: 'UsersView:UsersManagement.users-management',
        isDisabled: false,
        route: '/home/users-management',
      },
    ],
  },
  {
    id: 208,
    path: '/user-approvals',
    name: 'UsersView:UserApprovals.user-approvals',
    component: UserApprovalsView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: '',
    iconActive: '',
    isDisabled: false,
    isExact: false,
    breadcrumbs: [
      {
        name: 'UsersView:UserApprovals.user-approvals',
        isDisabled: false,
        route: '/home/user-approvals',
      },
    ],
  },
  {
    id: 303,
    path: '/activity-type-bulk-update',
    name: 'activity-type-bulk-update',
    component: ActivitiesTypesBulkUpdateView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'Activity-type',
        isDisabled: false,
        route: '/home/activity-type',
        groupName: 'system-parameters',
      },
      {
        name: 'activity-type-bulk-update',
        isDisabled: false,
        route: '/home/activity-type-bulk-update',
      },
    ],
  },
  {
    id: 308,
    path: '/activity-builder',
    name: 'SideMenuView.activity-builder',
    component: ActivityBuilderView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 16,
    order: 2,
    icon: 'mdi mdi-creation c-white',
    iconActive: 'mdi mdi-creation c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SideMenuView.activity-builder',
        isDisabled: true,
        route: '/home/activity-builder',
      },
    ],
  },
  {
    id: 307,
    path: '/billing-users/*',
    name: 'billing-users',
    component: UsersManagementLayout,
    layout: '/home',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'Billing',
        isDisabled: false,
        route: '/home/billing',
      },
      {
        name: 'Users',
        isDisabled: true,
        route: '/home/users',
      },
    ],
  },
  {
    id: 306,
    path: '/auto-correspondence',
    name: 'AutoCorrespondence:auto-correspondence',
    component: AutoCorrespondenceView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(AutoCorrespondencePermissions)],
    groupId: 31,
    order: 6,
    icon: 'mdi mdi-badge-account-outline',
    iconActive: 'mdi mdi-badge-account',
    isDisabled: true,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'AutoCorrespondence:auto-correspondence',
        isDisabled: true,
        route: '/home/auto-correspondencen',
        groupName: 'system-configuration',
      },
    ],
  },
];
