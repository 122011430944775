/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonBase, DialogActions } from '@material-ui/core';
import {
  XeleAutocomplete,
  XeleCheckbox,
  XeleDialog,
  XeleInput,
  Spinner,
} from '../../../../../Components';
import 'suneditor/dist/css/suneditor.min.css';
import { getDownloadableLink, showError } from '../../../../../Helper';
import { GetActivities } from '../../../../../Services/ActivitiesServices';
import { RunSystemTemplateForUnit } from '../../../../../Services/SystemTemplateServices';

export const TemplatesTemplatebuilderComponentPreviewDialog = ({
  activeItem,
  isOpen,
  isOpenChanged,
  parentTranslationPath,
  translationPath,
}) => {
  const data = JSON.parse(activeItem.templateParams);
  const searchTimer = useRef(null);
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });
  const [state, setState] = useState({
    templateId: activeItem.systemTemplateId,
    unitId: null,
    unitIds: null,
    leadId: null,
    contactId: null,
    activityId: null,
    userId: null,
  });
  const DetailsLeadsList = false;
  const IsLoadingUnits = false;
  const IsLoading = false;
  const [withsize, setwithsize] = useState('lg');
  const [IsLoadingActivities, setIsLoadingActivities] = useState(false);
  const [IsLoadingRunSystemTemplate, setIsLoadingRunSystemTemplate] =
    useState(false);
  const [Activities, setActivities] = useState(false);
  const [file, setFile] = useState(null);
  const [isCheckedHeader, setIsCheckedHeader] = useState(false);
  const [isCheckedfooter, setIsCheckedfooter] = useState(false);

  const detailsUnitsList = {
    result: [],
    totalCount: 0,
  };
  const maxHeight = '137px';
  const mediaprint =
    '@page {margin: 2mm;}@media print {.page-header,.page-header-space {height: 137px;max-height: 137px;}.page-footer,.page-footer-space {height: 137px;max-height: 137px;}.page-footer {position: fixed;bottom: 0;width: 100%;}.page-header {position: fixed;top: 0mm;width: 100%;}.page {page-break-after: always;}thead {display: table-header-group;}tfoot {display: table-footer-group;}button {display: none;}body {margin: 0;}}';
  const [HeaderAndFooter, setHeaderAndFooter] = useState({
    documentsFooterImageId: null,
    documentsHeaderImageId: null,
  });
  const RunSystemTemplateHtmlFis = useCallback(async (files, check) => {
    setIsLoadingRunSystemTemplate(true);
    const res = await RunSystemTemplateForUnit(files);

    if (!(res && res.status && res.status !== 200)) {
      if (!check) {
        setHeaderAndFooter({
          documentsFooterImageId: (res && res.documentsFooterImageId) || null,
          documentsHeaderImageId: (res && res.documentsHeaderImageId) || null,
        });
      }
      setFile(res && res.templateContent);
      const content = document.getElementById('divcontents');
      const pri = document.getElementById('ifmcontentstoprint').contentWindow;
      pri.document.open();
      pri.document.write(content.innerHTML);
    } else showError(t(`RunSystemTemplate-failed`));

    setIsLoadingRunSystemTemplate(false);
  }, []);

  const getActivities = useCallback(async (search) => {
    setIsLoadingActivities(true);

    const body = {
      pageSize: 25,
      pageIndex: 1,
      search,
      fromDate: null,
      toDate: null,
      filterBy: null,
      orderBy: null,
      agentId: null,
      activityTypesIds: [],
      relatedUnitNumberId: null,
      relatedLeadNumberId: null,
      relatedWorkOrderId: null,
      relatedMaintenanceContractId: null,
      relatedPortfolioId: null,
      isWithoutRelatedActivities: false,
      relationType: null,
    };

    const result = await GetActivities(body);
    if (!(result && result.status && result.status !== 200))
      setActivities(result.result);
    else setActivities([]);
    setIsLoadingActivities(false);
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    data.findIndex((element) => element === 'ActivityId') !== -1 &&
      getActivities();
  }, []);

  const onSizeClickedFun = useCallback(async (size) => {
    if (size === 'lg') setwithsize('xl');
    else setwithsize('lg');
  }, []);

  const printPartOfPage = async () => {
    const pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.close();
    pri.focus();
    pri.print();
  };

  return (
    <XeleDialog
      titleText={`${t(`preview-template`)} ${
        activeItem.templateName
      }`}
      maxWidth={withsize}
      reSize
      dialogContent={
        <div className='templates-preview-dialog'>
          {(data.findIndex((element) => element === 'UnitIds') !== -1 && (
            <div className='d-flex mb-2'>
              <XeleInput
                idRef='unitIdRef'
                labelValue='unitId'
                value={state.unitIds || ''}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  setState((items) => ({
                    ...items,
                    unitIds: `${value}` || null,
                  }));
                }}
              />
            </div>
          )) ||
            ''}
          {(data.findIndex((element) => element === 'UnitId') !== -1 && (
            <div className='d-flex mb-2'>
              <XeleInput
                idRef='unitIdRef'
                labelValue='unitId'
                value={state.unitId || ''}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  setState((items) => ({
                    ...items,
                    unitId: value || null,
                  }));
                }}
              />
              <XeleAutocomplete
                idRef='assignInquiryToUnitRef'
                data={detailsUnitsList && detailsUnitsList.result}
                inputPlaceholder='type-to-search-in-unit'
                labelValue='all-unit'
                multiple={false}
                displayLabel={(option) => (option && option.name) || ''}
                chipsLabel={(option) => (option && option.name) || ''}
                withoutSearchButton
                renderOption={(option) => (
                  <div className='d-flex-column'>
                    <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                      {option.name}
                    </div>
                    <span className='c-gray-secondary'> {option.refNo} </span>
                  </div>
                )}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(e, newValue) => {
                  setState((items) => ({
                    ...items,
                    unitId: (newValue && newValue.id) || null,
                  }));
                }}
                isLoading={IsLoadingUnits}
                withLoader
              />
            </div>
          )) ||
            ''}
          {(data.findIndex((element) => element === 'LeadId') !== -1 && (
            <div className='d-flex'>
              <XeleInput
                idRef='leadIdRef'
                labelValue='leadId'
                value={(state && state.leadId) || ''}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  setState((items) => ({
                    ...items,
                    leadId: value || null,
                  }));
                }}
              />
              <XeleAutocomplete
                idRef='assignInquiryTleadRef'
                data={(DetailsLeadsList && DetailsLeadsList.result) || ''}
                inputPlaceholder='type-to-search-in-lead'
                labelValue='all-lead'
                multiple={false}
                displayLabel={(option) => (option && option.name) || ''}
                chipsLabel={(option) => (option && option.name) || ''}
                withoutSearchButton
                renderOption={(option) => (
                  <div className='d-flex-column'>
                    <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                      {option.name}
                    </div>
                    <span className='c-gray-secondary'> {option.refNo} </span>
                  </div>
                )}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(e, newValue) => {
                  setState((items) => ({
                    ...items,
                    leadId: (newValue && newValue.id) || null,
                  }));
                }}
                isLoading={IsLoading.allLeads}
                withLoader
              />
            </div>
          )) ||
            ''}
          {(data.findIndex((element) => element === 'ContactId') !== -1 && (
            <XeleInput
              idRef='contactIdRef'
              labelValue='contactId'
              value={(state && state.contactId) || ''}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputChanged={(event) => {
                const { value } = event.target;
                setState((items) => ({
                  ...items,
                  contactId: value || null,
                }));
              }}
            />
          )) ||
            ''}
          {(data.findIndex((element) => element === 'ActivityId') !== -1 && (
            <div className='d-flex'>
              {' '}
              <XeleInput
                idRef='activityIdRef'
                labelValue='activityId'
                value={(state && state.activityId) || ''}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  setState((items) => ({
                    ...items,
                    activityId: value || null,
                  }));
                }}
              />
              <XeleAutocomplete
                idRef='assignActivitiesitRef'
                data={(Activities && Activities) || []}
                inputPlaceholder='type-to-search-in-Activities'
                labelValue='all-Activities'
                multiple={false}
                displayLabel={(option) =>
                  (option &&
                    option.activityType &&
                    option.activityType.activityTypeName) ||
                  ''
                }
                chipsLabel={(option) =>
                  (option &&
                    option.activityType &&
                    option.activityType.activityTypeName) ||
                  ''
                }
                withoutSearchButton
                renderOption={(option) => (
                  <div className='d-flex-column'>
                    <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                      activity Id : {option.activityId || ''}
                    </div>
                    <span className='c-gray-secondary'>
                      {' '}
                      {option.agentName} / {option.contactName} /{' '}
                      {option.createdByName}/{' '}
                      {option.activityType.activityTypeName || ''}{' '}
                      {option.activityDate}/{' '}
                    </span>
                  </div>
                )}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getActivities(value);
                  }, 700);
                }}
                onChange={(e, newValue) => {
                  setState((items) => ({
                    ...items,
                    activityId: (newValue && newValue.activityId) || null,
                  }));
                }}
                isLoading={IsLoadingActivities}
                withLoader
              />
            </div>
          )) ||
            ''}
          {(data.findIndex((element) => element === 'UserId') !== -1 && (
            <XeleInput
              idRef='UserIddRef'
              labelValue='UserId'
              value={(state && state.UserId) || ''}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputChanged={(event) => {
                const { value } = event.target;
                setState((items) => ({
                  ...items,
                  UserId: value || null,
                }));
              }}
            />
          )) ||
            ''}
          <div style={{ position: 'relative' }}>
            <Spinner isActive={IsLoadingRunSystemTemplate} />
            <div className='PrintorsavePDF'>
              {/* <ButtonBase
                className='btns theme-outline mb-2 '

              >
                <span className='mdi mdi-printer' />
                {t(`Printorsave`)}
                <span className='mdi mdi-file-pdf-box' />
              </ButtonBase> */}
            </div>
            <div id='divcontents' style={{ display: 'none' }}>
              {/* {HeaderAndFooter.documentsHeaderImageId !== null && (
                <header className='header'>
                  <img alt='HeaderPSITemplet' src={getDownloadableLink(HeaderAndFooter.documentsHeaderImageId) || null} style={{ width: '100%', maxHeight }} />
                </header>
              )} */}

              <style>{mediaprint}</style>
              {isCheckedHeader &&
                HeaderAndFooter.documentsHeaderImageId !== null && (
                  <div className='page-header'>
                    <img
                      alt='FooterPSITemplet'
                      src={
                        getDownloadableLink(
                          HeaderAndFooter.documentsHeaderImageId
                        ) || null
                      }
                      style={{ width: '100%', maxHeight }}
                    />
                  </div>
                )}

              <table>
                {isCheckedHeader &&
                  HeaderAndFooter.documentsHeaderImageId !== null && (
                    <thead>
                      <tr>
                        <td>
                          <div className='page-header-space' />
                        </td>
                      </tr>
                    </thead>
                  )}

                <tbody>
                  <tr>
                    <td>
                      <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: file }}
                      />
                    </td>
                  </tr>
                </tbody>
                {isCheckedfooter &&
                  HeaderAndFooter.documentsFooterImageId !== null && (
                    <tfoot>
                      <tr>
                        <td>
                          <div className='page-footer-space' />
                        </td>
                      </tr>
                    </tfoot>
                  )}
              </table>
              {isCheckedfooter &&
                HeaderAndFooter.documentsFooterImageId !== null && (
                  <div className='page-footer'>
                    <img
                      alt='FooterPSITemplet'
                      src={
                        getDownloadableLink(
                          HeaderAndFooter.documentsFooterImageId
                        ) || null
                      }
                      style={{ width: '100%', maxHeight }}
                    />
                  </div>
                )}
              {/* {HeaderAndFooter.documentsFooterImageId !== null && (
                <footer>
                  <img alt='FooterPSITemplet' src={getDownloadableLink(HeaderAndFooter.documentsFooterImageId) || null} style={{ width: '100%', maxHeight }} />
                </footer>
              )} */}
            </div>
            <iframe
              id='ifmcontentstoprint'
              title='ifmcontentstoprint'
              style={{ minHeight: 500 }}
              width='100%'
              height='100%'
            />
          </div>

          {/* <SunEditor
              idRef='SunEditorMIANview'
              disableToolbar={false}
              disable
              setOptions={{
                showPathLabel: false,
                minHeight: '50vh',
                maxHeight: '50vh',
                buttonList: [
                  ['fullScreen', 'preview', 'print']],
              }}
              setContents={Content}
            /> */}

          <DialogActions>
            <div className='unit-template-action-btns'>
              <XeleCheckbox
                idRef='isCheckedHeader'
                translationPathForData={translationPath}
                translationPath={translationPath}
                label='With Header'
                singleChecked={isCheckedHeader}
                onSelectedCheckboxClicked={() => {
                  setIsCheckedHeader(!isCheckedHeader);
                  RunSystemTemplateHtmlFis(state, true);
                }}
              />
              <XeleCheckbox
                idRef='isCheckedfooter'
                translationPathForData={translationPath}
                translationPath={translationPath}
                label='With footer'
                singleChecked={isCheckedfooter}
                onSelectedCheckboxClicked={() => {
                  setIsCheckedfooter(!isCheckedfooter);
                  RunSystemTemplateHtmlFis(state, true);
                }}
              />
            </div>
          </DialogActions>
          <div className='print-icon-wwww'>
            <ButtonBase
              className='print-button'
              onClick={() => {
                printPartOfPage();
              }}
            >
              <span className='print-icon' />
            </ButtonBase>
          </div>
        </div>
      }
      saveType='button'
      isOpen={isOpen}
      onCancelClicked={isOpenChanged}
      onSizeClicked={() => onSizeClickedFun(withsize)}
      saveText='view template'
      onSaveClicked={() => RunSystemTemplateHtmlFis(state)}
      onCloseClicked={isOpenChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};

TemplatesTemplatebuilderComponentPreviewDialog.propTypes = {
  activeItem: PropTypes.instanceOf(Object),
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  maintitleText: PropTypes.string,
};
TemplatesTemplatebuilderComponentPreviewDialog.defaultProps = {
  activeItem: null,
  maintitleText: 'preview-template',
};
