import React from 'react';
import moment from 'moment';
import {  Chip } from '@material-ui/core';
import {
  TableFilterTypesEnum,
} from '../../../../../Enums';


const optionFilterName = [
  {
    key: 1,
    value: 'pending',
  },
  {
    key: 2,
    value: 'approved',
  },
  {
    key: 3,
    value: 'rejected',
  },
  {
    key: 4,
    value: 'expired',
  },
];
const optionFilter = [
  {
    key: 1,
    value: 'publish',
  },
  {
    key: 2,
    value: 'unpublish',
  },
  {
    key: 3,
    value: 'changeUnitDetails',
  },
];

export const pendingRequestsHeaderData = [
  {
    id: 1,
    isSortable: true,
    label: 'requester-name',
    input: 'requesterName',
    filterType: TableFilterTypesEnum.textInput.key,
    component: (item) => (
      <span className='d-flex-column'>
        <span className='fw-simi-bold'>
          {item?.requestDate ? moment(item.requestDate).format('LL') : null}
        </span>
        <span className='light-text'>{item.requesterName}</span>
      </span>
    ),
  },
  {
    id: 2,
    isSortable: true,
    label: 'action-by',
    input: 'actionByName',
    filterType: TableFilterTypesEnum.textInput.key,
    component: (item) => (
      <span className='d-flex-column'>
        <span className='fw-simi-bold'>
          {item?.actionDate ? moment(item.actionDate).format('LL') : null}
        </span>
        <span className='light-text'>{item.actionByName}</span>
      </span>
    ),
  },
  {
    id: 4,
    label: 'unit-id',
    input: 'unitId',
    filterType: TableFilterTypesEnum.textInput.key,
  },

  {
    id: 6,
    label: 'request-status',
    filterType: TableFilterTypesEnum.selectOption.key,
    optionFilterList: optionFilterName.map((item) => ({
      key: item.key,
      value: item.value,
    })),
    searchableKey: 'requestStatusId',
    component: (item) => {
      let label = '';
      let className = '';

      switch (item?.requestStatusId) {
        case 1:
          label = 'pending';
          className = 'pending-chip';
          break;
        case 2:
          label = 'approved';
          className = 'approved-chip';
          break;
        case 3:
          label = 'rejected';
          className = 'rejected-chip';
          break;
        case 4:
          label = 'expired';
          className = 'expired-chip';
          break;
        default:
          label = 'unknown';
          className = 'unknown-chip';
      }

      return (
        <span>
          <Chip className={className} label={label} />
        </span>
      );
    },
  },
  {
    id: 3,
    label: 'type-of-request',
    input: 'typeOfRequestName',
    filterType: TableFilterTypesEnum.selectOption.key,
    optionFilterList: optionFilter.map((item) => ({
      key: item.key,
      value: item.value,
    })),
    searchableKey : 'typeOfRequestId'
  },
].map((item, i) => ({
  id: i + 1,
  key: item.displayPath ?? item.searchableKey,
  ...item,
}));
