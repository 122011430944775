import React, { lazy } from 'react';
import { SwitchRoute } from '../../../Components/Route/SwitchRoute';

const UsersManagement = lazy(() =>
  import('./UsersManagement').then((module) => ({
    default: module.UsersManagement,
  }))
);

export const UsersManagementLayout = () => (
  <SwitchRoute
    routes={[
      {
        id: 307,
        path: '/billing-users',
        name: 'billing-users',
        component: UsersManagement,
        layout: '/home',
        default: true,
        isRoute: true,
        authorize: true,
        roles: [],
        isDisabled: false,
        showInMenu: false,
        isExact: true,
        breadcrumbs: [
          {
            name: 'Billing',
            isDisabled: false,
            route: '/home/billing',
          },
          {
            name: 'Users',
            isDisabled: true,
            route: '/home/billing-users',
          },
        ],
      },
    ]}
  />
);
