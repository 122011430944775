import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../SharedComponents';
import { TabsComponent } from '../../../Components';
import { ActivityBuilderTabsData } from './ActivityBuilderTabsData';
import { getIsAllowedPermission } from '../../../Helper';

export const ActivityBuilderView = () => {
  const parentTranslationPath = 'ActivityBuilder';
  const { t } = useTranslation(parentTranslationPath);

  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const getTabsDataWithPermissions = () => {
    const tabsDataWithPermissions = [];

    const allTabsData = Object.values(ActivityBuilderTabsData);

    allTabsData.forEach((item) => {
      const isTabWithPermission = getIsAllowedPermission(
        item.permissionsList,
        loginResponse,
        item.permissionsId
      );
      if (isTabWithPermission) tabsDataWithPermissions.push(item);
    });
    return tabsDataWithPermissions;
  };

  const onTabChanged = (_, tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  return (
    <div className='propx-view user-groups-view'>
      <div className='d-flex-v-center-h-between'>
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          headerTitle={'activity-builder'}
          subTitle={'activity-builder-heading'}
          wrapperClasses={'px-4 pt-4 w-75'}
          headerTitleClasses={'fz-30  mb-3'}
          subTitleClasses={'m-0'}
        />
      </div>

      <div className='d-flex px-4 mb-3 w-fit'>
        <span className='icons b-icon i-20 i-clock mr-2'></span>
        <span className='fw-simi-bold pointer c-propx'>{t(`history`)}</span>
      </div>
      <div className='d-flex'>
        <TabsComponent
          wrapperClasses='mx-4 w-fit miw-fit'
          data={getTabsDataWithPermissions()}
          orientation='vertical'
          labelInput='label'
          parentTranslationPath={parentTranslationPath}
          themeClasses='theme-propx'
          currentTab={activeTabIndex}
          onTabChanged={onTabChanged}
          withDynamicComponents
          dynamicComponentProps={{
            parentTranslationPath,
          }}
        />
      </div>
    </div>
  );
};
