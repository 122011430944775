import React, { useEffect, useState } from 'react';
import { XeleSelect } from '../../../../../../Components';
import { lookupItemsGetId } from '../../../../../../Services/LookupsServices';

export const LookupSelect = ({
  lookupTypeId,
  multiple,
  id,
  labelValue,
  placeholder,
  parentTranslationPath,
  translationPath,
  onSelectChanged,
  value,
  excludeLookupByName,
}) => {
  const [data, setData] = useState([]);
  const getLookupItems = async () => {
    const result = await lookupItemsGetId({
      lookupTypeId,
    });
    if (!(result?.status && result.status !== 200)) {
      setData(
        result.filter(
          (item) =>
            item.lookupItemName.toLowerCase() !==
            excludeLookupByName?.toLowerCase()
        )
      );
    }
  };
  useEffect(() => {
    getLookupItems();
  }, []);
  return (
    <div>
      <XeleSelect
        idRef={id}
        multiple={multiple}
        data={data}
        valueInput='lookupItemId'
        textInput='lookupItemName'
        labelValue={labelValue}
        value={value}
        placeholder={placeholder}
        wrapperClasses='my-2'
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onSelectChanged={(value) =>
          onSelectChanged(
            value,
            data.find((item) => item.lookupItemId === value)
          )
        }
      />
    </div>
  );
};
