import React, { useEffect, useState } from 'react';
import {
  XeleAutocomplete,
  XeleDialog,
} from '../../../../../Components';
import { ApplicationUserSearch } from '../../../../../Services/userServices';
import { SendApprovalNotification } from '../../../../../Services/UsersServices/UserApprovalServices';
import { showSuccess } from '../../../../../Helper';
import { useTranslation } from 'react-i18next';

export const NotifyDialog = ({
  notifyDialogOpen,
  parentTranslationPath,
  translationPath,
  activeItem,
  setNotifyDialogOpen,
  onSave,
  isOpenChanged,
  approveOrRejectRequest,
}) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [state, setState] = useState({
    userIds: [],
    unitId: activeItem?.unitId || undefined,
  });
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });
  const [selected, setSelected] = useState([]);

  const getAllUsers = async () => {
    setIsLoading(true);

    const res = await ApplicationUserSearch({
      pageIndex: 0,
      pageSize: 1000,
      name: '',
    });
    if (!(res && res.status && res.status !== 200)) {
      setData(res?.result);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getAllUsers();
  }, []);

  const handleSavedClicked = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    const body = { ...state };
    const res = await SendApprovalNotification(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(
        t(`send-notification-for-users-successfully`)
      );

      setNotifyDialogOpen(false);
      onSave();
    }
  };
  useEffect(() => {
    if (activeItem) {
      setSelected([
        { fullName: activeItem?.requesterName, usersId: activeItem?.requesterUserId},
      ]);
    }
  }, [activeItem]);
  return (
    <div>
      <XeleDialog
        isOpen={notifyDialogOpen}
        saveType='button'
        titleText={'notify-users'}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        maxWidth={'sm'}
        onSaveClicked={handleSavedClicked}
        onCloseClicked={isOpenChanged}
        onCancelClicked={isOpenChanged}
        saveClasses='btns theme-solid w-100 mx-2 mb-2'
        dialogContent={
          <XeleAutocomplete
            labelValue='notify-users'
            idRef='notifyUsersRef'
            data={data || []}
            multiple
            withoutSearchButton
            isLoading={isLoading}
            displayLabel={(option) => option?.fullName || ''}
            chipsLabel={(option) => option?.fullName || ''}
            onChange={(e, newValue) => {
              setState((prevState) => ({
                ...prevState,
                userIds: newValue?.map((item) => item.usersId),
              }));
              setSelected(newValue)
            }}
            selectedValues={selected || []}
            isWithError
            isSubmitted={isSubmitted}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        }
      />
    </div>
  );
};
