import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useReducer,
} from 'react';
import moment from 'moment';
import './GroupsView.scss';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useTitle } from '../../../../Hooks';
import { PageHeading } from '../../../../SharedComponents';
import { TableActions } from '../../../../Enums';
import { returnPropsByPermissions } from '../../../../Helper';
import { GroupsPermissions } from '../../../../Permissions';
import {
  XeleInput,
  XeleTable,
  XeleAutocomplete,
  PermissionsComponent,
} from '../../../../Components';
import { GroupManagementDialog, DeleteGroupDialog } from './Dialogs';
import { GetUsersGroups } from '../../../../Services/UsersServices';
import { OrganizationUserSearch } from '../../../../Services/userServices';
import { GetAllRoles } from '../../../../Services/roleServices';
import { getAllPoliciesServices } from '../../../../Services/PoliciesServices';

export const GroupsView = () => {
  const parentTranslationPath = 'UsersView';
  const translationPath = 'UserGroups';
  const searchTimer = useRef(null);

  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });

  useTitle(t(`user-groups`));

  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };

  const [isLoading, setIsLoading] = useReducer(reducer, {
    filtering: false,
  });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isGroupDialogOpen, setIsGroupDialogOpen] = useState(false);

  const [filter, setFilter] = useState({
    pageIndex: 1,
    pageSize: 150,
    groupName: null,
  });
  const [selected, setSelected] = useReducer(reducer, {
    createdBy: null,
  });
  const [data, setData] = useReducer(reducer, {
    groups: [],
    systemUsers: [],
    systemRoles: [],
    systemPolicies: [],
  });

  const [activeItem, setActiveItem] = useState(null);

  const getUserGroups = async () => {
    setIsLoading({ id: 'filtering', value: true });
    const body = {
      ...(filter || {}),
    };
    const res = await GetUsersGroups(body);
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'groups', value: res?.result || [] });
    } else {
      setData({ id: 'groups', value: [] });
    }
    setIsLoading({ id: 'filtering', value: false });
  };

  const getSystemUsers = async (searchedItem) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchedItem || '',
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setData({ id: 'systemUsers', value: localValue });
      } else setData({ id: 'systemUsers', value: [] });
    }
  };

  const getSystemRoles = async (searchedItem) => {
    const pageIndex = 1;
    const pageSize = 10;

    const res = await GetAllRoles(pageIndex, pageSize, searchedItem);
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'systemRoles', value: res?.result || [] });
    } else setData({ id: 'systemRoles', value: [] });
  };

  const getSystemPolicies = async (searchedItem) => {
    const res = await getAllPoliciesServices({
      pageIndex: 1,
      pageSize: 10,
      search: searchedItem || null,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'systemPolicies', value: res?.result || [] });
    } else setData({ id: 'systemPolicies', value: [] });
  };

  const actionClickHandler = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      if (actionEnum === TableActions.deleteText.key) {
        setActiveItem(item);
        setIsDeleteDialogOpen(true);
      } else if (actionEnum === TableActions.editText.key) {
        setActiveItem(item);
        setIsGroupDialogOpen(true);
      }
    },
    []
  );

  const getTableActionsWithPermissions = () => {
    const actionsList = [];

    if (returnPropsByPermissions(GroupsPermissions.EditGroup.permissionsId))
      actionsList.push({
        enum: TableActions.editText.key,
      });

    if (returnPropsByPermissions(GroupsPermissions.DeleteGroup.permissionsId))
      actionsList.push({
        enum: TableActions.deleteText.key,
      });

    return actionsList;
  };

  const reloadData = () => {
    getUserGroups();
  };

  useEffect(() => {
    getUserGroups();
  }, [filter]);

  return (
    <div className='propx-view propx-bg user-groups-view'>
      <div className='d-flex-v-center-h-between'>
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={'user-groups'}
          subTitle={'groups-heading'}
          wrapperClasses={'px-4 pt-4 w-25'}
          headerTitleClasses={'fz-30 mb-3'}
          subTitleClasses={'fz-12 m-0'}
        />
        <div className='w-25 px-4 d-flex-v-center-h-between'>
          <div className='w-100 d-flex-column mr-3'>
            <div className='w-100 d-flex-center fj-end'>
              <div className='w-25 mt-2 mr-3'>
                <XeleInput
                  value={filter.name}
                  idRef='group-nameRef'
                  labelClasses='mt-4'
                  onInputChanged={(e) =>
                    setFilter({
                      ...filter,
                      groupName: e?.target?.value || '',
                    })
                  }
                  inputPlaceholder={t(`group-name`)}
                />
              </div>
              <XeleAutocomplete
                idRef='createdByRef'
                labelClasses='Requierd-Color'
                inputPlaceholder={t(`creator`)}
                selectedValues={selected.createdBy || []}
                wrapperClasses='w-25 mr-2'
                data={data.systemUsers || []}
                withoutSearchButton
                multiple={false}
                displayLabel={(option) => option?.fullName || ''}
                onChange={(event, newValue) => {
                  setSelected({
                    id: 'createdBy',
                    value: newValue,
                  });
                  setFilter({
                    ...filter,
                    createdBy: newValue?.id || null,
                  });
                }}
                onOpen={() => {
                  if (data.systemUsers?.length === 0) getSystemUsers();
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getSystemUsers(value);
                  }, 1200);
                }}
                onKeyDown={() => {
                  setSelected({
                    id: 'createdBy',
                    value: null,
                  });
                }}
              />
            </div>
            <div className='w-100 d-flex-center fj-end'>
              <XeleAutocomplete
                idRef='rolesRef'
                inputPlaceholder={t(`role`)}
                selectedValues={selected.role}
                wrapperClasses='w-25 mr-2'
                data={data.systemRoles || []}
                displayLabel={(option) => option?.rolesName || ''}
                withoutSearchButton
                multiple={false}
                onChange={(event, newValue) => {
                  setSelected({ id: 'role', value: newValue });
                  setFilter({
                    ...filter,
                    rolesId: newValue?.rolesId || null,
                  });
                }}
                onOpen={() => {
                  if (data.systemRoles && data.systemRoles.length === 0)
                    getSystemRoles();
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getSystemRoles(value);
                  }, 1200);
                }}
                onKeyDown={() => {
                  setSelected({ id: 'role', value: null });
                }}
                isLoading={isLoading.systemRoles}
              />
              <XeleAutocomplete
                idRef='policysRef'
                inputPlaceholder={t(`policy`)}
                selectedValues={selected.policy}
                wrapperClasses='w-25 mr-2'
                data={data.systemPolicies || []}
                displayLabel={(option) => option?.policyDataName || ''}
                withoutSearchButton
                multiple={false}
                onChange={(event, newValue) => {
                  setSelected({ id: 'policy', value: newValue });
                  setFilter({
                    ...filter,
                    policyId: newValue?.policyDataId || null,
                  });
                }}
                onOpen={() => {
                  if (data.systemPolicies && data.systemPolicies.length === 0)
                    getSystemPolicies();
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getSystemPolicies(value);
                  }, 1200);
                }}
                onKeyDown={() => {
                  setSelected({ id: 'policy', value: null });
                }}
                isLoading={isLoading.systemPolicies}
              />
            </div>
          </div>
          <PermissionsComponent
            permissionsList={Object.values(GroupsPermissions)}
            permissionsId={[GroupsPermissions.AddGroup.permissionsId]}
          >
            <Button
              className='btns theme-propx solid'
              id='btn-save'
              disabled={isLoading.saving}
              onClick={() => setIsGroupDialogOpen(true)}
            >
              <span className='mdi mdi-plus' /> <span>{t(`add-new`)}</span>
            </Button>
          </PermissionsComponent>
        </div>
      </div>
      <div className='propx-view-container md'>
        <XeleTable
          data={data.groups || []}
          headerData={[
            {
              id: 1,
              label: 'name',
              input: 'name',
            },
            {
              id: 2,
              label: 'roles',
              input: 'userGroupRoles',
              component: (item) =>
                (
                  <span>
                    {item?.userGroupRoles &&
                      item.userGroupRoles.map((role) => (
                        <span className='table-capsule mr-1 fw-simi-bold'>
                          {role.rolesName || ''}
                        </span>
                      ))}
                  </span>
                ) || '',
            },
            {
              id: 3,
              label: 'policy',
              input: 'policyName',
            },
            {
              id: 4,
              label: 'created-date',
              input: 'created-date',
              component: (item) =>
                (
                  <span className='d-flex-column'>
                    <span className='fw-simi-bold'>
                      {item.createdOn
                        ? moment(item.createdOn).format('LL')
                        : null}
                    </span>
                    <span className='light-text'>{item.createdByName}</span>
                  </span>
                ) || '',
            },
          ]}
          actionsOptions={{
            onActionClicked: actionClickHandler,
          }}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          defaultActions={getTableActionsWithPermissions()}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isLoading={isLoading.campaignRequests}
        />
      </div>
      {isGroupDialogOpen && (
        <GroupManagementDialog
          isDialogOpen={isGroupDialogOpen}
          activeItem={activeItem}
          onSave={() => {
            setIsGroupDialogOpen(false);
            reloadData();
          }}
          onClose={() => {
            setIsGroupDialogOpen(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteGroupDialog
          activeItem={activeItem}
          isDeleteDialogOpen={isDeleteDialogOpen}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          reloadData={reloadData}
          onCloseClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          onCancelClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
};
