import React, { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
// import './TaskDetailsSideMenu.scss';
// import '../RequestSideDetails/RequestSideDetails.scss';
import {
  getDownloadableLink,
  getFirstLastNameLetters,
} from "../../../../../../Helper";
import { GetApplicationUserById } from "../../../../../../Services/userServices";
import { Avatar } from "@material-ui/core";
// import {CampaignTaskRejectDialog} from './CampaignTaskRejectDialog'

export const DeactivatedUserSideDetails = ({
  closeSideMenu,
  userId,
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });

  const [userData, setUserData] = useState(null);

  const getApplicationUserById = async () => {
    const res = await GetApplicationUserById(userId);
    if (!(res && res.status && res.status !== 200)) {
      setUserData(res);
    } else setUserData(null);
  };

  useEffect(() => {
    if (userId) getApplicationUserById();
  }, [userId]);

  return (
    <div className="marketing-module-view TaskDetailsSideMenu">
      <div className="d-flex-v-center-h-between py-2 px-3">
        <h1 className="fz-16 m-0 fw-simi-bold">
          {t(`user-details`)}
        </h1>
        <span className="mdi mdi-close pointer" onClick={closeSideMenu}></span>
      </div>
      <div className="relative golden-gradiant">
        <Avatar
          className="avatars-wrapper user-avatar"
          src={
            userData?.profileImg ? getDownloadableLink(userData.profileImg) : ""
          }
        >
          {getFirstLastNameLetters(userData?.fullName || "")}
        </Avatar>
      </div>
      <div className="mt-4 pt-4 px-3">
        <p className="fz-20 mb-1 fw-simi-bold text-primary">
          {userData?.fullName || ""}
        </p>
        <p className="fz-12 mb-1 text-tertiary">{userData?.email || ""}</p>
        <div className="d-flex mt-2 mb-3">
          <span
            className="d-flex-center d-flex-default w-fit fz-10 px-2 mr-2
                    bg-gray-light border-gray-secondary radius-full pointer"
          >
            {`@${userData?.userName}`}
          </span>
          <span
            className="d-flex-center d-flex-default w-fit relative fz-10 px-3 mr-2 
                    status-label radius-full pointer"
          >
            {t(`deactivated`)}
          </span>
        </div>
      </div>
      <div className="px-3 mx-2">
        <div className="py-3 task-details-container">
          {userData?.phoneNumber ? (
            <div className="mb-2">
              <h5 className="fw-simi-bold m-0 mb-1 fz-12">
                {t(`mobile`)}
              </h5>
              <p className="word-break">{userData?.phoneNumber || ""}</p>
            </div>
          ) : null}
          {userData?.whatsAppNumber ? (
            <div className="mb-2">
              <h5 className="fw-simi-bold m-0 mb-1 fz-12">
                {t(`whatsapp`)}
              </h5>
              <p className="word-break">{userData?.whatsAppNumber || ""}</p>
            </div>
          ) : null}
          {userData?.nationalityName ? (
            <div className="mb-2">
              <h5 className="fw-simi-bold m-0 mb-1 fz-12">
                {t(`nationality`)}
              </h5>
              <p className="word-break">{userData?.nationalityName || ""}</p>
            </div>
          ) : null}
          {userData?.dataSource ? (
            <div className="mb-2">
              <h5 className="fw-simi-bold m-0 mb-1 fz-12">
                {t(`data-source`)}
              </h5>
              <p className="word-break">{userData?.dataSource || ""}</p>
            </div>
          ) : null}
          {userData?.createdOn ? (
            <div className="mb-2">
              <h5 className="fw-simi-bold m-0 mb-1 fz-12">
                {t(`register-date`)}
              </h5>
              <p>{moment(userData?.createdOn).format("LL")}</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
