import React, {
  useState,
  useCallback,
  useReducer,
  useEffect,
  useRef,
} from 'react';
import moment from 'moment';
import Joi from 'joi';
import '../../TaskConfiguration.scss';
import listIcon from '../../../../../assets/images/icons/list.svg';
import xCloseIcon from '../../../../../assets/images/icons/x-close-dialog.svg';
import { DialogActions, DialogContent, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess } from '../../../../../Helper';
import { TaskConfigurationAssignmentType } from '../../../../../Enums';
import {
  XeleInput,
  XeleDialog,
  XeleAutocomplete,
  XeleSwitch,
  XeleDatePicker,
  XeleSelect,
} from '../../../../../Components';
import { RelatedToComponent } from './RelatedToComponent';
import { TaskDynamicForm } from '../TaskDynamicForm';
import { GetLookupItemsByLookupTypeName } from '../../../../../Services/LookupsServices';
import {
  GetUserId,
  OrganizationUserSearch,
} from '../../../../../Services/UsersServices';
import { CreateProjectTask } from '../../../../../Services/ProjectTask/ProjectTask';
import {
  GetTaskConfigById,
  GetTaskConfigurationsAsAnOptions,
} from '../../../../../Services/TaskConfiguration/TaskConfiguration';

export const AddTaskDialog = ({
  isDialogOpen,
  onClose,
  onSave,
  taskConfigurationId,
  taskRelatedToIds,
}) => {
  const parentTranslationPath = 'TaskConfiguration';
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const defaultTaskReminderItem = {
    systemTemplateId: 1089,
    expirationPeriod: 0,
    expirationPeriodFrequency: 1,
    receiverType: null,
  };

  const TimeTypes = [
    {
      key: 1,
      value: 'minutes',
    },
    {
      key: 2,
      value: 'hours',
    },
    {
      key: 3,
      value: 'days',
    },
  ];

  const [state, setState] = useState(null);
  const [selected, setSelected] = useReducer(reducer, {
    taskCategory: null,
    taskConfiguration: null,
    projectTaskAssignees: [],
    expirationDate: null,
    taskRelatedToList: [],
    formData: null,
    taskReminders: [defaultTaskReminderItem],
  });
  const [data, setData] = useReducer(reducer, {
    taskCategories: [],
    assigneeUsers: [],
    configData: {},
    taskConfigurations: [],
  });
  const [taskRelatedToList, setTaskRelatedToList] = useReducer(reducer, {
    relatedUnit: null,
    relatedProperty: null,
    relatedContact: null,
  });
  const [activeForm, setActiveForm] = useState(1);

  const getTaskCategoriesLookups = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'TaskCategory',
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'taskCategories', value: res.result || [] });
    } else setData({ id: 'taskCategories', value: [] });
  };

  const getAllAssigneeUsers = async (value) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 25,
      name: value || '',
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setData({
          id: 'assigneeUsers',
          value: localValue,
        });
      } else {
        setData({
          id: 'assigneeUsers',
          value: [],
        });
      }
    }
  };

  const getTaskConfigurationsAsAnOptions = async (
    searchValue,
    taskCategory
  ) => {
    const pageIndex = 1;
    const pageSize = 10;

    const res = await GetTaskConfigurationsAsAnOptions({
      pageIndex,
      pageSize,
      search: searchValue || '',
      taskCategoryId: taskCategory?.lookupItemId || null,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'taskConfigurations', value: res.result });
    } else setData({ id: 'taskConfigurations', value: [] });
  };

  const createProjectTask = async () => {
    const body = {
      ...(state || {}),
    };

    const res = await CreateProjectTask(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`task-created-successfully`));
      onSave();
    } else {
      showError(t(`task-create-failed`));
    }
  };

  const getTaskConfigById = async (configId) => {
    const res = await GetTaskConfigById(configId);
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'configData', value: res });
    }
  };

  const displayNextForm = () => {
    if (schema.error) {
      showError(t('Shared:please-fix-all-errors'));
      return;
    }
    setActiveForm(2);
  };

  const taskRemindersDecrementHandler = (index) => {
    const localRemindersList = [...(selected.taskReminders || [])];
    localRemindersList.splice(index, 1);

    if (localRemindersList.length === 0) {
      setSelected({ id: 'taskReminders', value: [defaultTaskReminderItem] });
    } else {
      setSelected({ id: 'taskReminders', value: localRemindersList });
    }
  };

  const taskRemindersIncrementHandler = (index) => {
    const localTaskReminders = [...(selected.taskReminders || [])];
    localTaskReminders.push(defaultTaskReminderItem);

    setSelected({ id: 'taskReminders', value: localTaskReminders });
  };

  const taskRemindersChangeHandler = (item, index, property, newValue) => {
    const localTaskReminders = [...(selected.taskReminders || [])];

    let newLocalItem = {
      ...(item || {}),
      [property]: newValue,
    };
    localTaskReminders[index] = newLocalItem;

    setSelected({ id: 'taskReminders', value: localTaskReminders });
  };

  const saveHandler = async (event) => {
    if (activeForm === 2) {
      event.preventDefault();
      selectedToStateConversionHandler();
    }
  };

  const getAppUserId = async (GUId) => {
    const res = await GetUserId(GUId);
    if (!(res && res.status && res.status !== 200)) {
      return res || null;
    }
  };

  const selectedToStateConversionHandler = async () => {
    const getMappedAssignees = () => {
      return (
        (selected.projectTaskAssignees &&
          Promise.all(
            selected.projectTaskAssignees.map(async (item) => ({
              userId: await getAppUserId(item.id),
            }))
          )) ||
        []
      );
    };

    const filterValidReminders = () => {
      const validReminders =
        (selected.taskReminders &&
          selected.taskReminders.filter(
            (item) =>
              item.systemTemplateId &&
              item.expirationPeriod &&
              item.expirationPeriodFrequency &&
              item.receiverType
          )) ||
        [];

      return validReminders;
    };

    let stateConversion = {};
    stateConversion = {
      taskCategoryId: selected.taskCategory?.lookupItemId || null,
      taskConfigId:
        taskConfigurationId ||
        selected.taskConfiguration?.taskConfigurationId ||
        null,
      expirationDate:
        selected.isWithExpiry || data?.configData?.isWithExpiry
          ? selected.expirationDate
          : null,
      projectTaskAssignees: await getMappedAssignees(),
      projectTaskReminders:
        selected.isWithReminder || data?.configData?.isWithReminder
          ? filterValidReminders()
          : [],
      formData: selected.formData ? JSON.stringify(selected.formData) : null,
      relatedToLeadId: taskRelatedToList?.relatedLead?.leadId || null,
      relatedToUnitId: taskRelatedToList?.relatedUnit?.id || null,
      relatedToPropertyId:
        taskRelatedToList?.relatedProperty?.propertyId || null,
      relatedToContactId: taskRelatedToList?.relatedContact?.id || null,
      ...(taskRelatedToIds || {}),
    };
    setState(stateConversion);
  };

  const schema = Joi.object({
    taskCategory: Joi.object().required(),
    taskConfiguration: Joi.object().required(),
    projectTaskAssignees: Joi.array().min(1),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(selected);

  useEffect(() => {
    if (state) createProjectTask();
  }, [state]);

  useEffect(() => {
    const configId =
      taskConfigurationId || selected.taskConfiguration?.taskConfigurationId;

    if (configId) getTaskConfigById(configId);
    else setData({ id: 'configData', value: null });
  }, [taskConfigurationId, selected.taskConfiguration]);

  return (
    <div>
      <XeleDialog
        isOpen={isDialogOpen}
        maxWidth='sm'
        contentClasses='p-0'
        dialogTitle={
          <div className='add-task-dialog w-100'>
            <div className='d-flex-v-center-h-between'>
              <div className='dialog-title-icon'>
                <img src={listIcon} />
              </div>
              <div className='xCloseIcon pointer' onClick={onClose}>
                <img src={xCloseIcon} />
              </div>
            </div>
            <div className='pt-3 font-b'>{t(`add-task`)}</div>
          </div>
        }
        dialogContent={
          <div className='add-task-dialog'>
            <form noValidate onSubmit={saveHandler}>
              <DialogContent>
                <>
                  {activeForm === 1 ? (
                    <div>
                      <XeleAutocomplete
                        idRef='TaskCategoryRef'
                        labelClasses='Requierd-Color'
                        inputPlaceholder={t(`select`)}
                        selectedValues={selected.taskCategory}
                        labelValue={t(`taskCategory`)}
                        wrapperClasses='mr-2 my-2'
                        data={data.taskCategories || []}
                        displayLabel={(option) => option.lookupItemName || ''}
                        multiple={false}
                        withoutSearchButton
                        onChange={(event, newValue) => {
                          setSelected({
                            id: 'edit',
                            value: {
                              ...selected,
                              taskCategory: newValue,
                              taskConfiguration: null,
                            },
                          });

                          if (newValue)
                            getTaskConfigurationsAsAnOptions('', newValue);
                        }}
                        onOpen={() => {
                          if (
                            data.taskCategories &&
                            data.taskCategories.length === 0
                          )
                            getTaskCategoriesLookups();
                        }}
                      />
                      {!taskConfigurationId && (
                        <XeleAutocomplete
                          idRef='taskConfigurationRef'
                          labelClasses='Requierd-Color'
                          inputPlaceholder={t(`select`)}
                          labelValue={t(`Task-name`)}
                          selectedValues={selected.taskConfiguration}
                          wrapperClasses='mr-2 my-3'
                          data={data.taskConfigurations || []}
                          multiple={false}
                          displayLabel={(option) => t(`${option.name || ''}`)}
                          withoutSearchButton
                          onChange={(event, newValue) => {
                            setSelected({
                              id: 'taskConfiguration',
                              value: newValue,
                            });
                          }}
                          isDisabled={!selected.taskCategory}
                          onInputKeyUp={(e) => {
                            const { value } = e.target;
                            if (searchTimer) clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              getTaskConfigurationsAsAnOptions(value);
                            }, 1200);
                          }}
                          onKeyDown={() => {
                            setSelected({
                              id: 'taskConfiguration',
                              value: null,
                            });
                          }}
                        />
                      )}
                      <XeleAutocomplete
                        idRef='AssigntoRef'
                        labelClasses='Requierd-Color'
                        inputPlaceholder={t(`select`)}
                        labelValue={t(`Assignto`)}
                        selectedValues={selected.projectTaskAssignees || []}
                        wrapperClasses='mr-2 my-2'
                        data={data.assigneeUsers || []}
                        chipsLabel={(option) => option.fullName || ''}
                        withoutSearchButton
                        multiple
                        displayLabel={(option) => option.fullName || ''}
                        renderOption={(option) =>
                          ((option.userName || option.fullName) &&
                            `${option.fullName} (${option.userName})`) ||
                          ''
                        }
                        onChange={(event, newValue) => {
                          setSelected({
                            id: 'projectTaskAssignees',
                            value: newValue,
                          });
                        }}
                        onOpen={() => {
                          if (
                            data.assigneeUsers &&
                            data.assigneeUsers.length === 0
                          )
                            getAllAssigneeUsers();
                        }}
                        onInputKeyUp={(e) => {
                          const { value } = e.target;
                          if (searchTimer) clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getAllAssigneeUsers(value);
                          }, 1200);
                        }}
                        onKeyDown={() => {
                          setSelected({
                            id: 'projectTaskAssignees',
                            value: null,
                          });
                        }}
                      />
                      {data?.configData?.taskConfigurationRelatedTo &&
                        data?.configData?.taskConfigurationRelatedTo?.length !==
                          0 &&
                        !taskRelatedToIds && (
                          <>
                            <RelatedToComponent
                              parentTranslationPath={parentTranslationPath}
                              configData={data.configData}
                              taskRelatedToList={taskRelatedToList}
                              setTaskRelatedToList={setTaskRelatedToList}
                            />
                          </>
                        )}
                      <div>
                        <XeleSwitch
                          idRef='With-approvalRef'
                          themeClass='thick-theme'
                          isChecked={
                            data?.configData?.isTaskRequireApproval || false
                          }
                          labelValue='With-approval'
                          parentTranslationPath={parentTranslationPath}
                        />
                      </div>
                      <div>
                        <XeleSwitch
                          idRef='With-expiryRef'
                          themeClass='thick-theme'
                          isChecked={data?.configData?.isWithExpiry || false}
                          labelValue='With-expiry'
                          parentTranslationPath={parentTranslationPath}
                        />

                        {data?.configData?.isWithExpiry && (
                          <div className='d-flex-center mt-3'>
                            <XeleDatePicker
                              idRef='expirationDateRef'
                              wrapperClasses='mr-2 pr-1'
                              placeholder='DD/MM/YYYY'
                              value={
                                selected.expirationDate
                                  ? selected.expirationDate
                                  : moment().format('YYYY-MM-DDTHH:mm:ss')
                              }
                              parentTranslationPath={parentTranslationPath}
                              onDateChanged={(newValue) => {
                                const expirationDateValue =
                                  (newValue &&
                                    moment(newValue).format(
                                      'YYYY-MM-DDTHH:mm:ss'
                                    )) ||
                                  moment()
                                    .add(2, 'minutes')
                                    .format('YYYY-MM-DDTHH:mm:ss');

                                setSelected({
                                  id: 'expirationDate',
                                  value: expirationDateValue,
                                });
                              }}
                            />
                            <XeleDatePicker
                              idRef='expirationTimeRef'
                              wrapperClasses='ml-2 pl-1'
                              isTimePicker
                              value={
                                selected.expirationDate
                                  ? selected.expirationDate
                                  : moment()
                                      .add(2, 'minutes')
                                      .format('YYYY-MM-DDTHH:mm:ss')
                              }
                              minDate={moment().format('YYYY-MM-DDTHH:mm:ss')}
                              parentTranslationPath={parentTranslationPath}
                              onDateChanged={(newValue) => {
                                setSelected({
                                  id: 'expirationDate',
                                  value:
                                    (newValue &&
                                      moment(newValue).format(
                                        'YYYY-MM-DDTHH:mm:ss'
                                      )) ||
                                    null,
                                });
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div>
                        <XeleSwitch
                          idRef='With-reminderRef'
                          themeClass='thick-theme'
                          isChecked={data?.configData?.isWithReminder || false}
                          labelValue='With-reminder'
                          parentTranslationPath={parentTranslationPath}
                        />
                        <div className='mt-3'>
                          {data?.configData?.isWithReminder &&
                            selected.taskReminders &&
                            selected.taskReminders.map((item, index) => (
                              <div
                                className='d-flex'
                                key={`ExpiredPeriod-${item?.index}`}
                              >
                                <div className='d-flex'>
                                  <div className='w-50'>
                                    <XeleInput
                                      idRef='ExpiredPeriodRef'
                                      wrapperClasses='mr-1 pr-1'
                                      value={item?.expirationPeriod || 0}
                                      isWithError
                                      parentTranslationPath={
                                        parentTranslationPath
                                      }
                                      min={0}
                                      type='number'
                                      onInputChanged={(event) => {
                                        let { value } = event.target;
                                        taskRemindersChangeHandler(
                                          item,
                                          index,
                                          'expirationPeriod',
                                          value
                                        );
                                      }}
                                      endAdornment={
                                        <XeleSelect
                                          idRef='ExpiredPeriodTimeTypeRef'
                                          data={TimeTypes}
                                          value={
                                            item?.expirationPeriodFrequency || 1
                                          }
                                          valueInput='key'
                                          textInput='value'
                                          onSelectChanged={(value) => {
                                            taskRemindersChangeHandler(
                                              item,
                                              index,
                                              'expirationPeriodFrequency',
                                              value
                                            );
                                          }}
                                          wrapperClasses='over-input-select w-auto'
                                          parentTranslationPath={
                                            parentTranslationPath
                                          }
                                        />
                                      }
                                    />
                                  </div>
                                  <div className='w-25 ml-1 pl-1'>
                                    <XeleSelect
                                      idRef='receiver-TypeRef'
                                      data={Object.values(
                                        TaskConfigurationAssignmentType
                                      )}
                                      value={item?.receiverType || null}
                                      emptyItem={{
                                        value: null,
                                        text: 'select-receiver-Type',
                                        isDisabled: false,
                                      }}
                                      valueInput='key'
                                      textInput='label'
                                      onSelectChanged={(value) => {
                                        taskRemindersChangeHandler(
                                          item,
                                          index,
                                          'receiverType',
                                          value
                                        );
                                      }}
                                      wrapperClasses='over-input-select w-auto'
                                      parentTranslationPath={
                                        parentTranslationPath
                                      }
                                    />
                                  </div>
                                </div>
                                <div className='reminder-controls'>
                                  <ButtonBase
                                    className='btns-icon theme-solid bg-secondary-light mt-1 mr-1'
                                    onClick={() =>
                                      taskRemindersDecrementHandler(index)
                                    }
                                  >
                                    <span className='mdi mdi-minus c-black-light' />
                                  </ButtonBase>
                                  {index === 0 && (
                                    <ButtonBase
                                      className='btns-icon theme-solid bg-secondary-light mt-1 mr-1'
                                      onClick={() =>
                                        taskRemindersIncrementHandler(index)
                                      }
                                    >
                                      <span className='mdi mdi-plus c-black-light' />
                                    </ButtonBase>
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {activeForm === 2 && data?.configData?.formName ? (
                    <TaskDynamicForm
                      dynamicFormOptions={{
                        formName: data.configData.formName,
                        formChangeHandler: (formData) => {
                          setSelected({
                            id: 'formData',
                            value: formData || null,
                          });
                        },
                      }}
                    />
                  ) : null}
                </>
              </DialogContent>
              <DialogActions>
                <div className='d-flex-center fj-end px-4 py-0'>
                  <ButtonBase
                    onClick={onClose}
                    className='btns theme-propx outlined'
                  >
                    {t(`cancel`)}
                  </ButtonBase>

                  {activeForm === 1 && (
                    <ButtonBase
                      className='btns theme-propx solid'
                      onClick={displayNextForm}
                      // disabled={schema.error}
                    >
                      {t(`next`)}
                    </ButtonBase>
                  )}
                  {activeForm === 2 && (
                    <ButtonBase
                      className='btns theme-propx solid'
                      onClick={saveHandler}
                    >
                      {t(`save-changes`)}
                    </ButtonBase>
                  )}
                </div>
              </DialogActions>
            </form>
          </div>
        }
      />
    </div>
  );
};
