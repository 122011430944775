import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataFileAutocomplete } from '../../Components';
import { GetAllWorkOrders } from '../../Services/WorkOrdersServices';

export const ActivityRelatedToInput = ({
  selected,
  isSubmitted,
  activityRelatedToList,
  setData,
  parentTranslationPath,
  translationPath,
  isGeneralDialog,
  isReplyDialog,
  actionItemId,
  setLeadId,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared'], {
    keyPrefix: translationPath,
  });
  const searchTimer = useRef(null);

  const [filter] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const getAllRelatedWorkOrders = useCallback(
    async (value) => {
      const response = await GetAllWorkOrders({ ...filter, search: value });

      if (!(response && response.status && response.status !== 200))
        setData({
          id: 'relatedWorkOrder',
          value: (response && response.result) || [],
        });
      else setData({ id: 'relatedWorkOrder', value: [] });
    },
    [filter]
  );

  const getRelatedToData = (relatedToItem, searchValue) => {
    if (isGeneralDialog) {
      if (relatedToItem.relatedToName === 'WorkOrder')
        getAllRelatedWorkOrders(searchValue);
      return;
    }

    if (relatedToItem.relatedToName === 'WorkOrder')
      getAllRelatedWorkOrders(searchValue);
  };

  useEffect(() => {
    activityRelatedToList.map((activity) => {
      if (activity.relatedToName === 'Lead') {
        setLeadId(selected?.relatedLead?.leadId);
      }
      return activity;
    });
  }, [selected.relatedLead]);

  useEffect(() => {
    if (
      activityRelatedToList &&
      activityRelatedToList.length &&
      !isReplyDialog
    ) {
      activityRelatedToList.forEach((item) => {
        getRelatedToData(item);
      });
    }
  }, [filter, activityRelatedToList, actionItemId]);
  return (
    <div className='dialog-content-item related-to-wrapper'>
      {(activityRelatedToList && activityRelatedToList.length && (
        <fieldset
          className={activityRelatedToList.length === 1 && 'full-width-wrapper'}
        >
          <legend>{t(`related-to`)}</legend>
          {activityRelatedToList.map((item, index) => (
            <DataFileAutocomplete
              labelValue={t(`${item.relatedToName}`)}
              idRef={`relatedToRef-${index}`}
              isDisabled={isReplyDialog}
              selectedValues={[]}
              multiple={false}
              data={[]}
              filterOptions={(options) => options}
              displayLabel={() => {}}
              renderFor={item.relatedToName && item.relatedToName.toLowerCase()}
              withoutSearchButton
              isSubmitted={isSubmitted}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  getRelatedToData(item, value);
                }, 1200);
              }}
              onKeyDown={() => {
                const newValue = null;
              }}
              isWithError
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onChange={() => {}}
            />
          ))}
        </fieldset>
      )) ||
        null}
    </div>
  );
};
