import { lazy } from 'react';

const TemplatesView = lazy(() =>
  import('../../Views/Home').then((module) => ({
    default: module.TemplatesView,
  }))
);
const TeamplateOpenFolder = lazy(() =>
  import('../../Views/Home/TemplatesView/TeamplateOpenFolder').then(
    (module) => ({ default: module.TeamplateOpenFolder })
  )
);
export const TemplatesRoutes = [
  {
    path: '/view',
    name: 'TemplatesView:templates-builder',
    component: TemplatesView,
    layout: '/home/templates',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'TemplatesView:templates-builder',
        isDisabled: true,
        route: '/home/templates/view',
        groupName: 'system-parameters',
      },
    ],
  },
  {
    path: '/OpenFile',
    name: 'TemplatesView:templates-builder',
    component: TeamplateOpenFolder,
    layout: '/home/templates',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'TemplatesView:templates-builder',
        isDisabled: false,
        route: '/home/templates/view',
        groupName: 'system-parameters',
      },
      {
        name: 'TemplatesView:OpenFile-templates',
        isDisabled: false,
        route: '/home/templates/OpenFile',
      },
    ],
  },
];
