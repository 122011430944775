import React, { useCallback, useEffect, useReducer, useState } from 'react';
import '../../UsersManagementView.scss';
import Joi from 'joi';
import xCloseIcon from '../../../../../../assets/images/icons/x-close-dialog.svg';
import { DialogActions, DialogContent, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  showError,
  showSuccess,
  GlobalTranslate,
} from '../../../../../../Helper';
import { XeleDialog } from '../../../../../../Components';
import { StaticLookupsIds } from '../../../../../../assets/json/StaticLookupsIds';
import {
  emailExpression,
  phoneExpression,
} from '../../../../../../Utils/Expressions';
import {
  GetApplicationUserById,
  ReactiveApplicationUser,
} from '../../../../../../Services/userServices';
import { DialogFields } from './DialogFields/DialogFields';
import { GetAllRoles } from '../../../../../../Services/roleServices';
import { getAllPoliciesServices } from '../../../../../../Services/PoliciesServices';
import {
  CheckExistEmail,
  CheckExistPhone,
  CheckExistStaffId,
  CheckExistUserName,
  CreateOrganizationUser,
  GetUsersGroups,
  OrganizationUserSearch,
} from '../../../../../../Services/UsersServices';
import {
  GetLookupItemsByLookupTypeName,
  lookupItemsGetId,
} from '../../../../../../Services/LookupsServices';
import { GetAllBranches } from '../../../../../../Services/BranchesServices';

export const AddUserDialog = ({
  isDialogOpen,
  onClose,
  onSave,
  parentTranslationPath,
  translationPath,
  reactiveUserId,
}) => {
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [selected, setSelected] = useReducer(reducer, {
    firstName: '',
    lastName: '',
    userName: '',
    staffCode: null,
    branch: null,
    dateOfJoining: null,
    email: '',
    phoneNumber: '',
    whatsAppNumber: '',
    nationality: null,
    languages: [],
    profileImg: null,
    department: null,
    station: null,
    jobTitle: null,
    reportsTo: null,
    userGroup: null,
    roles: [],
    policy: null,
    crmLogin: true,
    myLeadsLogin: false,
    operationType: null,
    unitSaleType: null,
    developerSpecialistFor: null,
    propertySpecialistFor: null,
    carAvailability: false,
    drivingLicenseAvailability: false,
    numberOfTrainingsAttend: null,
    reraExpiryDate: null,
  });
  const [data, setData] = useReducer(reducer, {
    branches: [],
    countries: [],
    languages: [],
    jobTitles: [],
    systemUsers: [],
    userGroups: [],
    systemPolicies: [],
    systemRoles: [],
    departments: [],
    stations: [],
    developers: [],
    properties: [],
    unitSaleTypes: [],
  });

  const [isExist, setIsExist] = useState({
    userName: null,
    email: null,
    phoneNumber: null,
    staffCode: null,
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const schema = Joi.object({
    firstName: Joi.string()
      .regex(/^.*\S*.*$/)
      .trim()
      .required()
      .messages({
        'string.empty': t(`first-name-is-required`),
      }),

    lastName: Joi.string()
      .regex(/^.*\S*.*$/)
      .trim()
      .required()
      .messages({
        'string.empty': t(`last-name-is-required`),
      }),
    userName: Joi.string()
      .regex(/^.*\S*.*$/)
      .trim()
      .required()
      .custom(
        (value, helpers) =>
          (isExist?.userName && helpers.error('any.invalid')) || value,
        `username-is-already-exist`
      )
      .messages({
        'any.invalid': t(`username-is-already-exist`),
        'string.empty': t(`username-is-required`),
      }),
    email: Joi.string()
      .required()
      .regex(emailExpression)
      .custom(
        (value, helpers) =>
          (isExist?.email && helpers.error('any.invalid')) || value,
        t(`email-is-already-exist`)
      )
      .messages({
        'any.invalid': t(`email-is-already-exist`),
        'string.empty': t(`email-is-required`),
        'string.pattern.base': t(`invalid-email`),
      }),
    phoneNumber: Joi.string()
      .required()
      .regex(phoneExpression)
      .custom((value, helpers) => {
        return (isExist?.phoneNumber && helpers.error('any.invalid')) || value;
      }, t(`mobile-number-is-already-exist`))
      .messages({
        'any.invalid': t(`mobile-number-is-already-exist`),
        'string.empty': t(`mobile-number-is-required`),
        'string.pattern.base': t(`invalid-mobile-number`),
      }),
    staffCode: Joi.any()
      .custom((value, helpers) => {
        return (isExist?.staffCode && helpers.error('any.invalid')) || value;
      }, t(`staffCode-is-already-exist`))
      .messages({
        'any.invalid': t(`staffCode-is-already-exist`),
      }),
    whatsAppNumber: Joi.string()
      .required()
      .regex(phoneExpression)
      .messages({
        'string.empty': t(`whatsapp-number-is-required`),
        'string.pattern.base': t(`invalid-whatsapp-number`),
      }),
    nationality: Joi.object()
      .required()
      .messages({
        'object.base': t(`nationality-is-required`),
      }),
    reportsTo: Joi.object()
      .required()
      .messages({
        'object.base': t(`line-manager-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(selected);

  const getSystemPolicies = async (searchedItem) => {
    const res = await getAllPoliciesServices({
      pageIndex: 1,
      pageSize: 10,
      search: searchedItem || null,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'systemPolicies', value: res?.result || [] });
    } else setData({ id: 'systemPolicies', value: [] });
  };

  const getSystemRoles = async (searchedItem) => {
    const pageIndex = 1;
    const pageSize = 10;

    const res = await GetAllRoles(pageIndex, pageSize, searchedItem);
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'systemRoles', value: res?.result || [] });
    } else setData({ id: 'systemRoles', value: [] });
  };

  const getSystemUsers = async (searchedItem) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchedItem || '',
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setData({ id: 'systemUsers', value: localValue });
      } else setData({ id: 'systemUsers', value: [] });
    }
  };
  const getUserById = async (userId) => {
    const res = await GetApplicationUserById(userId);
    if (!(res && res.status && res.status !== 200)) {
      fillInitSelected(res);
    }
  };

  const getBranches = async (branchName) => {
    try {
      const res = await GetAllBranches({
        pageSize: 10,
        pageIndex: 1,
        branchName,
      });
      setData({ id: 'branches', value: res.result || [] });
    } catch (error) {
      showError('something went wrong ');
    }
  };

  const getCountires = async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.Country,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'countries', value: res || [] });
    else setData({ id: 'countries', value: [] });
  };

  const getLanguages = async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.Languages,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'languages', value: res || [] });
    else setData({ id: 'languages', value: [] });
  };

  const getJobTitles = async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.JobTitle,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'jobTitles', value: res || [] });
    else setData({ id: 'jobTitles', value: [] });
  };

  const getDepartments = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'Departments',
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'departments', value: res?.result || [] });
    else setData({ id: 'departments', value: [] });
  };

  const getUnitSaleTypes = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'UnitSaleType',
      pageSize: 100,
      pageIndex: 1,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'unitSaleTypes', value: res?.result || [] });
    } else setData({ id: 'unitSaleTypes', value: [] });
  };

  const getStations = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'Stations',
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'stations', value: res?.result || [] });
    else setData({ id: 'stations', value: [] });
  };

  const getUserGroups = async (searchItem) => {
    const body = {
      pageIndex: 1,
      pageSize: 10,
      groupName: searchItem || null,
    };
    const res = await GetUsersGroups(body);
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'userGroups', value: res?.result || [] });
    } else {
      setData({ id: 'userGroups', value: [] });
    }
  };

  const getIsExistEmail = async (email) => {
    if (email && email.match(emailExpression)) {
      const response = await CheckExistEmail(email);
      setIsExist({
        ...isExist,
        email: response?.isExist || false,
      });
    }
  };

  const getIsExistPhoneNumber = async (phoneNumber) => {
    if (phoneNumber && phoneNumber.match(phoneExpression)) {
      const response = await CheckExistPhone(
        (!phoneNumber.startsWith('+') && `+${phoneNumber}`) || phoneNumber
      );
      setIsExist({
        ...isExist,
        phoneNumber: response?.isExist || false,
      });
    }
  };

  const getIsExistUserName = async (userName) => {
    if (userName) {
      const response = await CheckExistUserName(userName);
      setIsExist({
        ...isExist,
        userName: response?.isExist || false,
      });
    }
  };

  const getIsExistStaffCode = async (staffCode) => {
    const response = await CheckExistStaffId(staffCode);
    setIsExist({
      ...isExist,
      staffCode: response?.isExist || false,
    });
  };

  const fillInitSelected = (data) => {
    const department = data.departmentId
      ? {
          lookupItemId: data.departmentId,
          lookupItemName: data.department,
        }
      : null;
    const nationality = data.nationalityId
      ? {
          lookupItemId: data.nationalityId,
          lookupItemName: data.nationalityName,
        }
      : null;
    const station = data.stationId
      ? {
          lookupItemId: data.stationId,
          lookupItemName: data.station,
        }
      : null;
    const jobTitle = data.jobTitleId
      ? {
          lookupItemId: data.jobTitleId,
          lookupItemName: data.jobTitle,
        }
      : null;
    const userGroup = data.groupId
      ? {
          userGroupId: data.groupId,
          name: data.group,
        }
      : null;
    const reportsTo = data.reportsToId
      ? {
          id: data.reportsToId,
          fullName: data.reportsTo,
        }
      : null;
    const branch = data.branchId
      ? {
          branchId: data.branchId,
          branchName: data.branch,
        }
      : null;
    const policy = data.policyId
      ? {
          policyDataId: data.policyId,
          policyDataName: data.policy,
        }
      : null;

    const initSelected = {
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      userName: data.userName || '',
      staffCode: data.staffCode || null,
      dateOfJoining: data.dateOfJoining || null,
      email: data.email || '',
      phoneNumber: data.phoneNumber || '',
      whatsAppNumber: data.whatsAppNumber || '',
      languages: data.languages || [],
      profileImg: data.profileImg || null,
      roles: data.userTypes || [],
      nationality,
      department,
      station,
      jobTitle,
      branch,
      reportsTo,
      userGroup,
      policy,
      crmLogin: false,
      myLeadsLogin: false,
      operationType: null,
      propertySpecialistFor: null,
      developerSpecialistFor: null,
      unitSaleType: null,
      carAvailability: false,
      drivingLicenseAvailability: false,
      totalYearsofExperienceInPSI: null,
      numberOfTrainingsAttend: null,
      reraExpiryDate: null,
    };
    setSelected({ id: 'edit', value: initSelected });
  };
  const convertSelectedToBody = () => {
    const userRoles =
      selected.roles && selected.roles.length > 0
        ? selected.roles.map((item) => ({
            rolesId: item.rolesId,
          }))
        : [];

    const userLanguages =
      selected.languages && selected.languages.length > 0
        ? selected.languages.map((item) => ({
            languageId: item.lookupItemId || item.lookupsId,
          }))
        : [];

    const body = {
      firstName: selected.firstName || null,
      lastName: selected.lastName || null,
      userName: selected.userName || null,
      email: selected.email || null,
      profileImg: selected.profileImg || null,
      phoneNumber:
        (selected.phoneNumber &&
          !selected.phoneNumber.startsWith('+') &&
          `+${selected.phoneNumber}`) ||
        selected.phoneNumber ||
        '',
      whatsAppNumber:
        (selected.whatsAppNumber &&
          !selected.whatsAppNumber.startsWith('+') &&
          `+${selected.whatsAppNumber}`) ||
        (selected.whatsAppNumber &&
          selected.whatsAppNumber.match(phoneExpression) &&
          selected.whatsAppNumber) ||
        '',
      nationalityId: selected.nationality?.lookupItemId || null,
      reportsToId: selected.reportsTo?.id || null,
      dateOfJoining: selected.dateOfJoining || null,
      branchId: selected.branch?.branchId || null,
      jobTitleId: selected.jobTitle?.lookupItemId || null,
      groupId: selected.userGroup?.userGroupId || null,
      stationId: selected.station?.lookupItemId || null,
      departmentId: selected.department?.lookupItemId || null,
      policyId: selected.policy?.policyDataId || null,
      staffCode: selected.staffCode || null,
      crmLogin: selected.crmLogin || false,
      myLeadsLogin: selected.myLeadsLogin || false,
      drivingLicenseAvailability: selected.drivingLicenseAvailability || false,
      carAvailability: selected.carAvailability || false,
      userOperationType: selected.operationType?.id || null,
      unitSaleTypeId: selected.unitSaleType?.lookupItemId || null,
      developerId: selected.developerSpecialistFor?.contactsId || null,
      propertyId: selected.propertySpecialistFor?.propertyId || null,
      userLanguages,
      userRoles,
      numberOfTrainingsAttend: selected.numberOfTrainingsAttend || null,
      reraExpiryDate: selected.reraExpiryDate || null,
    };

    return body;
  };

  const createUsersGroup = async () => {
    const body = convertSelectedToBody();
    const res = await CreateOrganizationUser(body);
    if (res && !(res.status && res.status !== 200)) {
      showSuccess(t(`user-created-successfully`));
      onSave();
    } else showError(t(`user-create-failed`));
  };

  const ractiveApplicationUser = async () => {
    const body = convertSelectedToBody();
    const res = await ReactiveApplicationUser(reactiveUserId, body);
    if (res === true) {
      showSuccess(t(`user-activated-successfully`));
      onSave();
    } else showError(t(`user-activate-failed`));
  };

  const saveHandler = () => {
    setIsSubmitted(true);

    if (schema.error) {
      showError(GlobalTranslate.t('Shared:please-fix-all-errors'));
      return;
    }

    if (reactiveUserId) ractiveApplicationUser();
    else createUsersGroup();
  };

  useEffect(() => {
    if (reactiveUserId) getUserById(reactiveUserId);
  }, [reactiveUserId]);

  return (
    <div>
      <XeleDialog
        isOpen={isDialogOpen}
        maxWidth='sm'
        dialogTitle={
          <div className='users-manage-view add-task-dialog pb-3 w-100'>
            <div className='d-flex-v-center-h-between'>
              <div className='fw-simi-bold'>
                {reactiveUserId ? t(`reactivate-user`) : t(`new-user`)}
              </div>
              <div className='xCloseIcon pointer' onClick={onClose}>
                <img src={xCloseIcon} />
              </div>
            </div>
          </div>
        }
        dialogContent={
          <div className='users-manage-view'>
            <DialogContent>
              <>
                <DialogFields
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  setSelected={setSelected}
                  selected={selected}
                  data={data}
                  schema={schema}
                  isSubmitted={isSubmitted}
                  isExist={isExist}
                  functions={{
                    getIsExistUserName,
                    getIsExistStaffCode,
                    getBranches,
                    getIsExistEmail,
                    getIsExistPhoneNumber,
                    getCountires,
                    getLanguages,
                    getDepartments,
                    getUnitSaleTypes,
                    getStations,
                    getJobTitles,
                    getSystemUsers,
                    getUserGroups,
                    getSystemRoles,
                    getSystemPolicies,
                  }}
                />
              </>
            </DialogContent>
            <DialogActions>
              <div className='d-flex-center fj-end py-0 pt-3'>
                <ButtonBase
                  onClick={onClose}
                  className='btns theme-propx outlined'
                >
                  {t(`cancel`)}
                </ButtonBase>

                <ButtonBase
                  className='btns theme-propx solid mr-0'
                  onClick={saveHandler}
                >
                  {reactiveUserId ? t(`reactivate`) : t(`create`)}
                </ButtonBase>
              </div>
            </DialogActions>
          </div>
        }
      />
    </div>
  );
};
