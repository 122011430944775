import React, {
  useState,
  useEffect,
  useCallback,
  useReducer,
  useRef,
} from 'react';
import './UsersManagementView.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PageHeading } from '../../../../SharedComponents';
import { UpdatedUserStatus } from '../../../../Enums';
import { UsersManagementPermissions } from '../../../../Permissions';
import { TabsComponent, PermissionsComponent } from '../../../../Components';
import Button from '@material-ui/core/Button';
import { useTitle } from '../../../../Hooks';
import { AddUserDialog, DeactivateUserDialog } from './Dialogs';
import { UsersManagementTabsData } from './UsersManagementTabsData';
import {
  GetParams,
  GlobalNavigate,
  getIsAllowedPermission,
} from '../../../../Helper';
import moment from 'moment';
import { GetUsers } from '../../../../Services/UsersServices';
import { useNavigate } from 'react-router-dom';

export const UsersManagementView = () => {
  const parentTranslationPath = 'UsersView';
  const translationPath = 'UsersManagement';
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });
  useTitle(t(`users-management`));
  const typingTimer = useRef(null);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const redirectStatus = +GetParams('redirectStatus');
  const navigate = useNavigate();
  const userNotificationData = localStorage.getItem('userNotificationData')
    ? JSON.parse(localStorage.getItem('userNotificationData'))
    : null;
  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };
  const [isLoading, setIsLoading] = useReducer(reducer, {
    users: false,
  });
  const initActiveTabIndex = redirectStatus ? getRedirectTabIndex() : 0;
  const [activeTabIndex, setActiveTabIndex] = useState(initActiveTabIndex);
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [isDeactivateUserOpen, setIsDeactivateUserOpen] = useState(false);
  const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);
  const [statusUserId, setStausId] = useState(1);
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
    selectedDateType: 1,
  };
  const initialFilter = {
    pageIndex: 0,
    pageSize: 25,
    status: getClickedTabStatus(0),
    userName: null,
    userUserName: null,
    staffId: null,
    email: null,
    startDate: null,
    endDate: null,
    mobileNumber: null,
    createdBy: null,
    creationDate: null,
    branchId: null,
    lineManagerId: null,
    platformName: null,
    userId: null,
    branchName: null,
  };
  const [filter, setFilter] = useState(initialFilter);
  const [selected, setSelected] = useReducer(reducer, {
    lineManager: null,
    branch: null,
    userName: null,
    dateFilter: dateRangeDefault,
  });
  const [data, setData] = useReducer(reducer, {
    users: { result: [], totalCount: 0 },
  });
  const getUsers = async () => {
    setIsLoading({ id: 'users', value: true });

    const body = {
      ...(filter || {}),
      pageIndex: filter.pageIndex + 1,
    };
    const res = await GetUsers(body);
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'users', value: res || { result: [], totalCount: 0 } });
    } else {
      setData({ id: 'users', value: { result: [], totalCount: 0 } });
    }

    setIsLoading({ id: 'users', value: false });
  };

  function getClickedTabStatus(tabIndex) {
    let statusValue = null;

    const tabsDataWithPermissions = getTabsDataWithPermissions();

    const clickedTabItem = tabsDataWithPermissions[tabIndex];
    const clickedTabLabel = clickedTabItem ? clickedTabItem.label : null;
    if (clickedTabLabel)
      statusValue = UpdatedUserStatus[clickedTabLabel]
        ? UpdatedUserStatus[clickedTabLabel].value
        : null;

    return statusValue;
  }

  function getTabsDataWithPermissions() {
    const tabsDataWithPermissions = [];

    const allTabsData = Object.values(UsersManagementTabsData);

    allTabsData.forEach((item) => {
      const isTabWithPermission = getIsAllowedPermission(
        item.permissionsList,
        loginResponse,
        item.permissionsId
      );
      const tabItem = {
        ...item,
        disabled: isLoading.users,
      };
      if (isTabWithPermission) tabsDataWithPermissions.push(tabItem);
    });
    return tabsDataWithPermissions;
  }

  const reloadData = useCallback(() => {
    getUsers();
  }, [filter]);

  const onTabChanged = (_, tabIndex) => {
    setActiveTabIndex(tabIndex);
    setData({ id: 'users', value: [] });
    onPageIndexChanged(0);

    const isAllUsersTab = getClickedTabStatus(tabIndex) === null;

    if (isAllUsersTab) GlobalNavigate('/home/Users');
    else if (!redirectStatus)
      setFilter((items) => ({
        ...items,
        status: getClickedTabStatus(tabIndex),
        userName: null,
        userId: null,
        branchId: null,
        lineManagerId: null,
      }));
    else if (redirectStatus) {
      setFilter((items) => ({
        ...items,
        status: +userNotificationData?.StatusId || null,
        userName: userNotificationData?.UserName || null,
        userId: userNotificationData?.UserId || null,
        branchId: null,
        lineManagerId: null,
      }));
      clearRedirectParam();
    }
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  function getRedirectTabIndex() {
    const tabsDataWithPermissions = getTabsDataWithPermissions();
    const isTabAllowed = tabsDataWithPermissions.find(
      (item) => item.statusId === redirectStatus
    );
    if (isTabAllowed) {
      const redirectTabIndex = tabsDataWithPermissions.findIndex(
        (item) => item.statusId === redirectStatus
      );
      return redirectTabIndex;
    }
  }

  const notificationRedirectHandler = () => {
    const redirectTabIndex = getRedirectTabIndex();
    onTabChanged(null, redirectTabIndex);
  };

  function clearRedirectParam() {
    navigate.replace('/home/users-management');
    localStorage.removeItem('userNotificationData');
  }

  useEffect(() => {
    if (redirectStatus) notificationRedirectHandler();
  }, [redirectStatus]);

  useEffect(() => {
    if (typingTimer.current) clearTimeout(typingTimer.current);
    typingTimer.current = setTimeout(() => {
      getUsers();
    }, 600);
  }, [filter]);

  useEffect(() => {
    if (
      selected.dateFilter &&
      selected.dateFilter.startDate &&
      selected.dateFilter.endDate
    ) {
      const fromDate = moment(selected.dateFilter.startDate)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');
      const toDate = moment(selected.dateFilter.endDate)
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');
      setFilter((f) => ({
        ...f,
        startDate: fromDate,
        endDate: toDate,
      }));
    }
  }, [isDatePickerChanged, selected.dateFilter]);
  return (
    <div className='propx-view propx-bg user-groups-view'>
      <div className='d-flex-v-center-h-between'>
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={'users-management'}
          subTitle={'users-management-heading'}
          wrapperClasses={'px-4 pt-4 w-50'}
          headerTitleClasses={'fz-30  mb-3'}
          subTitleClasses={'fz-12 m-0'}
        />
        <div className='w-25 px-4 d-flex-v-center-h-between'>
          <div className='w-100 d-flex-column mr-3'></div>
          <PermissionsComponent
            permissionsList={Object.values(UsersManagementPermissions)}
            permissionsId={
              UsersManagementPermissions.DeactiveUser.permissionsId
            }
          >
            <Button
              className='btns theme-propx outlined-colored px-4'
              id='btn-save'
              onClick={() => setIsDeactivateUserOpen(true)}
            >
              <span className='mdi mdi-account-remove mr-2' />
              <span>{t(`Deactivate-user`)}</span>
            </Button>
          </PermissionsComponent>
          <PermissionsComponent
            permissionsList={Object.values(UsersManagementPermissions)}
            permissionsId={[
              UsersManagementPermissions.AddUserButton.permissionsId,
            ]}
          >
            <Button
              className='btns theme-propx solid px-4'
              id='btn-save'
              onClick={() => setIsAddUserDialogOpen(true)}
            >
              <span className='mdi mdi-plus' />
              <span>{t(`add-user`)}</span>
            </Button>
          </PermissionsComponent>
        </div>
      </div>
      <div className='d-flex'>
        <TabsComponent
          wrapperClasses='mx-4 w-fit miw-fit'
          data={getTabsDataWithPermissions()}
          orientation='vertical'
          labelInput='label'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          themeClasses='theme-propx'
          currentTab={activeTabIndex}
          onTabChanged={onTabChanged}
          withDynamicComponents
          dynamicComponentProps={{
            parentTranslationPath,
            translationPath,
            usersData: data.users?.result,
            totalCount: data.users?.totalCount,
            filter,
            setFilter,
            reloadData,
            userNotificationData,
            onPageIndexChanged,
            onPageSizeChanged,
            setSelected,
            selected,
            isDatePickerChanged,
            setIsDatePickerChanged,
            dateRangeDefault,
            initialFilter,
          }}
        />
      </div>

      {isAddUserDialogOpen && (
        <AddUserDialog
          isDialogOpen={isAddUserDialogOpen}
          onSave={() => {
            setIsAddUserDialogOpen(false);
            reloadData();
          }}
          onClose={() => {
            setIsAddUserDialogOpen(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {isDeactivateUserOpen && (
        <DeactivateUserDialog
          isDialogOpen={isDeactivateUserOpen}
          onSave={() => {
            setIsDeactivateUserOpen(false);
            reloadData();
          }}
          onClose={() => {
            setIsDeactivateUserOpen(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};
