import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { XeleAutocomplete } from '../../../../../../Components';
import { AssetsActivitiesTypeTypeEnum } from '../../../../../../Enums/AssetsActivitiesTypeTypeEnum.Enum';
import { lookupItemsGetId } from '../../../../../../Services/LookupsServices';

export const ActivityCategoryComponent = ({
  parentTranslationPath,
  translationPath,
  setState,
  state,
  helperText,
  isSubmitted,
}) => {
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });
  const [ActivityCategories, setActivityCategories] = useState([]);
  const [loadings, setLoadings] = useState(false);
  const [selected, setSelected] = useState(null);

  const lookupActivityCategory = useCallback(async () => {
    setLoadings(true);
    const result = await lookupItemsGetId({
      lookupTypeId: AssetsActivitiesTypeTypeEnum.lookupActivityCategory,
    });
    setLoadings(false);
    setActivityCategories(result);
  }, []);
  useEffect(() => {
    lookupActivityCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state && state.categoryId) {
      setSelected({
        lookupItemId: state.categoryId,
        lookupItemName: state.categoryName,
      });
    }
  }, [state]);

  return (
    <div>
      <XeleAutocomplete
        idRef='MaintenanceCompanyRef'
        labelValue='Activity-category'
        labelClasses='Requierd-Color'
        selectedValues={selected}
        multiple={false}
        data={ActivityCategories || []}
        displayLabel={(option) => t(`${option.lookupItemName || ''}`)}
        getOptionSelected={(option) =>
          option.lookupItemId === selected.lookupItemId
        }
        withoutSearchButton
        inputPlaceholder={t(`Activity-category`)}
        isLoading={loadings}
        helperText={helperText}
        error={!!helperText}
        isSubmitted={isSubmitted}
        isWithError
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(event, newValue) => {
          setState({
            categoryId: (newValue && +newValue.lookupItemId) || '',
            categoryName: (newValue && newValue.lookupItemName) || '',
          });
          setSelected({
            lookupItemId: (newValue && +newValue.lookupItemId) || '',
            lookupItemName: (newValue && newValue.lookupItemName) || '',
          });
        }}
      />
    </div>
  );
};
ActivityCategoryComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  setState: PropTypes.number.isRequired,
};
