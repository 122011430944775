import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataFileAutocomplete } from '../../../../../Components';
import { TaskConfigurationRelatedTo } from '../../../../../Enums';

export const RelatedToComponent = ({
  parentTranslationPath,
  translationPath,
  configData,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared'], {
    keyPrefix: translationPath,
  });

  const handleRelatedToChange = () => {};

  return (
    <div className='dialog-content-item related-to-wrapper'>
      <div>
        {configData?.taskConfigurationRelatedTo &&
          configData.taskConfigurationRelatedTo.map((item, index) => (
            <div className='mr-2 my-3'>
              <DataFileAutocomplete
                labelValue={
                  TaskConfigurationRelatedTo[item.relatedTo]?.labelValue
                }
                wrapperClasses='mr-2 my-3'
                idRef={`relatedToRef-${index}`}
                inputPlaceholder={t(`select`)}
                selectedValues={[]}
                multiple={false}
                data={[]}
                filterOptions={(options) => options}
                displayLabel={() => {}}
                renderFor={item.relatedTo && item.relatedTo.toLowerCase()}
                withoutSearchButton
                isLoading={[]}
                onKeyDown={() => {
                  const newValue = null;
                  handleRelatedToChange(item, newValue);
                }}
                isWithError
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  handleRelatedToChange(item, newValue);
                }}
              />
            </div>
          ))}
      </div>
    </div>
  );
};
