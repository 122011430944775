import { config } from '../../config/config';
import { HttpServices } from '../../Helper';

export const GetUsersGroups = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/Identity/UserGroup/GetUsersGroups`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const CreateUsersGroup = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/Identity/UserGroup/CreateUsersGroup`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateUsersGroup = async (body, id) => {
  const result = await HttpServices.put(
    `${config.server_address}/Identity/UserGroup/UpdateUsersGroup/${id}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetUserGroupById = async (id) => {
  const result = await HttpServices.get(
    `${config.server_address}/Identity/UserGroup/GetUserGroupById/${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const DeleteUserGroup = async (id) => {
  const result = await HttpServices.delete(
    `${config.server_address}/Identity/UserGroup/DeleteUserGroup/${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
