import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { XeleAutocomplete } from '../../../../../../Components';
import {
  RecipientTypeCoreEnum,
  TemplateCategoryEnum,
} from '../../../../../../Enums';
import { GetAllSystemTemplateByCategoryId } from '../../../../../../Services/SystemTemplateServices';
export const AssignNotificationTemplateComponent = ({
  parentTranslationPath,
  translationPath,
  stateNotification,
  AssigntoNOT,
  Data,
  setTypePrerequisites,
  TypePrerequisites,
  setAssigntoData,
}) => {
  const { t } = useTranslation(parentTranslationPath,  {
    keyPrefix: translationPath,
  });
  const [selected, setSelected] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [loadings, setLoadings] = useState(false);
  const [filter] = useState({
    pageIndex: 0,
    pageSize: 9999,
  });

  const getAllSystemTemplateByCategoryId = useCallback(async () => {
    setLoadings(true);
    const res = await GetAllSystemTemplateByCategoryId(
      TemplateCategoryEnum.Activity.key
    );
    if (!(res && res.status && res.status !== 200)) {
      const mappedTemplatesData =
        res &&
        res.map((item) => ({
          templateName: item.systemTemplateName,
          templateId: item.systemTemplateId,
        }));
      setTemplates(mappedTemplatesData || []);
    } else setTemplates([]);
    setLoadings(false);
  }, [filter]);

  useEffect(() => {
    getAllSystemTemplateByCategoryId();
  }, []);

  useEffect(() => {
    setTypePrerequisites((item) => ({
      ...item,
      AssignToTemplat: {
        templateId: selected.templateId,
        templateName: selected.templateName,
        recipientTypeId: selected.recipientTypeId,
        activityTypeTemplateRecipientTypeSources: stateNotification.AssignTo,
      },
    }));
  }, [selected.templateId, stateNotification.AssignTo]);

  useEffect(() => {
    if (Data) {
      const filterData =
        Data &&
        Data &&
        Data.activityTypeTemplateRecipientTypes &&
        Data.activityTypeTemplateRecipientTypes.filter(
          (obj) => obj.recipientTypeId === RecipientTypeCoreEnum.AssignTo.key
        );
      setTypePrerequisites((item) => ({
        ...item,
        AssignToTemplat: {
          templateId: filterData && filterData[0] && filterData[0].templateId,
          recipientTypeId: RecipientTypeCoreEnum.AssignTo.key,
          activityTypeTemplateRecipientTypeSources:
            stateNotification.LineManager,
        },
      }));
      setSelected({
        templateId:
          (filterData && filterData[0] && filterData[0].templateId) || '',
        templateName:
          (filterData && filterData[0] && filterData[0].templateName) || '',
        recipientTypeId: RecipientTypeCoreEnum.AssignTo.key,
      });
    }
  }, [Data]);

  useEffect(() => {
    if (TypePrerequisites && TypePrerequisites.AssignToTemplat) {
      setSelected({
        templateId: TypePrerequisites.AssignToTemplat.templateId || '',
        templateName: TypePrerequisites.AssignToTemplat.templateName || '',
        recipientTypeId: TypePrerequisites.AssignToTemplat.recipientTypeId,
      });
    }
  }, []);

  return (
    <div>
      <XeleAutocomplete
        idRef='Assign-Notification-templateRef'
        selectedValues={selected || []}
        multiple={false}
        data={(templates && templates) || []}
        displayLabel={(option) => t(`${option.templateName || ''}`)}
        getOptionSelected={(option) =>
          option.templateId === selected.templateId
        }
        withoutSearchButton
        inputPlaceholder={t(`notification-template`)}
        isLoading={loadings}
        isWithError
        isDisabled={AssigntoNOT.length === 0 && AssigntoNOT.length === 0}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(event, newValue) => {
          setTypePrerequisites((item) => ({
            ...item,
            AssignToTemplat: {
              templateId: newValue && +newValue.templateId,
              templateName: newValue && newValue.templateName,
              recipientTypeId: RecipientTypeCoreEnum.AssignTo.key,
              activityTypeTemplateRecipientTypeSources:
                stateNotification.AssignTo,
            },
          }));
          setSelected({
            templateId: (newValue && +newValue.templateId) || '',
            templateName: (newValue && newValue.templateName) || '',
            recipientTypeId: RecipientTypeCoreEnum.AssignTo.key,
          });
          setAssigntoData((newValue === null && []) || [newValue.templateId]);
        }}
      />
    </div>
  );
};
AssignNotificationTemplateComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  stateNotification: PropTypes.string.isRequired,
};
