import React from 'react';
import {
  XeleAutocomplete,
  XeleInput,
  XeleSwitch,
} from '../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { getErrorByName } from '../../../../../../../Helper';

export const DialogMiddleFields = ({
  translationPath,
  parentTranslationPath,
  setSelected,
  selected,
  data,
  schema,
  isSubmitted,
  functions,
}) => {
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });

  return (
    <>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>
            {t(`nationality*`)}
          </span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <XeleAutocomplete
            idRef='nationalityRef'
            selectedValues={selected.nationality}
            inputPlaceholder={t(`select`)}
            wrapperClasses='w-100 mb-2'
            data={data.countries || []}
            displayLabel={(option) => option.lookupItemName || ''}
            multiple={false}
            withoutSearchButton
            isWithError
            isSubmitted={isSubmitted}
            helperText={getErrorByName(schema, 'nationality').message}
            error={getErrorByName(schema, 'nationality').error}
            onOpen={() => {
              if (data.countries && data.countries.length === 0)
                functions.getCountires();
            }}
            onChange={(event, newValue) => {
              setSelected({ id: 'nationality', value: newValue });
            }}
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>
            {t(`languages`)}
          </span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <XeleAutocomplete
            idRef='languagesRef'
            inputPlaceholder={t(`select`)}
            wrapperClasses='w-100 mb-2'
            selectedValues={selected.languages || []}
            data={data.languages || []}
            chipsLabel={(option) => option.lookupItemName || ''}
            displayLabel={(option) => option.lookupItemName || ''}
            multiple
            withoutSearchButton
            onOpen={() => {
              if (data.languages && data.languages.length === 0)
                functions.getLanguages();
            }}
            onChange={(event, newValue) => {
              setSelected({
                id: 'languages',
                value: newValue,
              });
            }}
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>
            {t(`hasDrivingLicense`)}
          </span>
        </div>
        <div className='w-75 mt-0 mb-2 pt-1'>
          <XeleSwitch
            idRef='CRMRef'
            themeClass='thick-theme'
            isChecked={selected.drivingLicenseAvailability || false}
            labelValue={`${selected.drivingLicenseAvailability ? 'Yes' : 'No'}`}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChangeHandler={(_, isChecked) => {
              setSelected({
                id: 'drivingLicenseAvailability',
                value: isChecked,
              });
            }}
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>{t(`hasCar`)}</span>
        </div>
        <div className='w-75 mt-0 mb-2 pt-1'>
          <XeleSwitch
            idRef='CRMRef'
            themeClass='thick-theme'
            isChecked={selected.carAvailability || false}
            labelValue={`${selected.carAvailability ? 'Yes' : 'No'}`}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChangeHandler={(_, isChecked) => {
              setSelected({ id: 'carAvailability', value: isChecked });
            }}
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>
            {t(`department`)}
          </span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <XeleAutocomplete
            idRef='departmentRef'
            inputPlaceholder={t(`select`)}
            wrapperClasses='w-100 mb-2'
            selectedValues={selected.department}
            data={data.departments || []}
            displayLabel={(option) => option.lookupItemName || ''}
            multiple={false}
            withoutSearchButton
            onOpen={() => {
              if (data.departments && data.departments.length === 0)
                functions.getDepartments();
            }}
            onChange={(event, newValue) => {
              setSelected({ id: 'department', value: newValue });
            }}
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>
            {t(`numberOfTrainingsAttended`)}
          </span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <XeleInput
            idRef='numberOfTrainingsAttendRef'
            inputPlaceholder='select'
            value={selected.numberOfTrainingsAttend}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            isWithError
            wrapperClasses={'mb-2'}
            isSubmitted={isSubmitted}
            type='number'
            withLoader
            max={900000000}
            onInputChanged={(event) =>
              setSelected({
                id: 'numberOfTrainingsAttend',
                value: +event.target.value,
              })
            }
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>{t(`station`)}</span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <XeleAutocomplete
            idRef='stationRef'
            inputPlaceholder={t(`select`)}
            wrapperClasses='w-100 mb-2'
            selectedValues={selected.station}
            data={data.stations || []}
            displayLabel={(option) => option.lookupItemName || ''}
            multiple={false}
            withoutSearchButton
            onOpen={() => {
              if (data.stations && data.stations.length === 0)
                functions.getStations();
            }}
            onChange={(event, newValue) => {
              setSelected({ id: 'station', value: newValue });
            }}
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>
            {t(`designation`)}
          </span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <XeleAutocomplete
            idRef='designationRef'
            inputPlaceholder={t(`select`)}
            wrapperClasses='w-100 mb-2'
            selectedValues={selected.jobTitle}
            data={data.jobTitles || []}
            displayLabel={(option) => option.lookupItemName || ''}
            multiple={false}
            withoutSearchButton
            onOpen={() => {
              if (data.jobTitles && data.jobTitles.length === 0)
                functions.getJobTitles();
            }}
            onChange={(event, newValue) => {
              setSelected({ id: 'jobTitle', value: newValue });
            }}
          />
        </div>
      </div>
    </>
  );
};
