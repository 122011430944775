import { FormControl, FormControlLabel, FormGroup } from '@material-ui/core';
import React from 'react';
import { XeleSwitch } from '../../../../../../Components';

export const ActivityOptions = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
}) => {
  const setStatewithDateTime = (item) =>
    setState({
      id: 'withDateTime',
      value: item,
    });
  const setStatewithReminder = (item) => {
    if (item === false) {
      setState({
        id: 'edit',
        value: {
          ...state,
          withReminder: item,
          isWithslaExpirationPeriod: false,
          slaExpirationPeriod: 0,
          slaExpirationPeriodFreqency: null,
          slaExpirationPeriodComparisonDate: null,
        },
      });
    } else setState({ id: 'withReminder', value: item });
  };
  const setStateWithDuration = (item) =>
    setState({
      id: 'withDuration',
      value: item,
    });
  const setStatefollowUpRequired = (item) =>
    setState({
      id: 'followUpRequired',
      value: item,
    });
  const setStateIsForMobile = (item) =>
    setState({
      id: 'isForMobile',
      value: item,
    });
  const setIsWithslaExpirationPeriod = (item) => {
    if (item === false) {
      setState({
        id: 'edit',
        value: {
          ...state,
          isWithslaExpirationPeriod: item,
          slaExpirationPeriod: 0,
          slaExpirationPeriodFreqency: null,
          slaExpirationPeriodComparisonDate: null,
          slaAppliedFor: null,
        },
      });
    } else setState({ id: 'isWithslaExpirationPeriod', value: item });
  };
  return (
    <FormControl component='fieldset' className='my-2'>
      <label className='label-wrapper'>Options</label>
      <FormGroup>
        <FormControlLabel
          control={
            <XeleSwitch
              isChecked={state.withDateTime}
              onChangeHandler={
                !state.withDateTime
                  ? () => [setStatewithDateTime(!state.withDateTime)]
                  : () => [
                      setStatewithReminder(state.withReminder),
                      setStatewithDateTime(!state.withDateTime),
                    ]
              }
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              labelValue='With-dateTime'
            />
          }
        />
        <FormControlLabel
          control={
            <XeleSwitch
              isChecked={state.withDuration}
              onChangeHandler={
                !state.withDuration && !state.withDateTime
                  ? () => [
                      setStateWithDuration(!state.withDuration),
                      setStatewithDateTime(!state.withDateTime),
                    ]
                  : () => [
                      setStateWithDuration(!state.withDuration),
                      setStatewithDateTime(state.withDateTime),
                    ]
              }
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              labelValue='With-Duration'
            />
          }
        />
        <FormControlLabel
          control={
            <XeleSwitch
              isChecked={state.followUpRequired}
              onChangeHandler={() => {
                setStatefollowUpRequired(!state.followUpRequired);
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              labelValue='Followuprequired'
            />
          }
        />
        <FormControlLabel
          control={
            <XeleSwitch
              isChecked={state.isForMobile}
              onChangeHandler={() => {
                setStateIsForMobile(!state.isForMobile);
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              labelValue='IsForMobile'
            />
          }
        />
        <FormControlLabel
          control={
            <XeleSwitch
              isChecked={
                state.isWithslaExpirationPeriod || state.slaExpirationPeriod > 0
              }
              onChangeHandler={() => {
                if (state.withReminder && state.withDateTime)
                  setIsWithslaExpirationPeriod(
                    !state.isWithslaExpirationPeriod
                  );
                else if (state.isWithslaExpirationPeriod === true) {
                  setIsWithslaExpirationPeriod(false);
                }
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              labelValue='SLA-expiration-period'
            />
          }
        />
      </FormGroup>
    </FormControl>
  );
};
