import React from 'react';
import { Menu, MenuItem, withStyles } from '@material-ui/core';
import { GlobalNavigate } from '../../../../Helper';
import { useTranslation } from 'react-i18next';
import cryptocurrency from '../../../../assets/images/Propxicon/cryptocurrency-01.svg';
import crm from '../../../../assets/images/Propxicon/crm.svg';
import list from '../../../../assets/images/Propxicon/list.svg';
import { PermissionsComponent } from '../../../../Components';
import { havePermissionToViewDetails } from '../../../../Helper';
import {
  GroupsPermissions,
  UsersManagementPermissions,
} from '../../../../Permissions';

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingBlock: '8px',
    '&:focus': {
      backgroundColor: theme.palette.primary.secondary,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export const SettingsMenu = ({ anchorEl, onClose, parentTranslationPath }) => {
  const { t } = useTranslation(parentTranslationPath);
  return (
    <Menu
      id='customized-menu'
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      elevation={0}
      getContentAnchorEl={null}
    >
      <PermissionsComponent
        permissionsList={Object.values(GroupsPermissions)}
        permissionsId={[GroupsPermissions.ViewGroupsPage.permissionsId]}
      >
        <StyledMenuItem onClick={() => GlobalNavigate('/home/user-groups')}>
          <img src={cryptocurrency} className='mr-2' alt='group' />
          {t('groups')}
        </StyledMenuItem>
      </PermissionsComponent>

      {havePermissionToViewDetails(
        Object.values(UsersManagementPermissions)
      ) ? (
        <StyledMenuItem
          onClick={() => GlobalNavigate('/home/users-management')}
        >
          <img src={crm} className='mr-2' alt='users' />
          {t('users-management')}
        </StyledMenuItem>
      ) : null}
      <StyledMenuItem onClick={() => GlobalNavigate('/home/user-approvals')}>
        <img src={list} className='mr-2' alt='Approvals' />
        {t('Approvals')}
      </StyledMenuItem>
    </Menu>
  );
};
