/* eslint-disable max-len */
import i18next from 'i18next';
import moment from 'moment-timezone/moment-timezone-utils';
import { GlobalRerender } from './Middleware.Helper';
import { config } from '../config';
import { store } from '../store/RootStore';


import SharedEn from '../Layouts/Common/I18n/en.json';
import SharedAr from '../Layouts/Common/I18n/ar.json';
import SharedRu from '../Layouts/Common/I18n/ru.json';
import SharedCn from '../Layouts/Common/I18n/cn.json';

import SharedV2En from '../Layouts/Common/I18nV2/en.json';
import SharedV2Ar from '../Layouts/Common/I18nV2/ar.json';
import SharedV2Ru from '../Layouts/Common/I18nV2/ru.json';
import SharedV2Cn from '../Layouts/Common/I18nV2/cn.json';

import UsersViewEn from '../Views/Home/Administration/UsersView/I18n/en.json';
import UsersViewCn from '../Views/Home/Administration/UsersView/I18n/cn.json';
import UsersViewRu from '../Views/Home/Administration/UsersView/I18n/ru.json';
import UsersViewAr from '../Views/Home/Administration/UsersView/I18n/ar.json';

import ActivitiesViewCn from '../Views/Home/ActivitiesView/I18n/cn.json';
import ActivitiesViewRu from '../Views/Home/ActivitiesView/I18n/ru.json';
import ActivitiesViewEn from '../Views/Home/ActivitiesView/I18n/en.json';
import ActivitiesViewAr from '../Views/Home/ActivitiesView/I18n/ar.json';



// Start My Referrals View
import MyReferralsRu from '../Views/Home/MyReferralsView/I18n/ru.json';
import MyReferralsCn from '../Views/Home/MyReferralsView/I18n/cn.json';
import MyReferralsEn from '../Views/Home/MyReferralsView/I18n/en.json';
import MyReferralsAr from '../Views/Home/MyReferralsView/I18n/ar.json';
// End My Referrals View

// Start ActivitiesType Views
import ActivitiesTypeCn from '../Views/Home/ActivitiesTypeView/ActivitiesTypeManagementView/I18n/cn.json';
import ActivitiesTypeRu from '../Views/Home/ActivitiesTypeView/ActivitiesTypeManagementView/I18n/ru.json';
import ActivitiesTypeEn from '../Views/Home/ActivitiesTypeView/ActivitiesTypeManagementView/I18n/en.json';
import ActivitiesTypeAr from '../Views/Home/ActivitiesTypeView/ActivitiesTypeManagementView/I18n/ar.json';
// End  ActivitiesType Views

// Start Templates View
import TemplatesViewRu from '../Views/Home/TemplatesView/I18n/ru.json';
import TemplatesViewCn from '../Views/Home/TemplatesView/I18n/cn.json';
import TemplatesViewEn from '../Views/Home/TemplatesView/I18n/en.json';
import TemplatesViewAr from '../Views/Home/TemplatesView/I18n/ar.json';
// End Templates View


import ImportDetailsViewCn from '../Views/Home/Common/ImportDetailsView/I18n/cn.json';
import ImportDetailsViewRu from '../Views/Home/Common/ImportDetailsView/I18n/ru.json';
import ImportDetailsViewEn from '../Views/Home/Common/ImportDetailsView/I18n/en.json';
import ImportDetailsViewAr from '../Views/Home/Common/ImportDetailsView/I18n/ar.json';



import PoliciesCn from '../Views/Home/PoliciesView/I18n/cn.json';
import PoliciesRu from '../Views/Home/PoliciesView/I18n/ru.json';
import PoliciesEn from '../Views/Home/PoliciesView/I18n/en.json';
import PoliciesAr from '../Views/Home/PoliciesView/I18n/ar.json';


// Start Reports Builder View
import ReportsBuildergRu from '../Views/Home/ReportsBuilderView/I18n/ru.json';
import ReportsBuildergCn from '../Views/Home/ReportsBuilderView/I18n/cn.json';
import ReportsBuildergEn from '../Views/Home/ReportsBuilderView/I18n/en.json';
import ReportsBuildergAr from '../Views/Home/ReportsBuilderView/I18n/ar.json';

import RolesManagementViewAr from '../Views/Home/Administration/RolesView/RolesManagement/I18n/ar.json';
import RolesManagementViewEn from '../Views/Home/Administration/RolesView/RolesManagement/I18n/en.json';
import RolesManagementViewCn from '../Views/Home/Administration/RolesView/RolesManagement/I18n/cn.json';
import RolesManagementViewRu from '../Views/Home/Administration/RolesView/RolesManagement/I18n/ru.json';


import RolesViewCn from '../Views/Home/Administration/RolesView/I18n/cn.json';
import RolesViewRu from '../Views/Home/Administration/RolesView/I18n/ru.json';
import RolesViewEn from '../Views/Home/Administration/RolesView/I18n/en.json';
import RolesViewAr from '../Views/Home/Administration/RolesView/I18n/ar.json';




import UserDataViewRu from '../Views/Home/Administration/UserDataView/I18n/ru.json';
import UserDataViewCn from '../Views/Home/Administration/UserDataView/I18n/cn.json';
import UserDataViewEn from '../Views/Home/Administration/UserDataView/I18n/en.json';
import UserDataViewAr from '../Views/Home/Administration/UserDataView/I18n/ar.json';

import DashboardRu from '../Views/Home/Dashboard/I18n/ru.json';
import DashboardCn from '../Views/Home/Dashboard/I18n/cn.json';
import DashboardEn from '../Views/Home/Dashboard/I18n/en.json';
import DashboardAr from '../Views/Home/Dashboard/I18n/ar.json';


// Start Account Views
import LoginViewRu from '../Views/Account/LoginView/I18n/ru.json';
import LoginViewCn from '../Views/Account/LoginView/I18n/cn.json';
import LoginViewEn from '../Views/Account/LoginView/I18n/en.json';
import LoginViewAr from '../Views/Account/LoginView/I18n/ar.json';
// End Account Views


import FormBuilderRu from '../Views/Home/FormBuilder/I18n/ru.json';
import FormBuilderCn from '../Views/Home/FormBuilder/I18n/cn.json';
import FormBuilderEn from '../Views/Home/FormBuilder/I18n/en.json';
import FormBuilderAr from '../Views/Home/FormBuilder/I18n/ar.json';

// Start system configration
import SystemConfigrationCn from '../Views/Home/Administration/SystemConfigrationView/I18n/cn.json';
import SystemConfigrationRu from '../Views/Home/Administration/SystemConfigrationView/I18n/ru.json';
import SystemConfigrationEn from '../Views/Home/Administration/SystemConfigrationView/I18n/en.json';
import SystemConfigrationAr from '../Views/Home/Administration/SystemConfigrationView/I18n/ar.json';
// End   system configration

// Start Form Builder Views
import FormBuilderViewCn from '../Views/FormBuilder/I18n/cn.json';
import FormBuilderViewRu from '../Views/FormBuilder/I18n/ru.json';
import FormBuilderViewEn from '../Views/FormBuilder/I18n/en.json';
import FormBuilderViewAr from '../Views/FormBuilder/I18n/ar.json';
// End Form Builder Views

import TeamViewCn from '../Views/Home/Administration/TeamView/I18n/cn.json';
import TeamViewRu from '../Views/Home/Administration/TeamView/I18n/ru.json';
import TeamViewEn from '../Views/Home/Administration/TeamView/I18n/en.json';
import TeamViewAr from '../Views/Home/Administration/TeamView/I18n/ar.json';

import LookupsViewEn from '../Views/Home/LookupsView/I18n/en.json';
import LookupsViewAr from '../Views/Home/LookupsView/I18n/ar.json';
import LookupsViewRu from '../Views/Home/LookupsView/I18n/ru.json';
import LookupsViewCn from '../Views/Home/LookupsView/I18n/cn.json';
// Start Bulk Assign View
import MonthlyCalendarViewRu from '../Views/Home/MonthlyCalendarView/I18n/ru.json';
import MonthlyCalendarViewCn from '../Views/Home/MonthlyCalendarView/I18n/cn.json';
import MonthlyCalendarViewEn from '../Views/Home/MonthlyCalendarView/I18n/en.json';
import MonthlyCalendarViewAr from '../Views/Home/MonthlyCalendarView/I18n/ar.json';
// End Bulk Assign View

import BranchViewEn from '../Views/Home/Administration/BranchesView/I18n/en.json';
import BranchViewRu from '../Views/Home/Administration/BranchesView/I18n/ru.json';
import BranchViewCn from '../Views/Home/Administration/BranchesView/I18n/cn.json';
import BranchViewAr from '../Views/Home/Administration/BranchesView/I18n/ar.json';

import BusinessGroupsViewCn from '../Views/Home/Administration/BusinessGroupsView/I18n/cn.json';
import BusinessGroupsViewRu from '../Views/Home/Administration/BusinessGroupsView/I18n/ru.json';
import BusinessGroupsViewEn from '../Views/Home/Administration/BusinessGroupsView/I18n/en.json';
import BusinessGroupsViewAr from '../Views/Home/Administration/BusinessGroupsView/I18n/ar.json';

import HeaderViewCn from '../Views/Home/Common/HeaderView/I18n/cn.json';
import HeaderViewRu from '../Views/Home/Common/HeaderView/I18n/ru.json';
import HeaderViewEn from '../Views/Home/Common/HeaderView/I18n/en.json';
import HeaderViewAr from '../Views/Home/Common/HeaderView/I18n/ar.json';









// Start Task Configuration View
import TaskConfigurationRu from '../Views/Home/TaskConfiguration/I18n/ru.json';
import TaskConfigurationCn from '../Views/Home/TaskConfiguration/I18n/cn.json';
import TaskConfigurationEn from '../Views/Home/TaskConfiguration/I18n/en.json';
import TaskConfigurationAr from '../Views/Home/TaskConfiguration/I18n/ar.json';

// End Task Configuration View

// Start User Management View
import UsersManagementAr from '../Views/Settings/UserManagement/i18n/ar.json';
import UsersManagementEn from '../Views/Settings/UserManagement/i18n/en.json';
import UsersManagementRu from '../Views/Settings/UserManagement/i18n/ru.json';
import UsersManagementCn from '../Views/Settings/UserManagement/i18n/cn.json';
// End User Management View


import AutoCorrespondenceRu from '../Views/Home/AutoCorrespondenceView/I18n/ru.json';
import AutoCorrespondenceCn from '../Views/Home/AutoCorrespondenceView/I18n/cn.json';
import AutoCorrespondenceEn from '../Views/Home/AutoCorrespondenceView/I18n/en.json';
import AutoCorrespondenceAr from '../Views/Home/AutoCorrespondenceView/I18n/ar.json';

import ActivityBuilderRu from '../Views/Home/ActivityBuilderView/I18n/ru.json';
import ActivityBuilderCn from '../Views/Home/ActivityBuilderView/I18n/cn.json';
import ActivityBuilderEn from '../Views/Home/ActivityBuilderView/I18n/en.json';
import ActivityBuilderAr from '../Views/Home/ActivityBuilderView/I18n/ar.json';


import ApprovalsConfigurationAr from '../Views/Home/ApprovalsConfigurationView/I18n/ar.json';
import ApprovalsConfigurationEn from '../Views/Home/ApprovalsConfigurationView/I18n/en.json';
import ApprovalsConfigurationCn from '../Views/Home/ApprovalsConfigurationView/I18n/cn.json';
import ApprovalsConfigurationRu from '../Views/Home/ApprovalsConfigurationView/I18n/ru.json';




import GlobalSuccessDialog from '../Views/Home/Common/GlobalSuccessDialog/GlobalSuccessDialog.i18n.json';

export function localizationInit() {
  i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    fallbackLng: ['en', 'ar', 'ru', 'cn'],
    lng: 'en', // language to use
    resources: {
      en: {
        Shared: SharedEn,
        SharedV2: SharedV2En,
        BusinessGroupsView: BusinessGroupsViewEn,
        TeamView: TeamViewEn,
        UserDataView: UserDataViewEn,
        UsersView: UsersViewEn,
        BranchView: BranchViewEn,
        RolesView: RolesViewEn,
        HeaderView: HeaderViewEn,
        ImportDetailsView: ImportDetailsViewEn,
        FormBuilder: FormBuilderEn,
        LookupsView: LookupsViewEn,
        LoginView: LoginViewEn,
        FormBuilderView: FormBuilderViewEn,
        ActivitiesView: ActivitiesViewEn,
        MonthlyCalendarView: MonthlyCalendarViewEn,
        MyReferralsView: MyReferralsEn,
        TemplatesView: TemplatesViewEn,
        TaskConfiguration: TaskConfigurationEn,
        RolesManagementView: RolesManagementViewEn,
        ActivitiesType: ActivitiesTypeEn,
        Dashboard: DashboardEn,
        ReportsBuilderg: ReportsBuildergEn,
        SystemConfigration: SystemConfigrationEn,
        Policies: PoliciesEn,
        GlobalSuccessDialog: GlobalSuccessDialog.en,
        UsersManagement: UsersManagementEn,
        ActivityBuilder: ActivityBuilderEn,
        AutoCorrespondence: AutoCorrespondenceEn,
        ApprovalsConfiguration: ApprovalsConfigurationEn,

      },
      ar: {
        Shared: SharedAr,
        SharedV2: SharedV2Ar,
        BusinessGroupsView: BusinessGroupsViewAr,
        TeamView: TeamViewAr,
        UserDataView: UserDataViewAr,
        UsersView: UsersViewAr,
        BranchView: BranchViewAr,
        RolesView: RolesViewAr,
        HeaderView: HeaderViewAr,
        ImportDetailsView: ImportDetailsViewAr,
        FormBuilder: FormBuilderAr,
        LookupsView: LookupsViewAr,
        LoginView: LoginViewAr,
        FormBuilderView: FormBuilderViewAr,
        ActivitiesView: ActivitiesViewAr,
        MonthlyCalendarView: MonthlyCalendarViewAr,
        MyReferralsView: MyReferralsAr,
        TemplatesView: TemplatesViewAr,
        TaskConfiguration: TaskConfigurationAr,
        RolesManagementView: RolesManagementViewAr,
        ActivitiesType: ActivitiesTypeAr,
        Dashboard: DashboardAr,
        ReportsBuilderg: ReportsBuildergAr,
        SystemConfigration: SystemConfigrationAr,
        Policies: PoliciesAr,
        GlobalSuccessDialog: GlobalSuccessDialog.ar,
        UsersManagement: UsersManagementAr,
        ActivityBuilder: ActivityBuilderAr,
        AutoCorrespondence: AutoCorrespondenceAr,
        ApprovalsConfiguration : ApprovalsConfigurationAr,
      },
      ru: {
        Shared: SharedRu,
        SharedV2: SharedV2Ru,
        BusinessGroupsView: BusinessGroupsViewRu,
        TeamView: TeamViewRu,
        UserDataView: UserDataViewRu,
        UsersView: UsersViewRu,
        BranchView: BranchViewRu,
        RolesView: RolesViewRu,
        HeaderView: HeaderViewRu,
        ImportDetailsView: ImportDetailsViewRu,
        FormBuilder: FormBuilderRu,
        LookupsView: LookupsViewRu,
        LoginView: LoginViewRu,
        FormBuilderView: FormBuilderViewRu,
        ActivitiesView: ActivitiesViewRu,
        MonthlyCalendarView: MonthlyCalendarViewRu,
        MyReferralsView: MyReferralsRu,
        TemplatesView: TemplatesViewRu,
        TaskConfiguration: TaskConfigurationRu,
        RolesManagementView: RolesManagementViewRu,
        ActivitiesType: ActivitiesTypeRu,
        Dashboard: DashboardRu,
        ReportsBuilderg: ReportsBuildergRu,
        SystemConfigration: SystemConfigrationRu,
        Policies: PoliciesRu,
        UsersManagement: UsersManagementRu,
        GlobalSuccessDialog: GlobalSuccessDialog.ru,
        ActivityBuilder: ActivityBuilderRu,
        AutoCorrespondence: AutoCorrespondenceRu,
        ApprovalsConfiguration : ApprovalsConfigurationRu,
      },
      cn: {
        Shared: SharedCn,
        SharedV2: SharedV2Cn,
        BusinessGroupsView: BusinessGroupsViewCn,
        TeamView: TeamViewCn,
        UserDataView: UserDataViewCn,
        UsersView: UsersViewCn,
        BranchView: BranchViewCn,
        RolesView: RolesViewCn,
        HeaderView: HeaderViewCn,
        ImportDetailsView: ImportDetailsViewCn,
        FormBuilder: FormBuilderCn,
        LookupsView: LookupsViewCn,
        LoginView: LoginViewCn,
        FormBuilderView: FormBuilderViewCn,
        ActivitiesView: ActivitiesViewCn,
        MonthlyCalendarView: MonthlyCalendarViewCn,
        MyReferralsView: MyReferralsCn,
        TemplatesView: TemplatesViewCn,
        TaskConfiguration: TaskConfigurationCn,
        RolesManagementView: RolesManagementViewCn,
        ActivitiesType: ActivitiesTypeCn,
        Dashboard: DashboardCn,
        ReportsBuilderg: ReportsBuildergCn,
        SystemConfigration: SystemConfigrationCn,
        Policies: PoliciesCn,
        GlobalSuccessDialog: GlobalSuccessDialog.cn,
        UsersManagement: UsersManagementCn,
        ActivityBuilder: ActivityBuilderCn,
        AutoCorrespondence: AutoCorrespondenceCn,
        ApprovalsConfiguration: ApprovalsConfigurationCn,
      },
    },
  });
  if (localStorage.getItem('localization')) {
    moment.tz.setDefault(config.timeZone);
    i18next.changeLanguage(
      JSON.parse(localStorage.getItem('localization')).currentLanguage
    );
    const isRtl =
      JSON.parse(localStorage.getItem('localization')).currentLanguage === 'ar';
    if (isRtl) {
      const direction =
        JSON.parse(localStorage.getItem('localization')).currentLanguage ===
        'ar'
          ? 'rtl'
          : '';
      document.body.setAttribute('class', direction);
      document.body.setAttribute('dir', direction);
      document.documentElement.lang = JSON.parse(
        localStorage.getItem('localization')
      ).currentLanguage;
    }
  } else {
    localStorage.setItem(
      'localization',
      JSON.stringify({ currentLanguage: 'en', isRtl: false })
    );
    i18next.changeLanguage('en');
  }
}

export const languageChange = (currentLanguage, isDarkMode) => {
  const { dispatch } = store;
  const isRtl = currentLanguage === 'ar';
  const direction = currentLanguage === 'ar' ? 'rtl' : '';
  localStorage.setItem(
    'localization',
    JSON.stringify({ currentLanguage, isRtl })
  );
  document.body.setAttribute('class', direction);
  document.body.setAttribute('dir', direction);
  const themeDirection = direction ? 'rtl' : 'ltr';
  dispatch({ type: 'TOGGLE_THEME_DIRECTION', payload: themeDirection });
  document.documentElement.lang = currentLanguage;
  i18next.changeLanguage(currentLanguage);

  if (typeof isDarkMode === 'boolean') {
    document.body.classList.add('new-layout');
    if (isDarkMode) {
      document.body.classList.add('new-layout__dark-mode');
    }
  }

  GlobalRerender();
};

localizationInit();
