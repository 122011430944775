import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { RequestTypeEnum } from '../../../../Enums';
import { XeleDialog, Spinner, XeleTable } from '../../../../Components';
import { isPrimative } from '../../../../Helper';
import { XelePagination } from '../../../../Components/PaginationComponent/XelePagination';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { GetAllDFMTransactionForPolicy } from '../../../../Services/PoliciesServices';

export default function PolicyHistoryDialog({
  close,
  activeItem,
  parentTranslationPath,
  translationPath,
  open,
}) {
  const { t } = useTranslation('Policies');
  const [isLoading, setIsLoading] = useState(false);
  const [changedFields, setChangedFields] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [policyTransactions, setPolicyTransactions] = useState({
    result: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState({
    pageSize: localStorage.getItem('Pagination') || 25,
    pageIndex: 0,
  });

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
    setChangedFields([]);
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    setChangedFields([]);
  };
  const getAllDFMTransactionForPolicy = useCallback(async () => {
    setIsLoading(true);

    const res = await GetAllDFMTransactionForPolicy({
      ...filter,
      policyId: activeItem.policyDataId,
    });
    if (!(res && res.status && res.status !== 200))
      setPolicyTransactions({ result: res.result, totalCount: res.totalCount });
    else setPolicyTransactions({ result: [], totalCount: 0 });

    setIsLoading(false);
  }, [activeItem, filter]);

  const getPolicyCategories = () => {
    let policyCategories = [];

    const response =
      policyTransactions.result &&
      policyTransactions.result.length > 0 &&
      policyTransactions.result[0].response
        ? JSON.parse(policyTransactions.result[0].response)
        : null;

    if (response) {
      for (const key in response) {
        if (!isPrimative(response[key])) policyCategories.push(key);
      }
    }
    return policyCategories;
  };

  const getFieldName = (key) => {
    let fieldName = key;
    if (key === 'viewSensitive') {
      fieldName = 'Hide Sensitive Data';
    }
    return fieldName;
  };

  const extractFieldName = (changeOccuredInVal) => {
    const changeTabsList = changeOccuredInVal
      ? changeOccuredInVal.split('/')
      : [];
    const fieldNameIndex =
      changeTabsList.length > 0 ? changeTabsList.length - 1 : -1;
    let fieldName = fieldNameIndex !== -1 ? changeTabsList[fieldNameIndex] : '';
    return fieldName;
  };

  const getObjectSize = (value) => (value ? Object.keys(value).length : 0);

  const getMultiSelectChange = (firstValue, secondValue) => {
    let firstMultiSelect = '',
      secondMultiSelect = '';

    for (const item of firstValue) {
      firstMultiSelect =
        firstMultiSelect + (item?.lookupItemName || item?.fullName) + ', ';
    }
    for (const item of secondValue) {
      secondMultiSelect =
        secondMultiSelect + (item?.lookupItemName || item?.fullName) + ', ';
    }

    return [firstMultiSelect, secondMultiSelect];
  };

  const getChangedFields = (
    firstValue,
    secondValue,
    differenceOutput = [],
    changeOccuredIn = null
  ) => {
    if (Array.isArray(firstValue) && Array.isArray(secondValue)) {
      const [changedFrom, changedTo] = getMultiSelectChange(
        firstValue,
        secondValue
      );

      if (changedFrom !== changedTo) {
        differenceOutput.push({
          fieldName: extractFieldName(changeOccuredIn),
          changedFrom,
          changedTo,
          changeOccuredIn,
        });
      }
      return;
    }

    let itirationValue = firstValue;

    if (getObjectSize(firstValue) === 0 || !firstValue) {
      firstValue = {};
      itirationValue = secondValue;
    } else if (getObjectSize(secondValue) === 0 || !secondValue) {
      secondValue = {};
      itirationValue = firstValue;
    } else if (getObjectSize(secondValue) > getObjectSize(firstValue)) {
      itirationValue = secondValue;
    }

    for (const key in itirationValue) {
      let newChangeOccuredIn = null;
      const policyCategories = getPolicyCategories();
      const isPolicyCategoryKey = policyCategories.includes(key);

      if (changeOccuredIn) newChangeOccuredIn = `${changeOccuredIn}/${key}`;
      else if (isPolicyCategoryKey) newChangeOccuredIn = key;

      if (isPrimative(firstValue[key]) && isPrimative(secondValue[key])) {
        if (firstValue[key] !== secondValue[key]) {
          const changedFromValue = firstValue[key]
            ? JSON.stringify(firstValue[key])
            : null;
          const changedToValue = secondValue[key]
            ? JSON.stringify(secondValue[key])
            : null;

          differenceOutput.push({
            fieldName: getFieldName(key),
            changedFrom: changedFromValue,
            changedTo: changedToValue,
            changeOccuredIn: newChangeOccuredIn,
          });
        }
      } else
        getChangedFields(
          firstValue[key],
          secondValue[key],
          differenceOutput,
          newChangeOccuredIn
        );
    }
    return differenceOutput;
  };

  const findRequestType = (requestType) => {
    const requestTypeName = RequestTypeEnum.find(
      (item) => item.key === requestType
    );
    if (requestTypeName) return requestTypeName.value;
    return 'Read';
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    getAllDFMTransactionForPolicy();
  }, [filter]);

  return (
    <XeleDialog
      titleText={t(`policies-history`)}
      dialogContent={
        <div className='policy-history'>
          <div className='history-table-conent-wrapper'>
            <Spinner isActive={isLoading} />
            <div className='w-100 history-table-header'>
              <span></span>
              <span>{t(`date-and-time-viewed`)}</span>
              <span>{t(`user`)}</span>
              <span>{t(`type`)}</span>
            </div>
            {policyTransactions &&
              policyTransactions.result &&
              policyTransactions.result.map((item, index) => (
                <Accordion
                  key={`${index + 1}-history`}
                  className={`expand-history-icon ${
                    index % 2 === 0 ? 'is-gray' : ''
                  }`}
                  expanded={
                    expanded === item.dfMTransactionId &&
                    (item.requestType && findRequestType(item.requestType)) ===
                      'Update'
                  }
                  onChange={handleChange(item.dfMTransactionId)}
                >
                  <AccordionSummary
                    onClick={() => {
                      const policyBeforeChange = JSON.parse(item.response);
                      const policyAfterChange = JSON.parse(
                        item.responseAfterChanged
                      );

                      setChangedFields(
                        getChangedFields(policyBeforeChange, policyAfterChange)
                      );
                    }}
                  >
                    <div
                      className={`history-table-content w-100 ${
                        index % 2 === 0 ? 'is-gray' : ''
                      }`}
                    >
                      <div
                        className={`history-expand-icon ${
                          (item.requestType &&
                            findRequestType(item.requestType)) === 'Update'
                            ? ''
                            : 'is-gray'
                        } `}
                      >
                        <span
                          className={`mdi mdi-chevron-${
                            expanded === item.dfMTransactionId &&
                            (item.requestType &&
                              findRequestType(item.requestType)) === 'Update'
                              ? 'up'
                              : 'down'
                          }`}
                        />
                      </div>
                      <span>
                        {(item.createdOn &&
                          moment(item.createdOn).format(
                            'DD/MM/YYYY - hh:mm A'
                          )) ||
                          'N/A'}
                      </span>
                      <span>
                        {(item.createdByName && item.createdByName) || 'N/A'}
                      </span>
                      <span>
                        {(item.requestType &&
                          findRequestType(item.requestType)) ||
                          'N/A'}
                      </span>
                    </div>
                  </AccordionSummary>
                  {item.responseAfterChanged &&
                    changedFields &&
                    changedFields.length > 0 && (
                      <AccordionDetails>
                        <XeleTable
                          data={changedFields || []}
                          headerData={[
                            {
                              id: 1,
                              label: 'policy-category',
                              component: (item) => (
                                <span>
                                  {(item &&
                                    item.changeOccuredIn &&
                                    item.changeOccuredIn.split('/')[0]) ||
                                    '-'}{' '}
                                </span>
                              ),
                            },
                            {
                              id: 2,
                              label: 'category-inner-tab',
                              component: (item) => (
                                <span>
                                  {(item &&
                                    item.changeOccuredIn &&
                                    _.startCase(
                                      item.changeOccuredIn.split('/')[1]
                                    )) ||
                                    '-'}
                                </span>
                              ),
                            },
                            {
                              id: 3,
                              label: 'field-name',
                              component: (item) => (
                                <span>{_.startCase(item.fieldName)}</span>
                              ),
                            },
                            {
                              id: 4,
                              label: 'from',
                              component: (item) => (
                                <span>
                                  {(item.changedFrom &&
                                    typeof item.changedFrom === 'string' &&
                                    item.changedFrom) ||
                                    ''}
                                </span>
                              ),
                            },
                            {
                              id: 5,
                              label: 'to',
                              component: (item) => (
                                <span>
                                  {(item.changedTo &&
                                    typeof item.changedTo === 'string' &&
                                    item.changedTo) ||
                                    (typeof item.changedTo === 'object' &&
                                    item.changedTo !== null
                                      ? JSON.stringify(item.changedTo)
                                      : '')}
                                </span>
                              ),
                            },
                          ]}
                          activePage={1}
                          itemsPerPage={
                            (changedFields && changedFields.length) || 0
                          }
                          defaultActions={[]}
                          translationPath={translationPath}
                          parentTranslationPath={parentTranslationPath}
                          totalItems={
                            (changedFields && changedFields.length) || 0
                          }
                        />
                      </AccordionDetails>
                    )}
                </Accordion>
              ))}
          </div>
          <div className='pagination-history-wrapper'>
            <XelePagination
              pageIndex={filter.pageIndex}
              pageSize={filter.pageSize}
              totalCount={policyTransactions?.totalCount || 0}
              onPageIndexChanged={onPageIndexChanged}
              onPageSizeChanged={onPageSizeChanged}
            />
          </div>
        </div>
      }
      isOpen={open}
      maxWidth='lg'
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      onCloseClicked={() => close()}
    />
  );
}
