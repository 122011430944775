import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextFieldComponent } from './TextFieldComponent';
import { DisplayPerType } from '../../ActivitiesTypeManagementView/DialogManagementViewComponent/DialogManagementConrtolComponent/DisplayPerType';
import { ActivitiesRelatedToActivitiesTypeEnum } from '../../../../../Enums';
import { LookupSelect } from '../../ActivitiesTypeManagementView/DialogManagementViewComponent/DialogManagementConrtolComponent/LookupSelect';
import { StaticLookupsIds } from '../../../../../assets/json/StaticLookupsIds';
import { XeleSelect } from '../../../../../Components';

const LeadCategory = [
  { title: 'Seller', value: 1 },
  { title: 'Landlord', value: 2 },
  { title: 'Buyer', value: 3 },
  { title: 'Tenant', value: 4 },
];
const RelateType = {
  UnitType: 1,
  UnitOperation: 2,
  UnitSaleType: 3,
  Contact: 4,
  Lead: 5,
};

const parse = (item) => JSON.parse(item);
const stringify = (item) => JSON.stringify(item);

export const NewValuesSection = ({
  errors,
  formData,
  setErrors,
  isSubmitted,
  activitiesTypesDetails,
  setFormData,
  formFieldIds,
  setActivitiesTypesDetails,
  translationPath,
  setIntActivitiesTypesDetails,
  parentTranslationPath,
  setFormFieldIds,
}) => {
  const { t } = useTranslation(parentTranslationPath,  {
    keyPrefix: translationPath,
  });
  const [loadings, setLoadings] = useState([]);

  const updateItemValue = (item, newValue) => {
    setActivitiesTypesDetails((items) => {
      items[item.fieldKey] = newValue;
      return { ...items };
    });

    setFormFieldIds((items) => {
      const fieldIndex = items.indexOf(
        items.find((f) => f.fieldKey === item.fieldKey)
      );
      if (fieldIndex !== -1)
        items[fieldIndex] = { ...items[fieldIndex], fieldValues: newValue };
      return [...items];
    });
  };
  const activityTypeRelatesItem = useMemo(
    () =>
      formFieldIds.find((item) => item.fieldTitle === 'activityTypeRelates'),
    [formFieldIds]
  );

  const updateActivityTypeRelatesItem = (newValue, relateType) => {
    let prevValue = [];
    if (activityTypeRelatesItem?.fieldValues) {
      prevValue = parse(activityTypeRelatesItem.fieldValues).filter(
        (item) => item.relateType !== relateType
      );
    }
    updateItemValue(
      activityTypeRelatesItem,
      stringify([...newValue, ...prevValue])
    );
  };

  const getValues = (relateType) => {
    if (activityTypeRelatesItem?.fieldValues) {
      return parse(activityTypeRelatesItem?.fieldValues).filter(
        (item) => item.relateType === relateType
      );
    } else {
      return [];
    }
  };
  return (
    <div>
      <div className='bulk-header-section'>
        {t(`new-values`)}
      </div>
      <div className='bulk-sub-header-section'>
        {t(`add-new-value-to-the-following-fields`)}
      </div>
    </div>
  );
};
NewValuesSection.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  setFormData: PropTypes.func.isRequired,
  setUnitDetails: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
  errors: PropTypes.instanceOf(Array).isRequired,
  formData: PropTypes.instanceOf(Array).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  unitDetails: PropTypes.instanceOf(Object).isRequired,
};
