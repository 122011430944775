import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  XeleAutocomplete,
  XeleInput,
  XeleSelect,
} from "../../../../../../../Components";
import { ButtonBase } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { ReminderTypesEnum } from "../../../../../../../Enums";
import "./CustomActivityDialog.scss";

function ReminderComponent({
  t,
  data,
  setData,
  legend,
  translationPath,
  parentTranslationPath,
  templates,
  timeOptions,
  roles,
  schemaErrors,
  isSubmitted,
}) {

  const isLoading =false;
  const defaultReminderData = {
    templateName: null,
    systemTemplateId: null,
    reminderTime: null,
    reminderType: null,
    reminderTo: null,
    leadStageActivityTypeId: null,
    slaExpirationPeriodFreqency: 0,
    slaExpirationPeriodFreqencyId: 1,
    customActivityTypeReminderRecipient: [
      {
        recipientType: null,
      },
    ],
  };

  const getError = (index) =>
    schemaErrors?.find((item) => item.index === index);

  const handleAddReminder = () => {
    if (data.remindersData?.length >= 1 && data.remindersData?.length < 4) {
      const newRemindersData = [...data.remindersData, defaultReminderData];
      setData({
        id: "remindersData",
        value: newRemindersData,
      });
    }
  };

  const reminderToChangedHandler = (reminder, newValue) => {
    const reminderIndex = data.remindersData.findIndex((r) => r === reminder);
    const updatedReminderTO = {
      ...reminder,
      reminderTo: newValue,
      reminderType: newValue === 0 ? null : reminder.reminderType,
      customActivityTypeReminderRecipient: [
        {
          recipientType: newValue,
        },
      ],
    };

    const newRemindersData = [
      ...data.remindersData.slice(0, reminderIndex),
      updatedReminderTO,
      ...data.remindersData.slice(reminderIndex + 1),
    ];

    setData({
      id: "remindersData",
      value: newRemindersData,
    });
  };

  const reminderTypeChangedHandler = (newValue, reminder) => {
    const reminderIndex = data.remindersData.findIndex((r) => r === reminder);
    const updatedReminderType = { ...reminder, reminderType: newValue };

    const newRemindersData = [
      ...data.remindersData.slice(0, reminderIndex),
      updatedReminderType,
      ...data.remindersData.slice(reminderIndex + 1),
    ];

    setData({
      id: "remindersData",
      value: newRemindersData,
    });
  };

  const onTemplateChangeHandler = (reminder, newValue) => {
    const reminderIndex = data.remindersData.findIndex((r) => r === reminder);
    const updatedReminderTemplate = {
      ...reminder,
      systemTemplateId: newValue || null,
      systemTemplateName: newValue?.systemTemplateName || null,
    };

    const newRemindersData = [
      ...data.remindersData.slice(0, reminderIndex),
      updatedReminderTemplate,
      ...data.remindersData.slice(reminderIndex + 1),
    ];

    setData({
      id: "remindersData",
      value: newRemindersData,
    });
  };

  const slaExpiriationPeriodFrequencyChangedHandler = (reminder, newValue) => {
    const reminderIndex = data.remindersData.findIndex((r) => r === reminder);
    const newExplorationPeriodFrequency = timeOptions.find(
      (option) => option.id === newValue
    );

    const updatedReminderSlaExpirationPeriod = {
      ...reminder,
      slaExpirationPeriodFreqencyId: newValue,
      slaExpirationPeriodFreqency: newExplorationPeriodFrequency?.value,
    };

    const newRemindersData = [
      ...data.remindersData.slice(0, reminderIndex),
      updatedReminderSlaExpirationPeriod,
      ...data.remindersData.slice(reminderIndex + 1),
    ];

    setData({
      id: "remindersData",
      value: newRemindersData,
    });
  };

  const slaReminderTimeChangedHandler = (reminder, newValue) => {
    const reminderIndex = data.remindersData.findIndex((r) => r === reminder);

    const updatedSlaTime = {
      ...reminder,
      reminderTime: +newValue,
    };

    const newRemindersData = [
      ...data.remindersData.slice(0, reminderIndex),
      updatedSlaTime,
      ...data.remindersData.slice(reminderIndex + 1),
    ];

    setData({
      id: "remindersData",
      value: newRemindersData,
    });
  };

  const getActivityReminderToTypes = (remniderTo) => {
    let reminderTypeOptions = [];

    if (remniderTo !== roles[0].id)
      reminderTypeOptions = Object.values(ReminderTypesEnum);
    if (remniderTo === roles[0].id) {
      reminderTypeOptions.push(ReminderTypesEnum.Email);
      reminderTypeOptions.push(ReminderTypesEnum.Sms);
    }
    return reminderTypeOptions;
  };

  const handleRemoveReminder = (reminderIndex) => {
    if (reminderIndex < 0 || reminderIndex >= data.remindersData?.length) {
      console.error("Invalid reminder index");
      return;
    }

    const newRemindersData = [
      ...data.remindersData.slice(0, reminderIndex),
      ...data.remindersData.slice(reminderIndex + 1),
    ];

    if (newRemindersData?.length === 0) {
      newRemindersData.push(defaultReminderData);
    }

    setData({
      id: "remindersData",
      value: newRemindersData,
    });
  };

  useEffect(() => {
    const updatedRemindersData = data.remindersData.map((reminder) => {
      if (reminder.systemTemplateId) {
        const matchingTemplate = templates.find(
          (template) => template.systemTemplateId === reminder.systemTemplateId
        );

        if (matchingTemplate) {
          return {
            ...reminder,
            systemTemplateId: matchingTemplate,
          };
        }
      }
      return reminder;
    });

    setData({ id: "remindersData", value: updatedRemindersData });
  }, [templates]);

  useEffect(() => {
    if (data.withReminder && data.remindersData?.length === 0) {
      const newRemindersData = [...data.remindersData, defaultReminderData];
      setData({
        id: "remindersData",
        value: newRemindersData,
      });
    }
  }, [data.withReminder]);

  return (
    <Fragment>
      <div className="d-flex">
        <ButtonBase
          onClick={handleAddReminder}
          disabled={data.remindersCount === 4}
          className="btns theme-solid mb-2"
        >
          + {t("add-reminder")}
        </ButtonBase>
      </div>

      {data?.remindersData.map((reminder, index) => (
        <div key={index}>
          <fieldset className="input-fieldset my-2">
            <legend className="input-fieldset-legend">
              {t(`${legend}`)}
            </legend>
            {!isLoading && (
              <React.Fragment>
                <XeleSelect
                  data={roles || []}
                  textInput={"text"}
                  valueInput="id"
                  keyLoopBy="id"
                  labelValue={"reminder-to"}
                  isSubmitted={isSubmitted}
                  helperText={getError(index)?.error}
                  error={!!getError(index)}
                  emptyItem={{
                    value: null,
                    text: "reminder-to",
                    isDisabled: false,
                  }}
                  value={
                    reminder?.customActivityTypeReminderRecipient[0]
                      ?.recipientType
                  }
                  onSelectChanged={(newValue) =>
                    reminderToChangedHandler(reminder, +newValue)
                  }
                  translationPathForData={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />

                <XeleSelect
                  idRef={`reminderWayRef`}
                  data={getActivityReminderToTypes(reminder?.recipientType)}
                  value={reminder?.reminderType}
                  valueInput="key"
                  textInput="value"
                  onSelectChanged={(value) =>
                    reminderTypeChangedHandler(value, reminder)
                  }
                  isDisabled={
                    reminder?.recipientType === null ||
                    reminder?.recipientType === 0
                  }
                  translationPathForData={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  placeholder="reminder-type"
                  emptyItem={{
                    value: null,
                    text: "reminder-type",
                    isDisabled: false,
                  }}
                  isSubmitted={isSubmitted}
                  helperText={getError(index)?.error}
                  error={!!getError(index)}
                  wrapperClasses="mt-3"
                />

                <XeleAutocomplete
                  idRef="reminderTemplateRef"
                  selectedValues={reminder.systemTemplateId}
                  multiple={false}
                  data={templates || []}
                  displayLabel={(option) =>
                    t(`${option.systemTemplateName || ""}`)
                  }
                  getOptionSelected={(option) =>
                    option?.systemTemplateId ===
                    reminder?.systemTemplateId?.systemTemplateId
                  }
                  wrapperClasses="mt-3"
                  withoutSearchButton
                  inputPlaceholder={t(`template`)}
                  isLoading={isLoading}
                  isWithError
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    onTemplateChangeHandler(reminder, newValue);
                  }}
                  isSubmitted={isSubmitted}
                  helperText={getError(index)?.error}
                  error={!!getError(index)}
                />

                <XeleInput
                  idRef="reminderTimeRef"
                  value={reminder.reminderTime || 0}
                  wrapperClasses="mt-3"
                  isSubmitted={isSubmitted}
                  helperText={getError(index)?.error}
                  error={!!getError(index)}
                  endAdornment={
                    <XeleSelect
                      data={timeOptions || []}
                      value={reminder?.slaExpirationPeriodFreqencyId ?? 1}
                      valueInput="id"
                      textInput="text"
                      onSelectChanged={(newValue) => {
                        slaExpiriationPeriodFrequencyChangedHandler(
                          reminder,
                          newValue
                        );
                      }}
                      wrapperClasses="over-input-select w-auto"
                      idRef="timeAmountTypeRef"
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                    />
                  }
                  type="number"
                  min={0}
                  isWithError
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(event) => {
                    const newValue = +event.target.value;
                    slaReminderTimeChangedHandler(reminder, newValue);
                  }}
                />
                <div>
                  <ButtonBase
                    className="btns-icon theme-solid bg-danger my-2 mr-1 p-3 float-right"
                    onClick={(e) => handleRemoveReminder(index)}
                    disabled={data.remindersCount === 0}
                  >
                    <DeleteIcon />
                  </ButtonBase>
                </div>
              </React.Fragment>
            )}
          </fieldset>
        </div>
      ))}
    </Fragment>
  );
}

export default ReminderComponent;
