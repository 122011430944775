import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { XeleCalendar, Spinner } from '../../../Components';
import { bottomBoxComponentUpdate } from '../../../Helper';
import { ActivitiesManagementDialog } from '../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog';
import {
  GetActivityById,
  GetMyActivities,
} from '../../../Services/ActivitiesServices';

const parentTranslationPath = 'MonthlyCalendarView';

export const MonthlyCalendarView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [events, setEvents] = useState([]);
  const [date, setDate] = useState(moment().format('YYYY-MM-DDTHH:mm:ssZ'));
  const [activeMonth, setActiveMonth] = useState(
    moment().format('YYYY-MM-DDTHH:mm:ssZ')
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [activity, setActivity] = useState(null);
  // selectedDate
  const getMyActivities = useCallback(async () => {
    setIsLoading(true);

    const isCurrentMonth = moment().month() === moment(activeMonth).month();
    const activeMonthIndex = isCurrentMonth
      ? new Date(activeMonth).getMonth()
      : new Date(activeMonth).getMonth() - 1;

    const startDate = moment(
      new Date(new Date(activeMonth).getFullYear(), activeMonthIndex, 1)
    ).format();
    const endDate = moment(
      new Date(new Date(activeMonth).getFullYear(), activeMonthIndex + 1, 0)
    ).format();
    const result = await GetMyActivities(startDate, endDate);
    if (!((result && result.data && result.data.ErrorId) || !result)) {
      const newEvents = [];
      const dates = [];
      if (result && result[0]) {
        result.map((item) => {
          dates.push(+moment(item.activityDate).format('D'));
          newEvents.push({
            hour: moment(item.activityDate).format('hh:mm'),
            date: item.activityDate,
            subject: item.subject,
            activity: item,
          });
        });
      }
      setSelectedDates(dates);
      setEvents(newEvents);
    }
    setIsLoading(false);
  }, [activeMonth]);
  useEffect(() => {
    getMyActivities();
  }, [activeMonth]);

  const GetActivityAPI = useCallback(async (activityId) => {
    const res = await GetActivityById(activityId);
    if (!(res && res.status && res.status !== 200)) setActivity(res);
  }, []);

  const onActivitySelect = useCallback((item) => {
    setOpenDialog(true);
    GetActivityAPI(item.activityId);
  }, []);
  const onMonthChange = useCallback((value) => {
    setActiveMonth(value);
  }, []);

  useEffect(() => {
    bottomBoxComponentUpdate(null);
  }, []);

  return (
    <div className='monthly-calendar-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='event-calender'>
        <div className='add-new-wrapper'>
          <ButtonBase
            className='btns theme-solid px-3'
            onClick={() => setOpenDialog(true)}
          >
            <span className='mdi mdi-plus' />
            {t(`add-new`)}
          </ButtonBase>
        </div>
        <XeleCalendar
          fullCalendar
          onMonthChange={onMonthChange}
          activities={events}
          onActivitySelect={onActivitySelect}
          wrapperClasses='full-callender w-100'
          selectedDateChanged={(newDate) => setDate(new Date(newDate))}
          selectedDate={date}
          events={{ selectedDays: selectedDates }}
        />
      </div>
      {openDialog && (
        <ActivitiesManagementDialog
          open={openDialog}
          activeItem={activity && activity}
          onSave={() => {
            getMyActivities();
            setOpenDialog(false);
            setActivity(null);
          }}
          close={() => {
            setOpenDialog(false);
            setActivity(null);
          }}
          parentTranslationPath='ActivitiesView'
          translationPath=''
          isGeneralDialog
        />
      )}
    </div>
  );
};
