import {
  HomeRoutes,
  FormBuilderRoutes,
  LookupsRoutes,
  RolesRoutes,
  UserDataViewRoutes,
  TemplatesRoutes,
  DashboardRoutes,
  BranchesRoutes,
  ReportsBuilderRoutes,
  PoliciesRoutes,
  TaskConfigurationRoutes,
} from '../HomeRoutes';

export const BreadCrumbRoutes = [
  ...HomeRoutes,
  ...FormBuilderRoutes,
  ...LookupsRoutes,
  ...RolesRoutes,
  ...UserDataViewRoutes,
  ...TemplatesRoutes,
  ...DashboardRoutes,
  ...BranchesRoutes,
  ...ReportsBuilderRoutes,
  ...PoliciesRoutes,
  ...TaskConfigurationRoutes,
];
