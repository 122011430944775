import { config } from '../config';
import { HttpServices } from '../Helper';

export const GetAllActivityTypes = async ({
  pageIndex,
  pageSize,
  search,
  categoryId,
  filterBy,
  orderBy,
}) => {
  const queryList = [];
  if (search) queryList.push(`search=${search}`);
  if (categoryId) queryList.push(`categoryId=${categoryId}`);
  if (filterBy) queryList.push(`filterBy=${filterBy}`);
  if (orderBy) queryList.push(`orderBy=${orderBy}`);
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/ActivityTypes/${pageIndex}/${pageSize}?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetActivityTypeByActivityId = async (activityId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/ActivityTypes/GetActivityTypeByActivityId/${activityId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllActivityTypeDependencies = async (
  activityTypeId,
  pageIndex,
  pageSize
) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/ActivityTypes/GetAllActivityTypeDependencies/${activityTypeId}/${pageIndex}/${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetActivityTypeById = async (ActivityTypeById, leadId) => {
  const queryList = [];
  if (leadId) queryList.push(`leadId=${leadId}`);

  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/ActivityTypes/GetActivityTypeById/${ActivityTypeById}?${queryList.join(
      '&'
    )}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const DeleteActivityType = async (activityTypeId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/ActivityTypes/${activityTypeId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const CreateActivityTypes = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/ActivityTypes`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateActivityTypes = async (activityId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/ActivityTypes/${activityId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const CreateActivityTypeReminder = async (activityTypeId, body) => {
  const queryList = [];
  if (activityTypeId) queryList.push(`activityTypeId=${activityTypeId}`);

  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/ActivityTypeReminder?${queryList.join(
      '&'
    )}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetActivityTypeReminders = async (activityTypeId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/ActivityTypeReminder/GetActivityTypeReminders/${activityTypeId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetActivityTypeReminderById = async (activityTypeReminderId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/ActivityTypeReminder/GetActivityTypeReminderById/${activityTypeReminderId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const UpdateActivityTypeReminder = async (activityTypeId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/ActivityTypeReminder/${activityTypeId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const DeleteActivityTypeReminder = async (activityTypeId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/ActivityTypeReminder/${activityTypeId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllActivityTypesNewAPI = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/ActivityTypes/GetAllActivityTypes`,
    body
  )

    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const BulkUpdateActivityType = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/ActivityTypes/BulkUpdateActivityType`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetLeadStageActivityTypeForCustomDetails = async (
  leadStageActivityTypeId
) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/ActivityTypes/GetLeadStageActivityTypeForCustomDetails?leadStageActivityTypeId=${leadStageActivityTypeId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const CreateOrUpdateCustomActivity = async (
  leadStageActivityTypeId,
  body
) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/ActivityTypes/CreateOrUpdateCustomActivity?leadStageActivityTypeId=${leadStageActivityTypeId}`,
      body
    );
    return result;
  } catch (error) {
    throw error.response || error;
  }
};

export const DeleteCustomActivity = async (activityTypeId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/ActivityTypes/DeleteCustomActivity?leadStageActivityTypeId=${activityTypeId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const UpdateActivityTypeArchived = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/ActivityTypes/UpdateActivityTypeArchived`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
