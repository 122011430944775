import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { XeleTable, XeleInput } from '../../../../../../../Components';
import { TemplateTreeFieldsView } from '../TemplatebuilderComponent/TemplateTreeFieldsView';
import { TableActions } from '../../../../../../../Enums';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

export const SMSTemplateComponent = ({
  state,
  onStateChanged,
  onContentChanged,
  isSubmitted,
  schema,
  parentTranslationPath,
  Data,
  activeItem,
  translationPath,
}) => {
  const [viewstate, setviewstate] = useState(false);
  const [State, setState] = useState([]);
  const [DragItem, setDragItem] = useState(false);
  const [focsee, setfocsee] = useState(false);
  const [lodeFirstTime, setlodeFirstTime] = useState(true);
  const [DragItemValue, setDragItemDragItemValue] = useState(null);

  const inputReference = useRef(null);
  useEffect(() => { inputReference.current.focus(); }, []);

  const tableActionClicked = useCallback((actionEnum, item, focusedRow, event) => {
    if (actionEnum === TableActions.deleteText.key) {
      try {
        const malek = (state && state.templateBody.replace(`{${item.keyName}}`, ''));
        onContentChanged(malek);
        setState((items) => {
          items.splice(focusedRow, 1);
          return [...items];
        });
      } catch (error) {
        console.log('error: ', error);
      }
    }
  },
    [state && state.templateBody]);

  useEffect(() => {
    if (state.templateKeys.length > 0)
      setState(state && state.templateKeys);
  }, [state.templateKeys]);
  // useEffect(() => {
  //   onContentChanged(state && state.templateBody);
  //   setlodeFirstTime(false);
  // }, [state.templateBody]);

  useEffect(() => { onStateChanged(State); }, [State]);

  useEffect(() => {
    if (DragItem && focsee) {
      if (!(State.find((element) => element.searchKey === DragItemValue.searchKey))) {
        setState((items) => {
          items.push(DragItemValue);
          return [...items];
        });
      }
      setfocsee(false);
    }
  }, [DragItemValue, focsee]);

  return (
    <div className='sms-template-wrapper childs-wrapper'>
      <div className='sms-template-contenar  d-flex    w-100 mb-3'>
        <div className='ss w-100'>
          <XeleInput
            idRef='refs'
            labelValue='sms-text'
            value={state && state.templateBody || ''}
            isWithError
            refs={inputReference}
            multiline
            rows={29}
            isSubmitted={isSubmitted}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(event) => {
              onContentChanged(event.target.value);
              setfocsee(true);
            }}
          />
        </div>
        <TemplateTreeFieldsView
          setDragItem={(value) => { setDragItem(value); }}
          setDragItemDragItemValue={(value) => { setDragItemDragItemValue(value); }}
          Data={Data}
        />
      </div>
      <div className='templates-Tables-Search-Key'>
        <XeleTable
          data={State || []}
          headerData={[
            {
              id: 1,
              label: 'searchKey',
              input: 'searchKey',
            }, {
              id: 2,
              label: 'keyName',
              input: 'keyName',
            }
          ]}
          defaultActions={[
            {
              enum: TableActions.deleteText.key,
            },
          ]}
          activePageChanged={1}
          // itemsPerPageChanged={itemsPerPageChanged}
          activePage={1}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          itemsPerPage={10000}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          totalItems={(state.templateTokens && state.templateTokens.length) || 0}
        />
      </div>
      {/* <TokenTableComponent
        state={state}
        onStateChanged={onStateChanged}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      /> */}
    </div>
  );
};
SMSTemplateComponent.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  onStateChanged: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
