import { config } from '../../config/config';
import { HttpServices } from '../../Helper';

export const GetAllBranches = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/Identity/Branch/GetAllBranches`,
      body
    );
    return result;
  } catch (error) {
    throw error.response;
  }
};

export const CreateBranch = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/Identity/Branch/CreateBranch`,
      body
    );
    return result;
  } catch (error) {
    throw error.response;
  }
};

export const SetBranchAsActiveOrInactive = async ({ branchId }) => {
  try {
    const result = await HttpServices.put(
      `${config.server_address}/Identity/Branch/SetBranchAsActiveOrInactive?branchId=${branchId}`
    );
    return result;
  } catch (error) {
    throw error.response;
  }
};

export const UpdateBranch = async ({ branchId, body }) => {
  try {
    const result = await HttpServices.put(
      `${config.server_address}/Identity/Branch/UpdateBranch?branchId=${branchId}`,
      body
    );
    return result;
  } catch (error) {
    throw error.response;
  }
};

export const GetBranchInfoById = async (branchId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/Identity/Branch/GetBranchInfoById/${branchId}`
    );
    return result;
  } catch (error) {
    throw error.response;
  }
};

export const DeleteBranchById = async (branchId) => {
  try {
    const result = await HttpServices.delete(
      `${config.server_address}/Identity/Branch/DeleteBranchDocument`,
      { params: { branchDocument: branchId } }
    );
    return result;
  } catch (error) {
    throw error.response;
  }
};

export const GetUsersByBranchId = async (branchId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/Identity/Account/GetUsersByBranchId?branchId=${branchId}`
    );
    return result;
  } catch (error) {
    throw error.response;
  }
};
