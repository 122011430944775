/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { ButtonBase } from '@material-ui/core';
import { XeleAutocomplete } from '../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { lookupItemsGet } from '../../../../../../../Services/LookupsServices';
export default function OpationTemplateBuilder({
  resonse,
  SetOpationTemplate,
  systemTemplateCategoryDto,
  setMain,
  SetsystemTemplateCategoryId,
  activeItem,
  OpationTemplateBuilderState,
  ParamsHandler,
  OpationState,
  setIsUserTemplte,
  IsUserTemplte,
  activeItemedit,
  parentTranslationPath,
  translationPath,
}) {
  const [SelectedTemplateCategor, setSelectedTemplateCategor] = React.useState(
    []
  );
  const [UnitOperationType, setUnitOperationType] = React.useState(false);
  const getAllPeriodsOfStay = useCallback(async () => {
    const res = await lookupItemsGet({
      lookupTypeName: 'UnitOperationType',
    });
    if (!(res && res.status && res.status !== 200))
      setUnitOperationType((res && res.result) || []);
    else setUnitOperationType([]);
  }, []);
  const hasOnlyValue6 = [activeItemedit].every(
    (item) =>
      Array.isArray(item.templateExtraCategoryId) &&
      item.templateExtraCategoryId.length === 1 &&
      item.templateExtraCategoryId[0] === 6
  );
  const Sale =
    (UnitOperationType &&
      UnitOperationType.filter(
        (item) => item && item.lookupItemName && item.lookupItemName === 'Sale'
      )) ||
    [];
  const Rent =
    (UnitOperationType &&
      UnitOperationType.filter(
        (item) => item && item.lookupItemName && item.lookupItemName === 'Rent'
      )) ||
    [];
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [state, setState] = React.useState({
    SaleSale: false,
    SaleDraft: false,
    LeasedDraft: false,
    LeasedLeased: false,
    LeaseAvailable: false,
    SaleAvailable: false,
    SaleReserved: false,
    LeaseReserved: false,
  });
  const handleChange = (event, checked, val, lookupItemId) => {
    setState({
      ...state,
      [event.target.name]: {
        checked: event.target.checked,
        unitStatusId: val,
        unitOperationTypeId: lookupItemId,
      },
    });
  };

  const [UnitTemplates, setUnitTemplates] = React.useState(false);

  useEffect(() => {
    getAllPeriodsOfStay();
  }, []);

  useEffect(() => {
    setMain(state);
  }, [state]);

  const UnitHandfunction = (item) => {
    SetsystemTemplateCategoryId(item);
    setUnitTemplates(true);
    SetOpationTemplate(item, 'add');
    setSelectedTemplateCategor(item);
  };

  const ActivtyHandfunction = (item) => {
    setUnitTemplates(false);
    SetsystemTemplateCategoryId(item);
    SetOpationTemplate(item, 'add');
  };
  useEffect(() => {
    resonse.map(
      (item) =>
        (item &&
          item.systemTemplateCategoryId ===
            systemTemplateCategoryDto.systemTemplateCategoryId &&
          item.templateCategoryName === 'Unit Templates' &&
          UnitHandfunction(item)) ||
        (item &&
          item.systemTemplateCategoryId ===
            systemTemplateCategoryDto.systemTemplateCategoryId &&
          item.templateCategoryName !== 'Unit Templates' &&
          ActivtyHandfunction(item))
    );
  }, [systemTemplateCategoryDto]);

  useEffect(() => {
    if (activeItem && OpationTemplateBuilderState)
      setState(OpationTemplateBuilderState);
  }, [activeItem.systemTemplateUnitStatuses]);

  useEffect(() => {
    if (OpationState === 'edit' && systemTemplateCategoryDto) {
      setSelectedTemplateCategor(systemTemplateCategoryDto);
    }
  }, [systemTemplateCategoryDto, OpationState]);

  return (
    <div className='d-flex' style={{ height: '7rem', maxWidth: '4rem' }}>
      <XeleAutocomplete
        idRef='SelecttemplateCategoryNameRef'
        multiple={false}
        labelValue={t(`templateCategoryName`)}
        data={
          resonse.filter((item) => item && item.categoryType === 'Main') || []
        }
        // isLoading={isLoading}
        isDisabled={OpationState === 'edit'}
        chipsLabel={(option) => option.templateCategoryName || ''}
        displayLabel={(option) => `${option.templateCategoryName || ''}`}
        withoutSearchButton
        selectedValues={SelectedTemplateCategor}
        getOptionSelected={(option) =>
          option.templateCategoryName ===
          SelectedTemplateCategor.templateCategoryName
        }
        inputPlaceholder={t(`SelecttemplateCategoryName`)}
        translationPath={translationPath}
        onChange={(event, newValue) => {
          setSelectedTemplateCategor(newValue);
          if (newValue.mainParameter === 'UnitId') {
            setUnitTemplates(true);
          } else {
            setUnitTemplates(false);
          }
          SetOpationTemplate(newValue || [], 'remove');
          ParamsHandler(newValue || []);
          SetsystemTemplateCategoryId(newValue || []);
          SetOpationTemplate(newValue || [], 'add');
        }}
      />

      <div className='display-display-3'>
        {(activeItemedit && IsUserTemplte === false && <div />) || (
          <ButtonBase
            className={`btns theme-solid ${
              IsUserTemplte ? 'bg-success' : 'bg-primary'
            } mb-2`}
            disabled={
              hasOnlyValue6 || (activeItemedit && IsUserTemplte === false)
            }
            onClick={() => {
              setIsUserTemplte(!IsUserTemplte);
            }}
          >
            <span className='px-1' />
            {t(`User-Templates`)}
          </ButtonBase>
        )}
      </div>

      <div className='box_sc_conteraner d-flex'>
        {(UnitTemplates && !IsUserTemplte && (
          <>
            <div>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        (state && state.SaleSale && state.SaleSale.checked) ||
                        false
                      }
                      onChange={(event, checked) =>
                        handleChange(event, checked, 6, Sale[0].lookupItemId)
                      }
                      name='SaleSale'
                      color='primary'
                    />
                  }
                  label='SaleSale'
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        (state && state.SaleDraft && state.SaleDraft.checked) ||
                        false
                      }
                      onChange={(event, checked) =>
                        handleChange(event, checked, 10, Sale[0].lookupItemId)
                      }
                      name='SaleDraft'
                      color='primary'
                    />
                  }
                  label='SaleDraft'
                />{' '}
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        (state &&
                          state.SaleAvailable &&
                          state.SaleAvailable.checked) ||
                        false
                      }
                      onChange={(event, checked) =>
                        handleChange(event, checked, 1, Sale[0].lookupItemId)
                      }
                      name='SaleAvailable'
                      color='primary'
                    />
                  }
                  label='SaleAvailable'
                />{' '}
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        (state &&
                          state.SaleReserved &&
                          state.SaleReserved.checked) ||
                        false
                      }
                      onChange={(event, checked) =>
                        handleChange(event, checked, 5, Sale[0].lookupItemId)
                      }
                      name='SaleReserved'
                      color='primary'
                    />
                  }
                  label='SaleReserved'
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        (state &&
                          state.LeasedLeased &&
                          state.LeasedLeased.checked) ||
                        false
                      }
                      onChange={(event, checked) =>
                        handleChange(event, checked, 3, Rent[0].lookupItemId)
                      }
                      name='LeasedLeased'
                      color='primary'
                    />
                  }
                  label='LeasedLeased'
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        (state &&
                          state.LeasedDraft &&
                          state.LeasedDraft.checked) ||
                        false
                      }
                      onChange={(event, checked) =>
                        handleChange(event, checked, 10, Rent[0].lookupItemId)
                      }
                      name='LeasedDraft'
                      color='primary'
                    />
                  }
                  label='LeasedDraft'
                />{' '}
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        (state &&
                          state.LeaseAvailable &&
                          state.LeaseAvailable.checked) ||
                        false
                      }
                      onChange={(event, checked) =>
                        handleChange(event, checked, 1, Rent[0].lookupItemId)
                      }
                      name='LeaseAvailable'
                      color='primary'
                    />
                  }
                  label='LeaseAvailable'
                />{' '}
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        (state &&
                          state.LeaseReserved &&
                          state.LeaseReserved.checked) ||
                        false
                      }
                      onChange={(event, checked) =>
                        handleChange(event, checked, 2, Rent[0].lookupItemId)
                      }
                      name='LeaseReserved'
                      color='primary'
                    />
                  }
                  label='LeaseReserved'
                />
              </FormGroup>
            </div>
          </>
        )) ||
          ''}
      </div>
    </div>
  );
}
