import React, { useState, useEffect, useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './TaskConfiguration.scss';
import { PageHeading } from '../../../SharedComponents';
import { TableActions } from '../../../Enums';
import { XeleInput, XeleTable } from '../../../Components';
import { GlobalNavigate } from '../../../Helper';
import { DeleteConfigDialog } from './Dialogs';
import Button from '@material-ui/core/Button';
import { XeleDateRangePicker } from '../../../Components/Controls/DateRangePickerComponent/XeleDateRangePicker';
import { useTitle } from '../../../Hooks';
import { GetTaskConfigs } from '../../../Services/TaskConfiguration/TaskConfiguration';

export const TaskConfigurationsView = () => {
  const parentTranslationPath = 'TaskConfiguration';
  const { t } = useTranslation(parentTranslationPath);

  useTitle(t('task-configuration'));

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
    selectedDateType: 1,
  };

  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };

  const [isLoading, setIsLoading] = useReducer(reducer, {
    filtering: false,
  });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [filter, setFilter] = useState({
    pageIndex: 1,
    pageSize: 150,
  });
  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: null,
    key: 'selection',
    selectedDateType: dateRangeDefault,
  });

  const [data, setData] = useReducer(reducer, {
    configs: [],
  });
  const actionsList = [
    {
      enum: TableActions.editText.key,
    },
    {
      enum: TableActions.deleteText.key,
    },
  ];
  const [activeItem, setActiveItem] = useState(null);

  const getTaskConfigs = async () => {
    setIsLoading({ id: 'filtering', value: true });
    const body = {
      ...(filter || {}),
    };
    const res = await GetTaskConfigs(body);
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'configs', value: res?.result || [] });
    } else {
      setData({ id: 'configs', value: [] });
    }
    setIsLoading({ id: 'filtering', value: false });
  };

  const dateFilterHandler = (selectedDate) => {
    setDateFilter((item) => ({
      ...item,
      startDate: selectedDate?.selection?.startDate,
      endDate: selectedDate?.selection?.endDate,
      key: 'selection',
    }));
    setFilter({
      ...filter,
      createdDateFrom: selectedDate?.selection?.startDate
        ? new Date(moment(selectedDate.selection.startDate).endOf('day'))
        : null,
      createdDateTo: selectedDate?.selection?.endDate
        ? new Date(moment(selectedDate.selection.endDate).endOf('day'))
        : null,
    });
  };

  const actionClickHandler = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      if (actionEnum === TableActions.deleteText.key) {
        setIsDeleteDialogOpen(true);
        setActiveItem(item);
      } else if (actionEnum === TableActions.editText.key) {
        GlobalNavigate(
          `/home/task-configuration/configuration-management?id=${item?.taskConfigurationId}`
        );
      }
    },
    []
  );

  const reloadData = () => {
    getTaskConfigs();
  };

  useEffect(() => {
    getTaskConfigs();
  }, [filter]);

  return (
    <div className='propx-view'>
      <div className='d-flex-v-center-h-between'>
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          
          headerTitle={'task-configuration'}
          subTitle={'task-configuration-heading'}
          wrapperClasses={'px-4 pt-2 w-75'}
          headerTitleClasses={'fz-30  mb-3'}
          subTitleClasses={'fz-12 m-0'}
        />
        <div className='w-25 px-4 d-flex-v-center-h-between'>
          <div className='w-100 d-flex-center'>
            <div className='w-50 mt-2 mr-3'>
              <XeleInput
                value={filter.name}
                idRef='activitiesSearchRef'
                labelClasses='mt-4'
                onInputChanged={(e) =>
                  setFilter({
                    ...filter,
                    name: e?.target?.value || '',
                  })
                }
                inputPlaceholder={t(`configuration-name`)}
              />
            </div>
            <div className='customDatePicker'>
              <XeleDateRangePicker
                onClearClicked={() => {
                  setDateFilter(dateRangeDefault);
                  dateFilterHandler(dateRangeDefault);
                }}
                ranges={[dateFilter]}
                parentTranslationPath={parentTranslationPath}
                
                onDateChanged={(selectedDate) => {
                  dateFilterHandler(selectedDate);
                }}
              />
            </div>
          </div>
          <Button
            className='btns theme-propx solid'
            id='btn-save'
            disabled={isLoading.saving}
            onClick={() =>
              GlobalNavigate(
                `/home/task-configuration/configuration-management`
              )
            }
          >
            <span className='mdi mdi-plus' />{' '}
            <span>{t(`add-new`)}</span>
          </Button>
        </div>
      </div>
      <div className='propx-view-container md'>
        <XeleTable
          data={data.configs || []}
          headerData={[
            {
              id: 1,
              label: 'name',
              input: 'name',
            },
            {
              id: 2,
              label: 'taskCategory',
              input: 'taskCategory',
            },
            {
              id: 3,
              label: 'priorityName',
              input: 'priorityName',
            },
            {
              id: 5,
              label: 'formName',
              input: 'formName',
            },
            {
              id: 6,
              label: 'createdByName',
              input: 'createdByName',
            },
            {
              id: 7,
              label: 'createdOn',
              input: 'createdOn',
              isDate: true,
              dateFormat: 'DD/MM/YYYY',
            },
            {
              id: 8,
              label: 'Assigned-to',
              input: 'taskConfigurationRoles',
              component: (item) =>
                (
                  <span>
                    {item?.taskConfigurationRoles &&
                      item.taskConfigurationRoles
                        .map((role) => role.rolesName || '')
                        .join(' , ')}
                  </span>
                ) || '',
            },
          ]}
          actionsOptions={{
            onActionClicked: actionClickHandler,
          }}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          defaultActions={actionsList}
          parentTranslationPath={parentTranslationPath}
          
          isLoading={isLoading.campaignRequests}
        />
      </div>
      {isDeleteDialogOpen && (
        <DeleteConfigDialog
          activeItem={activeItem}
          isDeleteDialogOpen={isDeleteDialogOpen}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          reloadData={reloadData}
          onCloseClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          onCancelClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          
        />
      )}
    </div>
  );
};
