import { PendingDeactivationView } from "./PendingDeactivationView/PendingDeactivationView";


export const PendingDeactivationTabs = {
  PendingDeactivation: {
    label: "PendingDeactivation",
    key: 1,
    component: PendingDeactivationView,
  },
};
