import { ActivitiesPolicies }  from './ActivitiesPolicies' ; 

export const PoliciesTabs = [
    {   
         label: 'Activities',
         component: ActivitiesPolicies  ,
    } ,

]
  

