import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useReducer,
} from 'react';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { returnPropsByPermissions } from '../../../Helper';
import {
  XeleInput,
  XeleSelect,
  Spinner,
  XeleAutocomplete,
  DataFileAutocomplete,
  ViewTypesActivities,
} from '../../../Components';
import { XeleDateRangePicker } from '../../../Components/Controls/DateRangePickerComponent/XeleDateRangePicker';

import { useTitle } from '../../../Hooks';
import {
  TableActions,
  ViewTypes2Enum,
  ActivitiesRelationTypesEnum,
} from '../../../Enums';
import { ActivitiesManagementDialog } from '../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog';
import { PermissionsComponent } from '../../../Components/PermissionsComponent/PermissionsComponent';
import { GlobalOrderFilterActions } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { ActivitiesTableView } from './ActivitiesViewUtilities/ActivitiesTableView';
import {
  GetAllActivities,
  GetAllActivityTypesAPI,
} from '../../../Services/ActivitiesServices';
import { OrganizationUserSearch } from '../../../Services/UsersServices';
import { GetAllWorkOrders } from '../../../Services/WorkOrdersServices';

const parentTranslationPath = 'ActivitiesView';

export const ActivitiesView = () => {
  // eslint-disable-next-line prefer-const
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };
  const advanceSearch = useSelector(
    (state) => state.GlobalAdvanceSearchReducer
  );
  const activitiesTableFilter =
    (advanceSearch && advanceSearch['activitiesCallCenter']) || null;

  const [agents, setAllAgent] = useState({
    result: [],
    totalCount: 0,
  });

  const [isReplay, setIsReplay] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [activeActionType, setActiveActionType] = useState(
    ViewTypes2Enum.tableRelatedView.key
  );
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const dispatch = useDispatch();
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const { t } = useTranslation(parentTranslationPath);
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);
  const [isLoading, setIsLoading] = useState({
    agents: false,
    units: false,
    leads: false,
    activityTypes: false,
  });

  const [searchedItem, setSearchedItem] = useState('');
  const [isFirst, setIsFirst] = useState(false);

  const [activities, setActivities] = useState({
    result: [],
    totalCount: 0,
  });

  const [activityTypes, setActivityTypes] = useState({
    result: [],
    totalCount: 0,
  });
  const pathName = window.location.pathname
    .split('/home/')[1]
    .split('/view')[0];
  const [openDialog, setOpenDialog] = useState(false);
  const searchTimer = useRef(null);

  const [tableActionspermissionsList, setTableActionspermissionsList] =
    useState([]);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [selected, setSelected] = useReducer(reducer, {
    activityAssign: null,
    activityType: null,
    unit: null,
    activeFormType: 0,
    relationType: null,
    relatedUnit: null,
    relatedLead: null,
    relatedWorkOrder: null,
    relatedPortfolio: null,
    relatedMaintenanceContract: null,
    reminderPersons: [],
  });
  const isPropertyManagementView = pathName === 'activities-management';

  const [selectedOrderBy, setSelectedOrderBy] = useState(
    pathName === 'Activities'
      ? {
          filterBy:
            (orderFilter.activitesCallCenterFilter &&
              orderFilter.activitesCallCenterFilter.filterBy) ||
            null,
          orderBy:
            (orderFilter.activitesCallCenterFilter &&
              orderFilter.activitesCallCenterFilter.orderBy) ||
            null,
        }
      : {
          filterBy:
            (orderFilter.activitesManagementFilter &&
              orderFilter.activitesManagementFilter.filterBy) ||
            null,
          orderBy:
            (orderFilter.activitesManagementFilter &&
              orderFilter.activitesManagementFilter.orderBy) ||
            null,
        }
  );
  const [orderBy, setOrderBy] = useState(
    selectedOrderBy.filterBy
      ? selectedOrderBy
      : { filterBy: null, orderBy: null }
  );

  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    search: '',
    fromDate: null,
    toDate: null,
    filterBy: orderBy.filterBy || null,
    orderBy: orderBy.orderBy || null,
    guidAgentId:
      (orderFilter &&
        orderFilter.activitesCallCenterFilter &&
        orderFilter.activitesCallCenterFilter.agent &&
        orderFilter.activitesCallCenterFilter.agent.id) ||
      null,
    activityTypeId: null,
    relatedUnitId: null,
    relatedLeadId: null,
    relatedWorkOrderId: null,
    relatedMaintenanceContractId: null,
    relatedPortfolioId: null,
    agent:
      (orderFilter &&
        orderFilter.activitesCallCenterFilter &&
        orderFilter.activitesCallCenterFilter.agent) ||
      null,
  });

  useTitle(t(`activities`));

  const getActivityType = useCallback(async (activityTypeSearch) => {
    setIsLoading((loading) => ({ ...loading, activityTypes: true }));
    const res = await GetAllActivityTypesAPI({
      pageSize: 25,
      pageIndex: 0,
      search: activityTypeSearch,
    });
    if (!(res && res.status && res.status !== 200)) {
      setActivityTypes({
        result: res.result || [],
        totalCount: res.totalCount || 0,
      });
    } else {
      setActivityTypes({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((loading) => ({ ...loading, activityTypes: false }));
  });

  const getAllActivities = useCallback(async () => {
    if (!isFirst) return;

    const isViewAllData = pathName === 'activities-management';
    returnPropsByPermissions();
    if (isViewAllData) {
      setIsLoadingActivities(true);

      let activitiesDate = { fromDate: null, toDate: null };
      if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
        activitiesDate = {
          fromDate: moment(dateFilter.startDate)
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss'),
          toDate: moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss'),
        };
      }
      let res = {
        result: [],
        totalCount: 0,
      };
      if (pathName === 'activities-management') {
        res =
          activeActionType === ViewTypes2Enum.tableRelatedView.key
            ? await GetAllActivities({
                ...filter,
                isForPropertyManagement: true,
                isWithoutRelatedActivities: false,
                pageIndex: filter.pageIndex + 1,
                fromDate: activitiesDate.fromDate,
                toDate: activitiesDate.toDate,
                relationType: selected.relationType,
              })
            : await GetAllActivities({
                ...filter,
                isForPropertyManagement: true,
                isWithoutRelatedActivities: true,
                pageIndex: filter.pageIndex + 1,
                fromDate: activitiesDate.fromDate,
                toDate: activitiesDate.toDate,
                relationType: selected.relationType,
              });
      } else {
        res =
          activeActionType === ViewTypes2Enum.tableRelatedView.key
            ? await GetAllActivities({
                ...filter,
                guidAgentId:
                  (filter && filter.agent && filter.agent.id) || null,
                isWithoutRelatedActivities: false,
                pageIndex: filter.pageIndex + 1,
                fromDate: activitiesDate.fromDate,
                toDate: activitiesDate.toDate,
                relationType: selected.relationType,
              })
            : await GetAllActivities({
                ...filter,
                guidAgentId:
                  (filter && filter.agent && filter.agent.id) || null,

                isWithoutRelatedActivities: true,
                pageIndex: filter.pageIndex + 1,
                fromDate: activitiesDate.fromDate,
                toDate: activitiesDate.toDate,
                relationType: selected.relationType,
              });
      }
      if (!(res && res.status && res.status !== 200)) {
        setActivities({
          result: (res && res.result) || [],
          totalCount: (res && res.totalCount) || 0,
        });
      } else {
        setActivities({
          result: [],
          totalCount: 0,
        });
      }

      setIsLoadingActivities(false);
    }
  }, [filter, pathName, activeActionType, dateFilter, selected.relationType]);

  useEffect(() => {
    getActivityType();
  }, []);

  useEffect(() => {
    getAllActivities();
  }, [filter, pathName, activeActionType, dateFilter, selected.relationType]);

  useEffect(() => {
    if (
      (orderFilter.activitesCallCenterFilter &&
        orderFilter.activitesCallCenterFilter.agent &&
        orderFilter.activitesCallCenterFilter.agent.id) !==
      (filter.agent && filter.agent.id)
    )
      setFilter((item) => ({
        ...item,
        agent:
          orderFilter.activitesCallCenterFilter &&
          orderFilter.activitesCallCenterFilter.agent,
      }));
  }, [orderFilter]);

  const searchHandler = (event) => {
    const { value } = event.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({ ...item, search: value, pageIndex: 0 }));
    }, 700);
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const addNewHandler = () => {
    setOpenDialog(true);
  };

  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const orderBySubmitted = () => {
    if (pathName === 'Activities') {
      if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
        if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
        return;
      }
      dispatch(
        GlobalOrderFilterActions.globalOrderFilterRequest({
          ...orderFilter,
          activitesCallCenterFilter: {
            ...orderFilter.activitesCallCenterFilter,
            filterBy: selectedOrderBy.filterBy,
            orderBy: selectedOrderBy.orderBy,
          },
        })
      );
    } else {
      dispatch(
        GlobalOrderFilterActions.globalOrderFilterRequest({
          ...orderFilter,
          activitesManagementFilter: {
            filterBy: selectedOrderBy.filterBy,
            orderBy: selectedOrderBy.orderBy,
          },
        })
      );
    }
    setFilter((item) => ({
      ...item,
      orderBy: selectedOrderBy.orderBy,
      filterBy: selectedOrderBy.filterBy,
    }));
  };

  const [data, setData] = useReducer(reducer, {
    activityAssignments: [],
    activityTypes: [],
    units: [],
    relatedLeads: [],
    relatedUnits: [],
    relatedPortfolio: [],
    relatedWorkOrder: [],
    maintenanceContract: [],
    relatedMaintenanceContractId: [],
  });
  const changeActiveFormType = (newValue) => {
    setSelected({
      id: 'edit',
      value: {
        ...selected,
        activeFormType: +newValue,
        relatedUnit: null,
        relatedLead: null,
        relatedPortfolio: null,
        relatedWorkOrder: null,
        relatedMaintenanceContract: null,
      },
    });
    if (filter.relatedLeadId)
      setFilter((item) => ({ ...item, relatedLeadId: null }));
    if (filter.relatedUnitId)
      setFilter((item) => ({ ...item, relatedUnitId: null }));
    if (filter.relatedWorkOrderId)
      setFilter((item) => ({ ...item, relatedWorkOrderId: null }));
    if (filter.relatedPortfolioId)
      setFilter((item) => ({ ...item, relatedPortfolioId: null }));
    if (filter.relatedMaintenanceContractId)
      setFilter((item) => ({ ...item, relatedMaintenanceContractId: null }));
  };

  const getAllAgents = useCallback(async () => {
    setIsLoading((loading) => ({ ...loading, agents: true }));

    const res = await OrganizationUserSearch({});
    if (!(res && res.status && res.status !== 200)) {
      setAllAgent({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAllAgent({
        result: [],
        totalCount: 0,
      });
    }

    setIsLoading((loading) => ({ ...loading, agents: false }));
  }, []);
  useEffect(() => {
    getAllAgents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onTypeChanged = useCallback(
    (activeType) => {
      setActiveActionType(activeType);
    },
    [setActiveActionType]
  );

  const getAllRelatedWorkOrder = useCallback(async (value) => {
    const response = await GetAllWorkOrders({
      pageIndex: 0,
      pageSize: 25,
      search: value,
    });
    if (!(response && response.status && response.status !== 200)) {
      setData({
        id: 'relatedWorkOrder',
        value: (response && response.result) || [],
      });
    } else setData({ id: 'relatedWorkOrder', value: [] });
  }, []);

  useEffect(() => {
    if (selected.activeFormType === 0) {
      setSelected({
        id: 'relationType',
        value: null,
      });
    } else if (selected.activeFormType === 3) {
      getAllRelatedWorkOrder('');
      setSelected({
        id: 'relationType',
        value: ActivitiesRelationTypesEnum.workOrder.key,
      });
    } else if (selected.activeFormType === 5) {
      setSelected({
        id: 'relationType',
        value: ActivitiesRelationTypesEnum.maintenanceContract.key,
      });
    }
  }, [selected.activeFormType]);

  useEffect(() => {
    const EditpermissionsId = '';
    if (activeActionType === ViewTypes2Enum.tableRelatedView.key) {
      if (isReplay) {
        setTableActionspermissionsList(
          returnPropsByPermissions(EditpermissionsId)
            ? [
                { enum: TableActions.editText.key },
                { enum: TableActions.replyText.key, isDisabled: false },
              ]
            : [{ enum: TableActions.replyText.key, isDisabled: false }]
        );
      } else {
        setTableActionspermissionsList(
          returnPropsByPermissions(EditpermissionsId)
            ? [{ enum: TableActions.editText.key }]
            : []
        );
      }
    }
  }, [isPropertyManagementView, activeActionType, isReplay]);

  useEffect(() => {
    if (sortBy) {
      setFilter((item) => ({
        ...item,
        filterBy: sortBy.filterBy,
        orderBy: sortBy.orderBy,
        pageIndex: 0,
      }));

      if (pathName === 'Activities') {
        dispatch(
          GlobalOrderFilterActions.globalOrderFilterRequest({
            ...orderFilter,
            activitesCallCenterFilter: {
              filterBy: sortBy.filterBy,
              orderBy: sortBy.orderBy,
            },
          })
        );
      } else {
        dispatch(
          GlobalOrderFilterActions.globalOrderFilterRequest({
            ...orderFilter,
            activitesManagementFilter: {
              filterBy: sortBy.filterBy,
              orderBy: sortBy.orderBy,
            },
          })
        );
      }

      setOrderBy((item) => ({
        ...item,
        filterBy: sortBy.filterBy,
        orderBy: sortBy.orderBy,
      }));
      setSelectedOrderBy((item) => ({
        ...item,
        filterBy: sortBy.filterBy,
        orderBy: sortBy.orderBy,
      }));
    }
  }, [sortBy]);

  const onFilterValuesChanged = (newValue) => {
    setIsFirst(true);
    const localFilterDto = filter || {};
    if (isFirst) {
      if (newValue) {
        Object.values(newValue)
          .filter((item) => item.searchableKey || item.displayPath)
          .map((item) => {
            if (localFilterDto[item.displayPath])
              item.value
                ? (localFilterDto[item.displayPath] = item.value)
                : (localFilterDto[item.displayPath] = null);
            else if (item.value) localFilterDto[item.displayPath] = item.value;

            return undefined;
          });
      }
      setFilter(() => ({ ...localFilterDto }));
    } else {
      if (activitiesTableFilter) {
        Object.values(activitiesTableFilter)
          .filter((item) => item.searchableKey || item.displayPath)
          .map((item) => {
            if (localFilterDto[item.displayPath])
              item.value
                ? (localFilterDto[item.displayPath] = item.value)
                : (localFilterDto[item.displayPath] = null);
            else if (item.value) localFilterDto[item.displayPath] = item.value;

            return undefined;
          });
      }
      setFilter(() => ({ ...localFilterDto }));
    }
  };

  return (
    <div className='view-wrapper'>
      <Spinner isActive={isLoadingActivities} />
      <div className='d-flex-column'>
        <div className='header-section'>
          <div className='filter-section'>
            <div className='section px-3'>
              {!isPropertyManagementView && (
                <PermissionsComponent permissionsList={[]} permissionsId={[]}>
                  <ButtonBase
                    className='btns theme-solid px-3'
                    onClick={addNewHandler}
                  >
                    <span className='mdi mdi-plus' />
                    {t(`add-new`)}
                  </ButtonBase>
                </PermissionsComponent>
              )}
            </div>
            <PermissionsComponent permissionsList={[]} permissionsId={[]}>
              <div className='section autocomplete-section'>
                <div className='d-flex-column px-2 w-100 p-relative'>
                  <div className='d-flex-column w-100'>
                    <XeleInput
                      value={searchedItem}
                      onKeyUp={searchHandler}
                      idRef='activitiesSearchRef'
                      label={t(`search-activity`)}
                      onInputChanged={(e) => setSearchedItem(e.target.value)}
                      inputPlaceholder={t(`search-activity`)}
                      beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                    />
                  </div>
                  <div className='d-inline-flex pl-4-reversed'>
                    <div className='w-100 mr-1-reversed'>
                      <XeleAutocomplete
                        idRef='activityTypesRef'
                        isLoading={isLoading.activityTypes}
                        multiple={false}
                        data={activityTypes.result || []}
                        value={filter.activityTypeId}
                        chipsLabel={(option) => option.activityTypeName || ''}
                        displayLabel={(option) => option.activityTypeName || ''}
                        withoutSearchButton
                        inputPlaceholder={t(`activity-type`)}
                        parentTranslationPath={parentTranslationPath}
                        onInputKeyUp={(event) => {
                          const { value } = event.target;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getActivityType(value);
                          }, 700);
                        }}
                        onChange={(event, newValue) => {
                          if (!newValue) getActivityType();
                          setFilter((item) => ({
                            ...item,
                            activityTypeId:
                              (newValue && newValue.activityTypeId) || null,
                            pageIndex: 0,
                          }));
                        }}
                      />
                    </div>
                    <div className='w-100'>
                      <XeleAutocomplete
                        idRef='agentsRef'
                        isLoading={isLoading.agents}
                        multiple={false}
                        data={agents.result || []}
                        selectedValues={filter.agent}
                        chipsLabel={(option) => option.fullName || ''}
                        displayLabel={(option) => option.fullName || ''}
                        withoutSearchButton
                        inputPlaceholder={t(`Agent`)}
                        parentTranslationPath={parentTranslationPath}
                        onChange={(event, newValue) => {
                          setFilter((item) => ({
                            ...item,
                            guidAgentId: (newValue && newValue.id) || null,
                            agent: newValue,
                            pageIndex: 0,
                          }));

                          dispatch(
                            GlobalOrderFilterActions.globalOrderFilterRequest({
                              ...orderFilter,
                              activitesCallCenterFilter: {
                                ...orderFilter.activitesCallCenterFilter,
                                agent:
                                  (newValue && {
                                    id: newValue && newValue.id,
                                    fullName: newValue && newValue.fullName,
                                  }) ||
                                  null,
                              },
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className='d-inline-flex pl-4-reversed mt-2'>
                    <div className='w-100 mr-1-reversed'>
                      {isPropertyManagementView && (
                        <DataFileAutocomplete
                          idRef='RelatedToRef'
                          isDisabled={selected.activeFormType === 0}
                          isLoading={isLoading.units || isLoading.leads}
                          labelClasses='Requierd-Color'
                          multiple={false}
                          selectedValues={
                            (selected.activeFormType === 3 &&
                              selected.relatedWorkOrder) ||
                            (selected.activeFormType === 4 &&
                              selected.relatedPortfolio) ||
                            (selected.activeFormType === 5 &&
                              selected.maintenanceContract)
                          }
                          data={
                            (selected.activeFormType === 3 &&
                              data.relatedWorkOrder) ||
                            (selected.activeFormType === 4 &&
                              data.relatedPortfolio) ||
                            (selected.activeFormType === 5 &&
                              data.relatedMaintenanceContractId) ||
                            []
                          }
                          displayLabel={
                            (selected.activeFormType === 0 &&
                              ((option) => '')) ||
                            (selected.activeFormType === 3 &&
                              ((option) => option.referenceNo || '')) ||
                            (selected.activeFormType === 4 &&
                              ((option) => option.portfolioName || '')) ||
                            (selected.activeFormType === 5 &&
                              ((option) =>
                                String(option.maintenanceContractId) || ''))
                          }
                          renderFor={
                            (selected.activeFormType === 0 && ' ') ||
                            (selected.activeFormType === 3 && 'work-order') ||
                            (selected.activeFormType === 4 && 'portfolio') ||
                            (selected.activeFormType === 5 &&
                              'relatedMaintenanceContractId')
                          }
                          getOptionSelected={
                            (selected.activeFormType === 0 &&
                              ((option) => '')) ||
                            (selected.activeFormType === 3 &&
                              ((option) =>
                                option.workOrderId ===
                                filter.relatedWorkOrderId)) ||
                            (selected.activeFormType === 4 &&
                              ((option) =>
                                option.portfolioId ===
                                filter.relatedPortfolioId)) ||
                            (selected.activeFormType === 5 &&
                              ((option) =>
                                option.maintenanceContractId ===
                                filter.relatedMaintenanceContractId))
                          }
                          onChange={(event, newValue) => {
                            if (selected.activeFormType === 3) {
                              setSelected({
                                id: 'relatedWorkOrder',
                                value: newValue,
                              });
                              setFilter((item) => ({
                                ...item,
                                relatedWorkOrderId:
                                  (newValue && newValue.workOrderId) || null,
                                pageIndex: 0,
                              }));
                            } else if (selected.activeFormType === 4) {
                              setSelected({
                                id: 'relatedPortfolio',
                                value: newValue,
                              });
                              setFilter((item) => ({
                                ...item,
                                relatedPortfolioId:
                                  (newValue && newValue.portfolioId) || null,
                                pageIndex: 0,
                              }));
                            } else if (selected.activeFormType === 5) {
                              setSelected({
                                id: 'relatedMaintenanceContract',
                                value: newValue,
                              });
                              setFilter((item) => ({
                                ...item,
                                relatedMaintenanceContractId:
                                  (newValue &&
                                    newValue.maintenanceContractId) ||
                                  null,
                                pageIndex: 0,
                              }));
                            }
                          }}
                          onInputKeyUp={(e) => {
                            const { value } = e.target;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              if (selected.activeFormType === 3)
                                getAllRelatedWorkOrder(value);
                            }, 700);
                          }}
                          withoutSearchButton
                          inputStartAdornment={
                            <XeleSelect
                              data={[
                                {
                                  key: 0,
                                  value: 'all',
                                },
                                {
                                  key: 3,
                                  value: 'work-order',
                                  backendValue: 'WorkOrder',
                                },
                                {
                                  key: 4,
                                  value: 'portfolio',
                                  backendValue: 'Portfolio',
                                },
                                {
                                  key: 5,
                                  value: 'maintenance-contract',
                                  backendValue: 'MaintenanceContract',
                                },
                              ]}
                              value={selected.activeFormType}
                              valueInput='key'
                              textInput='value'
                              onSelectChanged={changeActiveFormType}
                              wrapperClasses='over-input-select w-auto'
                              idRef='relatedToTypeRef'
                              parentTranslationPath={parentTranslationPath}
                              
                            />
                          }
                          parentTranslationPath={parentTranslationPath}
                        />
                      )}

                      {!isPropertyManagementView && (
                        <DataFileAutocomplete
                          idRef='RelatedToRef'
                          isDisabled={selected.activeFormType === 0}
                          isLoading={isLoading.units || isLoading.leads}
                          labelClasses='Requierd-Color'
                          multiple={false}
                          selectedValues={
                            (selected.activeFormType === 1 &&
                              selected.relatedUnit) ||
                            (selected.activeFormType === 2 &&
                              selected.relatedLead)
                          }
                          data={
                            (selected.activeFormType === 1 &&
                              data.relatedUnits) ||
                            (selected.activeFormType === 2 &&
                              data.relatedLeads) ||
                            []
                          }
                          displayLabel={
                            (selected.activeFormType === 0 &&
                              ((option) => '')) ||
                            (selected.activeFormType === 1 &&
                              ((option) => option.unitRefNo || '')) ||
                            (selected.activeFormType === 2 &&
                              ((option) =>
                                (option.lead &&
                                  option.lead.contact_name &&
                                  option.lead.contact_name.name) ||
                                (option.lead &&
                                  option.lead.company_name &&
                                  option.lead.company_name.name))) ||
                            ''
                          }
                          renderFor={
                            (selected.activeFormType === 0 && ' ') ||
                            (selected.activeFormType === 1 && 'unit') ||
                            (selected.activeFormType === 2 && 'lead')
                          }
                          getOptionSelected={
                            (selected.activeFormType === 0 &&
                              ((option) => '')) ||
                            (selected.activeFormType === 1 &&
                              ((option) =>
                                option.id === filter.relatedUnitId)) ||
                            (selected.activeFormType === 2 &&
                              ((option) =>
                                option.leadId === filter.relatedLeadId))
                          }
                          filterOptions={(options) => options}
                          withoutSearchButton
                          inputStartAdornment={
                            <XeleSelect
                              data={[
                                {
                                  key: 0,
                                  value: 'all',
                                },
                                {
                                  key: 1,
                                  value: 'unit',
                                },
                                {
                                  key: 2,
                                  value: 'lead',
                                },
                              ]}
                              value={selected.activeFormType}
                              valueInput='key'
                              textInput='value'
                              onSelectChanged={changeActiveFormType}
                              wrapperClasses='over-input-select w-auto'
                              idRef='relatedToTypeRef'
                              parentTranslationPath={parentTranslationPath}
                            />
                          }
                          parentTranslationPath={parentTranslationPath}
                        />
                      )}
                    </div>
                    <div className='w-100 filter-section-date-picker'>
                      <XeleDateRangePicker
                        onClearClicked={() => setDateFilter(dateRangeDefault)}
                        ranges={[dateFilter]}
                        emptyLabel={t(`all`)}
                        parentTranslationPath={parentTranslationPath}
                        onDateChanged={(selectedDate) => {
                          setDateFilter({
                            startDate:
                              selectedDate.selection &&
                              selectedDate.selection.startDate,
                            endDate: new Date(
                              moment(
                                selectedDate.selection &&
                                  selectedDate.selection.endDate
                              )
                                .add(-1, 'hours')
                                .endOf('day')
                            ),
                            key: 'selection',
                          });
                          setFilter((item) => ({ ...item, pageIndex: 0 }));
                        }}
                      />
                    </div>
                  </div>
                </div>
                <ViewTypesActivities
                  onTypeChanged={onTypeChanged}
                  className='mb-3'
                />
              </div>
            </PermissionsComponent>
          </div>
          <PermissionsComponent permissionsList={[]} permissionsId={[]}>
            <div className='d-flex px-2'>
              <span className='mx-2 mt-1'>{t(`select`)}</span>
              <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
              <span className='px-2 d-flex'>
                <span className='texts-large mt-1'>{t(`order-by`)}:</span>
                <div className='px-2'>
                  <XeleSelect
                    idRef='filterByRef'
                    data={[
                      { id: 'CreatedOn', filterBy: 'created-date' },
                      { id: 'UpdateOn', filterBy: 'Updated-date' },
                      { id: 'ActivityDate', filterBy: 'activitie-date' },
                    ]}
                    wrapperClasses='mb-3'
                    isRequired
                    value={selectedOrderBy.filterBy}
                    emptyItem={{
                      value: null,
                      text: 'select-filter-by',
                      isDisabled: false,
                    }}
                    onSelectChanged={filterByChanged}
                    valueInput='id'
                    textInput='filterBy'
                    parentTranslationPath={parentTranslationPath}
                  />
                </div>
                <div className='px-2'>
                  <XeleSelect
                    idRef='orderByRef'
                    data={[
                      { id: 1, orderBy: 'ascending' },
                      { id: 2, orderBy: 'descending' },
                    ]}
                    emptyItem={{
                      value: null,
                      text: 'select-sort-by',
                      isDisabled: false,
                    }}
                    value={selectedOrderBy.orderBy}
                    onSelectChanged={orderByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='orderBy'
                    parentTranslationPath={parentTranslationPath}
                    
                  />
                </div>
                <div className='mt-1'>
                  <ButtonBase
                    className='btns theme-solid'
                    onClick={orderBySubmitted}
                    disabled={
                      !selectedOrderBy.filterBy || !selectedOrderBy.orderBy
                    }
                  >
                    <span>{t(`apply`)}</span>
                  </ButtonBase>
                </div>
              </span>
            </div>
          </PermissionsComponent>
        </div>

        {activeActionType === ViewTypes2Enum.tableRelatedView.key && (
          <PermissionsComponent permissionsList={[]} permissionsId={[]}>
            <ActivitiesTableView
              data={activities.result || []}
              activitiesCount={activities.totalCount || 0}
              parentTranslationPath={parentTranslationPath}
              filter={filter}
              onPageIndexChanged={onPageIndexChanged}
              onPageSizeChanged={onPageSizeChanged}
              isLoading={isLoadingActivities}
              setIsLoading={setIsLoadingActivities}
              isTableRelatedView
              setIsReplay={setIsReplay}
              getTableActionsByPermissions={tableActionspermissionsList}
              setSortBy={setSortBy}
              pathName={pathName}
              activeActionType={activeActionType}
              onTypeChanged={onTypeChanged}
              onFilterValuesChanged={onFilterValuesChanged}
              localStorageKey={'activitiesCallCenter'}
            />
          </PermissionsComponent>
        )}
        {activeActionType === ViewTypes2Enum.tableView.key && (
          <PermissionsComponent permissionsList={[]} permissionsId={[]}>
            <ActivitiesTableView
              data={activities.result || []}
              activitiesCount={activities.totalCount || 0}
              parentTranslationPath={parentTranslationPath}
              filter={filter}
              onPageIndexChanged={onPageIndexChanged}
              onPageSizeChanged={onPageSizeChanged}
              isLoading={isLoadingActivities}
              setIsLoading={setIsLoadingActivities}
              getTableActionsByPermissions={tableActionspermissionsList}
              setSortBy={setSortBy}
              pathName={pathName}
              setIsReplay={setIsReplay}
              activeActionType={activeActionType}
              onFilterValuesChanged={onFilterValuesChanged}
              localStorageKey={'activitiesCallCenter'}
            />
          </PermissionsComponent>
        )}
      </div>
      {openDialog && (
        <ActivitiesManagementDialog
          open={openDialog}
          onSave={() => {
            setOpenDialog(false);
            onPageIndexChanged(0);
          }}
          close={() => {
            setOpenDialog(false);
          }}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};
