import React, { memo } from 'react';
import {
  DatePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { Badge, ButtonBase } from '@material-ui/core';
import PropTypes from 'prop-types';

const Calendar = ({
  selectedDate,
  selectedDateChanged,
  hideHeader = true,
  withNumber = false,
  fullCalendar = false,
  onActivitySelect,
  onMonthChange,
  activities = [],
  onlyColorChanged = true,
  events = {
    selectedDays: [],
  },
  format = '',
  isInputPicker = false,
  wrapperClasses = '',
  contactsDetails,
}) => (
  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale='en'>
    <div
      className={`calendars${
        hideHeader ? ' hide-header' : ''
      } ${wrapperClasses}`}
    >
      {isInputPicker && (
        <KeyboardDatePicker
          disableToolbar
          variant='inline'
          format={format}
          margin='normal'
          id='date-picker-inline'
          label='Date picker inline'
          value={selectedDate}
          onChange={selectedDateChanged}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      )}
      <DatePicker
        autoOk
        orientation='portrait'
        variant='static'
        openTo='date'
        // disableFuture
        value={selectedDate}
        onChange={selectedDateChanged}
        // disabled
        // labelFunc={(date) => (date ? date.format('YYYY-MM-DD') : '')}
        // dateForm="YYYY-MM-DD"
        renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
          const currentMonth = moment(day).format();
          const isSelected =
            isInCurrentMonth &&
            events.selectedDays.includes(dayComponent.children);
          if (
            withNumber &&
            events.selectedDays.findIndex(
              (el) => `${el}` === moment(day).format('D')
            ) !== -1 &&
            activities.findIndex(
              (el) => moment(el.date).format('M') === moment(day).format('M')
            ) !== -1
          ) {
            return (
              <Badge
                color='secondary'
                overlap='circle'
                badgeContent={
                  events.selectedDays.filter(
                    (el) => `${el}` === moment(day).format('D')
                  ).length
                }
                onLoad={()=>onMonthChange(currentMonth)}
              >
                {dayComponent}
              </Badge>
            );
          }
          if (
            fullCalendar &&
            events.selectedDays.findIndex(
              (el) => `${el}` === moment(day).format('D')
            ) !== -1 &&
            activities.findIndex(
              (el) => moment(el.date).format('M') === moment(day).format('M')
            ) !== -1
          ) {
            return (
              <div className='full-calendar-item'>
                {activities.map(
                  (item) =>
                    moment(item.date).format('D') ===
                      moment(day).format('D') && (
                      <ButtonBase
                        onClick={() => onActivitySelect(item.activity)}
                        className='activity-item'
                      >
                        <div className='activity-item-subject'>
                          <div className='activity-item-badge' />
                          {item.subject}
                        </div>
                        <div className='activity-item-hour'>{item.hour}</div>
                      </ButtonBase>
                    )
                )}
                <span className='activity-date'>{dayComponent}</span>
              </div>
            );
          }
          if (
            contactsDetails &&
            events.selectedDays.findIndex(
              (el) => `${el}` === moment(day).format('D')
            ) !== -1 &&
            activities.findIndex(
              (el) =>
                moment(el.date).format('M') === moment(day).format('M') &&
                moment(el.date).format('D') === moment(day).format('D')
            ) !== -1
          ) {
            return (
              isInCurrentMonth && (
                <Badge
                  color='primary'
                  variant='dot'
                  overlap='circular'
                  className={
                    moment(selectedDate).format('D/M') ===
                    moment(day).format('D/M')
                      ? 'selectedDay'
                      : 'Badge-dot'
                  }
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  {dayComponent}
                </Badge>
              )
            );
          }
          if (!isSelected) return <span>{dayComponent}</span>;
          if (onlyColorChanged)
            return <span className='have-events'>{dayComponent}</span>;
          return <span>{dayComponent}</span>;
        }}
      />
    </div>
  </MuiPickersUtilsProvider>
);
Calendar.propTypes = {
  selectedDate: PropTypes.string.isRequired,
  selectedDateChanged: PropTypes.func.isRequired,
  hideHeader: PropTypes.bool,
  withNumber: PropTypes.bool,
  fullCalendar: PropTypes.bool,
  onActivitySelect: PropTypes.func,
  onMonthChange: PropTypes.func,
  activities: PropTypes.instanceOf(Array),
  onlyColorChanged: PropTypes.bool,
  events: PropTypes.objectOf(PropTypes.any),
  format: PropTypes.string,
  isInputPicker: PropTypes.bool,
  wrapperClasses: PropTypes.string,
};
export const XeleCalendar = memo(Calendar);
