import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { XeleAutocomplete } from '../../../../../../Components';
import { GetAllBranches } from '../../../../../../Services/userServices';

export const CreatedByBranchesComponent = ({
  parentTranslationPath,
  translationPath,
  setState,
  state,
}) => {
  const { t } = useTranslation(parentTranslationPath,  {
    keyPrefix: translationPath,
  });
  const [branches, setBranches] = useState({
    result: [],
    totalCount: 0,
  });
  const [searchedItem] = useState('');
  const [loadings, setloadings] = useState(false);
  const [selected, setSelected] = useState([]);
  const searchTimer = useRef(null);

  const getBranches = useCallback(async (branchName) => {
    setloadings(true);

    const res = await GetAllBranches({
      pageSize: 25,
      pageIndex: 1,
      branchName,
    });

    if (!(res && res.status && res.status !== 200)) {
      setBranches({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setBranches({
        result: [],
        totalCount: 0,
      });
    }
    setloadings(false);
  }, []);

  const searchHandler = (e) => {
    const { value } = e.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      getBranches(value);
    }, 700);
  };

  useEffect(() => {
    getBranches(searchedItem);
  }, [getBranches, searchedItem]);

  useEffect(() => {
    setSelected(
      state &&
        state.activityTypeCreatedByBranches &&
        state.activityTypeCreatedByBranches.map((x) => ({
          branchId: x.branchId,
          branchName: x.branchName || x.branchName,
        }))
    );
  }, [state]);

  return (
    <div>
      <XeleAutocomplete
        idRef='branch2Ref'
        labelValue={t(`branch`)}
        selectedValues={selected || []}
        inputPlaceholder={t(`Select-multiple`)}
        data={branches?.result || []}
        chipsLabel={(option) => (option.branchName && option.branchName) || ''}
        displayLabel={(option) =>
          (option.branchName && option.branchName) || ''
        }
        getOptionSelected={(option) =>
          selected.findIndex((item) => item.branchId === option.branchId) !==
            -1 || ''
        }
        multiple
        withoutSearchButton
        onInputKeyUp={(e) => searchHandler(e)}
        onChange={(_event, newValue) => {
          const branches = newValue?.map((x) => ({
            branchId: x.branchId,
            branchName: x.branchName,
          }));
          setState(branches);
          setSelected(branches);
        }}
      />
    </div>
  );
};
CreatedByBranchesComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
};
