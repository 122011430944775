import { Button, Checkbox } from '@material-ui/core';
import {
  AccountBoxOutlined,
  HomeOutlined,
  PersonOutlineOutlined,
} from '@material-ui/icons';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  ActivityCategoryComponent,
  ActivityNameComponent,
  ActivityOptions,
  AssignedToComponent,
  CreatedByBranchesComponent,
  CreatedByComponent,
  PrerequisiteActivitiesComponent,
  RatingComponent,
  SLAExpirationPeriodDependOn,
  SLAExpirationPeriodInputComponent,
  SLAExpirationPeriodSection,
  SLALApplyForComponent,
} from './DialogManagementConrtolComponent';
import { LeadScore } from './DialogManagementConrtolComponent/LeadScore';
import { XeleSelect, Spinner, XeleSwitch } from '../../../../../Components';
import { ActivityNotifications } from './DialogManagementConrtolComponent/ActivityNotifications';
import { ActivityReminders } from './DialogManagementConrtolComponent/ActivityReminders';
import { WithFieldset } from './DialogManagementConrtolComponent/WithFieldset';
import { LookupSelect } from './DialogManagementConrtolComponent/LookupSelect';
import { StaticLookupsIds } from '../../../../../assets/json/StaticLookupsIds';
import { DisplayPerType } from './DialogManagementConrtolComponent/DisplayPerType';
import { useTranslation } from 'react-i18next';
import { LeadScoreOption } from './DialogManagementConrtolComponent/LeadScoreOption';
import Joi from 'joi';
import { getErrorByName, showError, showSuccess } from '../../../../../Helper';
import { ActivitiesRelatedToActivitiesTypeEnum } from '../../../../../Enums';
import {
  CreateActivityTypeReminder,
  CreateActivityTypes,
  GetActivityTypeById,
  UpdateActivityTypes,
} from '../../../../../Services/ActivitiesTypesServices';

export const ActivityTypeManagementDialogV2 = ({
  parentTranslationPath,
  translationPath,
  setReloading,
  activeItem,
  onCancelClicked,
}) => {
  const edit = !!activeItem;
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [state, setState] = useReducer(reducer, {
    activityTypeName: '',
    leadStageId: null,
    categoryId: null,
    description: '',
    activityRate: null,
    withDateTime: false,
    withReminder: false,
    withDuration: false,
    followUpRequired: false,
    isForMobile: false,
    isImportant: false,
    slaExpirationPeriod: 0,
    isWithslaExpirationPeriod: false,
    slaExpirationPeriodComparisonDate: 2,
    slaExpirationPeriodFreqency: null,
    isClosedRelatedLead: false,
    isStatic: false,
    slaAppliedFor: null,
    expiredPeriod: 0,
    relatedTo: [],
    createActivityTypeRoles: [],
    activityTypeCreatedByBranches: [],
    assignedToActivityTypeRoles: [],
    activityTypeTemplateRecipientTypes: [],
    activityTypeActivityTypePrerequisites: [],
    activityTypeRotationRoles: [],
    activityTypeRotationUsers: [],
    leadScore: null,
    withNotifications: false,
    notificationsRoles: [],
    notificationCreatedFrom: [],
    notificationsTemplates: [],
    reminderTemplate: null,
    reminderFrequency: { reminderAmountOfTimeType: null, reminderTime: null },
    unitSaleType: [],
    isImportant: false,
    unitType: [],
    unitOperationType: [],
    contactClass: [],
    leadType: [],
    leadScoreOption: null,
    activityTypeRelates: [],
    activityTypeReminders: [],
    activityTypeTemplateRecipientTypes: [],
  });

  const globalSchema = Joi.object({
    activityTypeName: Joi.string()
      .required()
      .messages({
        'string.empty': t('is-required', { field: t('activity-name') }),
        'any.required': t('is-required', { field: t('activity-name') }),
      }),
    categoryId: Joi.number()
      .required()
      .messages({
        'number.base': t('is-required', { field: t('category') }),
        'any.required': t('is-required', { field: t('category') }),
      }),
    activityRate: Joi.number()
      .required()
      .messages({
        'number.base': t('is-required', { field: t('Rating') }),
        'any.required': t('is-required', { field: t('Rating') }),
      }),
    createActivityTypeRoles: Joi.array()
      .min(1)
      .required()
      .messages({
        'array.min': t('is-required', { field: t('Created-By') }),
        'any.required': t('is-required', { field: t('Created-By') }),
      }),
    assignedToActivityTypeRoles: Joi.array()
      .min(1)
      .required()
      .messages({
        'array.min': t('is-required', { field: t('assign-to') }),
        'any.required': t('is-required', { field: t('assign-to') }),
      }),
    relatedTo: Joi.array()
      .min(1)
      .required()
      .messages({
        'array.min': t('is-required', { field: t('Relatedto') }),
        'any.required': t('is-required', { field: t('Relatedto') }),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const updateActivityTypeReminder = useCallback(async (reminders) => {
    setIsLoading(true);
    setIsLoading(false);
  });

  const saveHandler = async () => {
    setIsSubmitted(true);
    let remindersWithoutDefaultItem = state.activityTypeReminders?.filter(
      (item) =>
        item.reminderTo !== null ||
        item.reminderType !== null ||
        item.templateId !== null ||
        item.reminderTime !== 0
    );
    if (state.relatedTo.length === 0) {
      showError(t('select-entity'));
      return;
    }
    const schema = Joi.array()
      .items(
        Joi.object({
          reminderType: Joi.any(),
          reminderTime: Joi.any(),
          reminderTo: Joi.any(),
          templateId: Joi.any(),
        })
          .custom((value, helpers) => {
            if (state.withReminder && !value.reminderType)
              return helpers.error('state.reminderType');
            if (state.withReminder && value.reminderTo === null)
              return helpers.error('state.reminderTo');
            if (state.withReminder && !value.templateId)
              return helpers.error('state.emptyTemplateId');
            if (state.withReminder && value.reminderTime <= 0)
              return helpers.error('state.reminderTime');
            return value;
          })
          .messages({
            'state.reminderType': t(
              `reminder-type-is-required`
            ),
            'state.reminderTo': t(`reminder-to-is-required`),
            'state.emptyTemplateId': t(
              `reminder-template-is-required`
            ),
            'state.reminderTime': t(
              `reminder-time-must-be-greater-than-zero`
            ),
          })
      )
      .options({
        abortEarly: false,
        allowUnknown: true,
      })
      .validate(remindersWithoutDefaultItem);

    if (schema?.error?.message) {
      showError(schema.error.message);
      return;
    }

    if (globalSchema?.error?.message) {
      showError(t('fill-all-required-fields'));
      return;
    }
    setIsLoading(true);
    const reminders = state.activityTypeReminders;
    delete state.activityTypeReminders;
    const res = edit ? handleUpdate() : await CreateActivityTypes(state);

    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) {
      const activityTypeId = res.activityTypeId;
      if (activeItem && activeItem.activityTypeId) {
        showSuccess(t`activity-Type-updated-successfully`);
        updateActivityTypeReminder(reminders);
        setReloading(true);
        onCancelClicked();
      } else {
        showSuccess(t`activity-Type-created-successfully`);
        CreateActivityTypeReminder(activityTypeId, reminders);
        setReloading(true);
        onCancelClicked();
      }
    } else if (edit)
      showError(t(`activity-Type-update-failed`));
    else showError(t`activity-Type-create-failed`);
  };

  const handleUpdate = async () => {
    const newActivityTypeTemplateRecipientTypes =
      state.activityTypeTemplateRecipientTypes.map((recipientType) => ({
        ...recipientType,
        activityTypeTemplateRecipientTypeSources:
          recipientType.activityTypeTemplateRecipientTypeSources.map(
            (source) => ({
              notifyBy: source.notifyById || source.notifyBy,
            })
          ),
      }));

    const body = {
      ...state,
      activityTypeTemplateRecipientTypes: newActivityTypeTemplateRecipientTypes,
    };

    await UpdateActivityTypes(activeItem.activityTypeId, body);
  };

  const [selected, setSelected] = useReducer(reducer, {
    leadCategories: [],
    operationTypes: [],
    saleTypes: [],
    unitTypes: [],
    contactClass: [],
  });
  const entitiesEnum = {
    UNIT: 'unit',
    CONTACT: 'contact',
    LEAD: 'lead',
  };
  const LeadCategory = [
    { title: 'Seller', value: 1 },
    { title: 'Landlord', value: 2 },
    { title: 'Buyer', value: 3 },
    { title: 'Tenant', value: 4 },
  ];

  const RelateType = {
    UnitType: 1,
    UnitOperation: 2,
    UnitSaleType: 3,
    Contact: 4,
    Lead: 5,
  };

  const updateActivityTypeRelates = ({ ids, enumValues }, relateType) => {
    const activityTypeRelates = state.activityTypeRelates.filter(
      (relate) => relate.relateType !== relateType
    );
    if (ids) {
      ids.forEach((id) =>
        activityTypeRelates.push({ relateType, lookupsId: id })
      );
    }
    if (enumValues) {
      enumValues.forEach((id) =>
        activityTypeRelates.push({ relateType, enumValue: id })
      );
    }
    setState({ id: 'activityTypeRelates', value: activityTypeRelates });
  };

  const setEntity = (entity) => () => {
    if (
      state.relatedTo.find(
        (relate) =>
          relate.relatedToId ===
          ActivitiesRelatedToActivitiesTypeEnum[entity].key
      )
    ) {
      const filtered = state.relatedTo.filter(
        (relate) =>
          relate.relatedToId !==
          ActivitiesRelatedToActivitiesTypeEnum[entity].key
      );

      if (entity === entitiesEnum.LEAD) {
        setState({
          id: 'edit',
          value: {
            ...state,
            leadScore: 0,
            leadScoreOption: null,
            activityTypeRelates: state.activityTypeRelates.filter(
              (relate) => relate.relateType !== RelateType.Lead
            ),
            relatedTo: filtered,
          },
        });
        setSelected({ id: 'leadCategories', value: [] });
      } else if (entity === entitiesEnum.CONTACT) {
        setState({
          id: 'edit',
          value: {
            ...state,
            relatedTo: filtered,
            activityTypeRelates: state.activityTypeRelates.filter(
              (relate) => relate.relateType !== RelateType.Contact
            ),
          },
        });
        setSelected({ id: 'contactClass', value: [] });
      } else if (entity === entitiesEnum.UNIT) {
        setState({
          id: 'edit',
          value: {
            ...state,
            relatedTo: filtered,
            activityTypeRelates: state.activityTypeRelates.filter(
              (relate) =>
                ![
                  RelateType.UnitOperation,
                  RelateType.UnitSaleType,
                  RelateType.UnitType,
                ].includes(relate.relateType)
            ),
          },
        });
        setSelected({
          id: 'edit',
          value: {
            ...selected,
            operationTypes: [],
            unitTypes: [],
            saleTypes: [],
          },
        });
      }
    } else {
      setState({
        id: 'relatedTo',
        value: [
          ...state.relatedTo,
          { relatedToId: ActivitiesRelatedToActivitiesTypeEnum[entity].key },
        ],
      });
    }
  };

  const GetActivityTypeByIdAPI = useCallback(async (id) => {
    setIsLoading(true);
    const res = await GetActivityTypeById(id);
    setIsLoading(false);
    const leadScoreOption = res.leadScoreOptionId;
    delete res.leadScoreOptionId;
    if (!(res && res.status && res.status !== 200)) {
      setState({
        id: 'edit',
        value: { ...res, leadScoreOption },
      });
      if (res.activityTypeRelates?.length > 0) {
        setSelected({
          id: 'edit',
          value: {
            leadCategories:
              res.activityTypeRelates
                ?.filter((item) => item.relateType === RelateType.Lead)
                .map((item) => item.enumValue) ?? [],
            operationTypes:
              res.activityTypeRelates
                ?.filter((item) => item.relateType === RelateType.UnitOperation)
                .map((item) => item.lookupsId) ?? [],
            saleTypes:
              res.activityTypeRelates
                ?.filter((item) => item.relateType === RelateType.UnitSaleType)
                .map((item) => item.lookupsId) ?? [],
            unitTypes:
              res.activityTypeRelates
                ?.filter((item) => item.relateType === RelateType.UnitType)
                .map((item) => item.lookupsId) ?? [],
            contactClass:
              res.activityTypeRelates
                ?.filter((item) => item.relateType === RelateType.Contact)
                .map((item) => item.lookupsId) ?? [],
          },
        });
      }
    }
  }, []);

  useEffect(() => {
    if (activeItem) {
      GetActivityTypeByIdAPI(activeItem.activityTypeId);
    }
  }, [activeItem]);

  useEffect(() => {
    if (state.withDateTime === false) {
      setState({ id: 'withReminder', value: false });
      setState({ id: 'activityTypeReminders', value: [] });
    }
  }, [state.withDateTime]);

  return (
    <div className='DialogManagementViewComponent-wrapper d-flex-column'>
      <Spinner isActive={isLoading} />
      <label className='label-wrapper'>{t('entity-text')}</label>
      <div className='d-flex w-100 h-around my-3'>
        <div
          className='checkbox-container'
          onClick={setEntity(entitiesEnum.UNIT)}
        >
          <Checkbox
            className='checkbox'
            checked={
              !!state.relatedTo.find(
                (relate) =>
                  relate.relatedToId ===
                  ActivitiesRelatedToActivitiesTypeEnum[entitiesEnum.UNIT].key
              )
            }
          />
          <div className='d-flex-column align-center'>
            <HomeOutlined fontSize='large' />
            <span>{t('Unit')}</span>
          </div>
        </div>
        <div
          className='checkbox-container'
          onClick={setEntity(entitiesEnum.CONTACT)}
        >
          <Checkbox
            className='checkbox'
            checked={
              !!state.relatedTo.find(
                (relate) =>
                  relate.relatedToId ===
                  ActivitiesRelatedToActivitiesTypeEnum[entitiesEnum.CONTACT]
                    .key
              )
            }
          />
          <div className='d-flex-column align-center'>
            <PersonOutlineOutlined fontSize='large' />
            <span>{t('Contact')}</span>
          </div>
        </div>
        <div
          className='checkbox-container'
          onClick={setEntity(entitiesEnum.LEAD)}
        >
          <Checkbox
            className='checkbox'
            checked={
              !!state.relatedTo.find(
                (relate) =>
                  relate.relatedToId ===
                  ActivitiesRelatedToActivitiesTypeEnum[entitiesEnum.LEAD].key
              )
            }
          />
          <div className='d-flex-column align-center'>
            <AccountBoxOutlined fontSize='large' />
            <span>{t('Lead')}</span>
          </div>
        </div>
      </div>
      <div className='d-flex-column'>
        <ActivityNameComponent
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          state={state}
          setState={(name) => {
            setState({ id: 'activityTypeName', value: name });
          }}
          helperText={getErrorByName(globalSchema, 'activityTypeName')?.message}
          isSubmitted={isSubmitted}
        />
        <ActivityCategoryComponent
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          state={state}
          setState={(category) => {
            setState({ id: 'categoryId', value: category.categoryId });
            setState({ id: 'categoryName', value: category.categoryName });
          }}
          helperText={getErrorByName(globalSchema, 'categoryId')?.message}
          isSubmitted={isSubmitted}
        />
        <CreatedByBranchesComponent
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          state={state}
          setState={(branches) =>
            setState({ id: 'activityTypeCreatedByBranches', value: branches })
          }
        />
        <RatingComponent
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          state={state}
          setState={(activity) => {
            setState({ id: 'activityRate', value: activity.activityRate });
            setState({
              id: 'activityRateName',
              value: activity.activityRateName,
            });
          }}
          helperText={getErrorByName(globalSchema, 'activityRate')?.message}
          isSubmitted={isSubmitted}
        />
        <PrerequisiteActivitiesComponent
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          state={state}
          setState={(prerequisites) =>
            setState({
              id: 'activityTypeActivityTypePrerequisites',
              value: prerequisites,
            })
          }
        />

        <CreatedByComponent
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          state={state}
          setState={(roles) =>
            setState({ id: 'createActivityTypeRoles', value: roles })
          }
          helperText={
            getErrorByName(globalSchema, 'createActivityTypeRoles')?.message
          }
          isSubmitted={isSubmitted}
        />
        <AssignedToComponent
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          state={state}
          setState={(roles) =>
            setState({ id: 'assignedToActivityTypeRoles', value: roles })
          }
          helperText={
            getErrorByName(globalSchema, 'assignedToActivityTypeRoles')?.message
          }
          isSubmitted={isSubmitted}
        />
        <DisplayPerType
          type={ActivitiesRelatedToActivitiesTypeEnum[entitiesEnum.UNIT].key}
          typeList={state.relatedTo}
        >
          <LookupSelect
            lookupTypeId={StaticLookupsIds.UnitSaleType}
            id='unitSaleTypeRef'
            multiple
            labelValue={'unit-sale-type'}
            placeholder={'Select-multiple'}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            value={selected.saleTypes}
            onSelectChanged={(ids) => {
              updateActivityTypeRelates({ ids }, RelateType.UnitSaleType);
              setSelected({ id: 'saleTypes', value: ids });
            }}
          />

          <LookupSelect
            lookupTypeId={StaticLookupsIds.UintType}
            id='unitTypeRef'
            multiple
            labelValue={'unit-type'}
            placeholder={'Select-multiple'}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            value={selected.unitTypes}
            onSelectChanged={(ids) => {
              updateActivityTypeRelates({ ids }, RelateType.UnitType);
              setSelected({ id: 'unitTypes', value: ids });
            }}
          />

          <LookupSelect
            lookupTypeId={StaticLookupsIds.UintOperationType}
            id='unitOperationTypeRef'
            multiple
            labelValue={'unit-operation-type'}
            placeholder={'Select-multiple'}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            value={selected.operationTypes}
            onSelectChanged={(ids) => {
              updateActivityTypeRelates({ ids }, RelateType.UnitOperation);
              setSelected({ id: 'operationTypes', value: ids });
            }}
            excludeLookupByName={'SaleAndRent'}
          />
        </DisplayPerType>
        <DisplayPerType
          type={ActivitiesRelatedToActivitiesTypeEnum[entitiesEnum.CONTACT].key}
          typeList={state.relatedTo}
        >
          <LookupSelect
            lookupTypeId={StaticLookupsIds.ContactClass}
            id='contactClassRef'
            multiple
            labelValue={'contact-class'}
            placeholder={'Select-multiple'}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            value={selected.contactClass}
            onSelectChanged={(ids) => {
              updateActivityTypeRelates({ ids }, RelateType.Contact);
              setSelected({ id: 'contactClass', value: ids });
            }}
          />
        </DisplayPerType>
        <DisplayPerType
          type={ActivitiesRelatedToActivitiesTypeEnum[entitiesEnum.LEAD].key}
          typeList={state.relatedTo}
        >
          <LeadScoreOption
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            state={state}
            setState={setState}
          />
          {state.leadScoreOption && (
            <LeadScore
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              state={state}
              setState={setState}
            />
          )}
          <XeleSelect
            data={LeadCategory}
            textInput={'title'}
            valueInput={'value'}
            idRef='leadTypeRef'
            multiple
            labelValue={'lead-type'}
            placeholder={'Select-multiple'}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            value={selected.leadCategories}
            onSelectChanged={(enumValues) => {
              updateActivityTypeRelates({ enumValues }, RelateType.Lead);
              setSelected({ id: 'leadCategories', value: enumValues });
            }}
          />
        </DisplayPerType>

        <ActivityOptions
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          state={state}
          setState={setState}
        />
        {(state.isWithslaExpirationPeriod || state.slaExpirationPeriod > 0) && (
          <SLAExpirationPeriodSection
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          >
            <SLAExpirationPeriodInputComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              state={state}
              setState={(item) =>
                setState({
                  id: 'edit',
                  value: {
                    ...state,
                    ...item,
                  },
                })
              }
            />
            <SLALApplyForComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              state={state}
              setState={(item) =>
                setState({
                  id: 'edit',
                  value: {
                    ...state,
                    ...item,
                  },
                })
              }
            />
            <SLAExpirationPeriodDependOn
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              state={state}
              setState={(item) =>
                setState({
                  id: 'slaExpirationPeriodComparisonDate',
                  value: +item,
                })
              }
            />
          </SLAExpirationPeriodSection>
        )}
        <XeleSwitch
          isChecked={
            state.withNotifications ||
            state.activityTypeTemplateRecipientTypes?.length > 0
          }
          onChangeHandler={(e) => {
            setState({
              id: 'withNotifications',
              value: e.target.checked,
            });
            setState({
              id: 'activityTypeTemplateRecipientTypes',
              value: [],
            });
          }}
          labelValue={t('with-notifications')}
        />
        {(state.withNotifications ||
          state.activityTypeTemplateRecipientTypes?.length > 0) && (
          <WithFieldset
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            legend={'notifications'}
          >
            <ActivityNotifications
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              setState={setState}
              state={state}
            />
          </WithFieldset>
        )}
        <XeleSwitch
          isChecked={state.withReminder}
          onChangeHandler={(e) => {
            setState({
              id: 'withReminder',
              value: e.target.checked,
            });
            if (e.target.checked === true) {
              setState({
                id: 'withDateTime',
                value: e.target.checked,
              });
            }
            if (!e.target.checked) {
              setState({ id: 'activityTypeReminders', value: [] });
            }
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          labelValue='With-Reminder'
        />
        {state.withReminder && (
          <WithFieldset
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            legend={'reminder'}
          >
            <ActivityReminders
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              setState={(reminder) =>
                setState({ id: 'activityTypeReminders', value: [reminder] })
              }
              state={state}
            />
          </WithFieldset>
        )}
      </div>
      <div className='MuiDialogActions-root dialog-footer-wrapper  MuiDialogActions-spacing'>
        <div className='save-cancel-wrapper d-flex-v-center-h-end flex-wrap p-2'>
          <div className='cancel-wrapper d-inline-flex-center'>
            <Button
              className='MuiButtonBase-root MuiButton-root MuiButton-text cancel-btn-wrapper btns theme-transparent c-primary'
              onClick={onCancelClicked}
              id='cancel-ref-action'
            >
              <span className='MuiButton-label'>
                <span>{t('Shared:cancel')}</span>
              </span>
              <span className='MuiTouchRipple-root' />
            </Button>
          </div>
          <div className='save-wrapper d-inline-flex-center'>
            <Button
              onClick={saveHandler}
              className='MuiButtonBase-root MuiButton-root MuiButton-text save-btn-wrapper btns theme-solid bg-primary'
            >
              <span className='MuiButton-label'>
                <span>{t('Shared:save')}</span>
              </span>
              <span className='MuiTouchRipple-root' />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
