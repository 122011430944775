import React, { useState, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ViewTypesTeamANDBusinessGroups } from '../../Enums';

const TypesTeamANDBusinessGroups = ({
  onTypeChanged,
  activeTypes,
  initialActiveType,
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });
  const [activeType, setActiveType] = useState(
    initialActiveType || ViewTypesTeamANDBusinessGroups.family_tree.key
  );
  const viewTypeClicked = useCallback(
    (actionType) => {
      if (actionType !== activeType) {
        setActiveType(actionType);
        onTypeChanged(actionType);
      }
    },
    [onTypeChanged, activeType]
  );
  const getViewTypesValue = (key) =>
    Object.values(ViewTypesTeamANDBusinessGroups).find(
      (item) => item.key === key
    );
  return (
    <div className='view-types-wrapper'>
      {activeTypes.map((item) => (
        <Tooltip title={t(`${getViewTypesValue(item).name}`)}>
          <Button
            className={`btns-view-type${
              activeType === getViewTypesValue(item).key ? ' active' : ''
            }`}
            id={`btns-view-type${getViewTypesValue(item).name || null}`}
            key={getViewTypesValue(item).key}
            onClick={() => viewTypeClicked(getViewTypesValue(item).key)}
          >
            <span className={getViewTypesValue(item).classes} />
          </Button>
        </Tooltip>
      ))}
    </div>
  );
};
TypesTeamANDBusinessGroups.propTypes = {
  initialActiveType: PropTypes.instanceOf(Object),
  onTypeChanged: PropTypes.func.isRequired,
  activeTypes: PropTypes.arrayOf(
    PropTypes.oneOf(
      Object.values(ViewTypesTeamANDBusinessGroups).map((item) => item.key)
    )
  ),
  parentTranslationPath: PropTypes.string,
  translationPath: PropTypes.string,
};
TypesTeamANDBusinessGroups.defaultProps = {
  initialActiveType: ViewTypesTeamANDBusinessGroups.family_tree.key,
  activeTypes: Object.values(ViewTypesTeamANDBusinessGroups).map(
    (item) => item.key
  ),
  parentTranslationPath: 'Shared',
  translationPath: '',
};
export { TypesTeamANDBusinessGroups };
