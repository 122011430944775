import React, { useCallback, useState } from "react";
import { XeleDialog, Spinner } from "../../../../../../../Components";
import {
  Button,
  DialogActions,
  withStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { showError, showSuccess } from "../../../../../../../Helper";
import { ReminderTimeOptions } from "../../../../../../../Enums/ReminderTimeOptions.enum";
import { CreateOrUpdateCustomActivity, GetActivityTypeById } from "../../../../../../../Services/ActivitiesTypesServices";

const CancelButton = withStyles(() => ({
  root: {
    color: "#000000",
    backgroundColor: "#",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
}))(Button);

const ConfirmButton = withStyles(() => ({
  root: {
    color: "white",
    backgroundColor: "#ed0001",
    border: "2px solid #ed0001",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#ba0000",
    },
  },
}))(Button);

const timeOptions = Object.values(ReminderTimeOptions);
function ConfirmActionsDialog({
  isOpen,
  body,
  reload,
  setReload,
  actionName,
  translationPath,
  parentTranslationPath,
  setIsOpenConfirmDialog,
  leadStageActivityTypeId,
  setIsCustomActivityOpen,
  activityTypeId,
  resetTrigger,
  setResetTrigger,
  resetData,
  setResetData,
  fetchedTemplates,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [isLoading, setIsLoading] = useState(false);

  const getActivityTypeByIdApi = useCallback(async () => {
    const res = await GetActivityTypeById(activityTypeId);
    if (!(res && res.status && res.status !== 200)) {
      const newTemplate = fetchedTemplates.find(
        (template) =>
          template.systemTemplateId ===
          res?.activityTypeReminders[0]?.templateId
      );

      const newReminderData = res?.activityTypeReminders.map((reminder) => ({
        leadStageActivityTypeId: leadStageActivityTypeId,
        reminderTime: reminder?.reminderTime,
        systemTemplateId: newTemplate,
        templateName: reminder?.templateName,
        reminderType: reminder?.reminderType,
        reminderTo: reminder?.reminderTo,
        customActivityTypeReminderRecipient: [
          { recipientType: reminder?.reminderTo },
        ],
      }));
      const newSlaExpirationFrequency = timeOptions.find(
        (option) => option.id === res?.slaExpirationPeriodFreqency
      );

      const newSlaAppliedFor =
        res.slaAppliedFor === 1
          ? "OnlyForBulkLeads"
          : res.slaAppliedFor === 2
          ? "OnlyForFreshLeads"
          : res.slaAppliedFor === 3
          ? "ForBothBulkAndFreshLeads"
          : null;

      setResetData({
        id: "edit",
        value: {
          stateSlaExpirationPeriod: res?.slaExpirationPeriod,
          stateActivityTypeName: res?.activityTypeName,
          stateSlaExpirationPeriodFreqency: res.slaExpirationPeriodFreqency || "Minutes",
          stateSlaExpirationPeriodComparisonDate:
            res?.slaExpirationPeriodComparisonDate === 1
              ? "ActivityDate"
              : "CreationDate",
          stateSlaExpirationPeriodComparisonDateId:
            res?.slaExpirationPeriodComparisonDate,
          stateSlaAppliedForId: res?.slaAppliedFor || 3,
          stateSlaAppliedFor: newSlaAppliedFor || "ForBothBulkAndFreshLeads",
          stateSlaExpirationPeriodComparisonDateId:
            res?.slaExpirationPeriodComparisonDate,
          stateLeadScore: res?.leadScore || 0,
          stateLeadScoreOption: res?.leadScoreOptionName,
          StateLeadScoreOptionId: res?.leadScoreOptionId,

          leadScore: res?.leadScore || 0,
          leadScoreOption: res?.leadScoreOptionName,
          leadScoreOptionId: res?.leadScoreOptionId,

          slaExpirationPeriod: res?.slaExpirationPeriod,
          slaExpirationPeriodFreqency: newSlaExpirationFrequency?.value || 1,
          slaExpirationPeriodFreqencyId: res?.slaExpirationPeriodFreqency,

          slaExpirationPeriodComparisonDateId:
            res?.slaExpirationPeriodComparisonDate,
          slaExpirationPeriodComparisonDate:
            res?.slaExpirationPeriodComparisonDate === 1
              ? "ActivityDate"
              : res?.slaExpirationPeriodComparisonDate === 2
              ? "CreationDate"
              : null,

          withReminder:
            newReminderData?.length === 0 ? false : res?.withReminder,
          remindersData: newReminderData || [],
        },
      });
    }
    setResetTrigger(!resetTrigger);
  }, [
    resetData,
    setResetData,
    resetTrigger,
    setResetTrigger,
    fetchedTemplates,
  ]);

  const handleConfirm = async () => {
    setIsLoading(true);

    if (actionName === "submit") {
      let res = await CreateOrUpdateCustomActivity(
        leadStageActivityTypeId,
        body
      );
      if (!(res && res.status && res.status !== 200)) {
        setIsOpenConfirmDialog(false);
        setIsCustomActivityOpen(false);
        showSuccess(t("submitted-successfully"));
      } else {
        setIsLoading(false);
        showError(t("error-while-submitting"));
      }
    } else if (actionName === "reset") {
      getActivityTypeByIdApi();
      setIsOpenConfirmDialog(false);
    }

    setIsLoading(false);
  };

  const handleCancel = () => {
    setIsOpenConfirmDialog(false);
  };

  return (
    <div>
      <XeleDialog
        dialogTitle={t("confirm-activity-setup-change")}
        maxWidth={"sm"}
        isOpen={isOpen}
        dialogContent={
          <div>
            <Spinner isActive={isLoading} isAbsolute />
            <div className="my-3">{t("confirm-msg1")}</div>
            <div className="my-2">{t("confirm-msg2")}</div>

            <DialogActions>
              <CancelButton
                className="dialog-action-btn"
                onClick={handleCancel}
              >
                {t("cancel")}
              </CancelButton>

              <ConfirmButton
                className="dialog-action-btn"
                onClick={handleConfirm}
              >
                {actionName === "submit" ? t("submit") : t("reset")}
              </ConfirmButton>
            </DialogActions>
          </div>
        }
      />
    </div>
  );
}

export default ConfirmActionsDialog;
