/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { XeleDialog, XeleInput, Spinner } from '../../../../../Components';
import { TemplatebuilderComponent } from './Sections';
import { GetAllTemplateCategoryServices } from '../../../../../Services/SystemTemplateServices';

export const TemplatesBodyManagementDialog = ({
  activeItem,
  isOpen,
  isOpenChanged,
  parentTranslationPath,
  translationPath,
  onTemplateEmailBodyChanged,
  onTemplateEmailKeysChanged,
  setState,
  state,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [EmailKeys, setEmailKeys] = useState('');
  const [EmailBody, setEmailBody] = useState('');
  const [Data, setData] = useState([]);
  const [resonse, setResonse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onStateChanged = (newValue) => {
    setEmailKeys(newValue);
  };
  const onContentChanged = (newValue) => {
    setEmailBody(newValue);
  };
  const saveHandler = (event) => {
    event.preventDefault();
    onTemplateEmailKeysChanged(EmailKeys);
    onTemplateEmailBodyChanged(EmailBody);
    isOpenChanged();
  };
  useEffect(() => {
    resonse &&
      resonse.map((MainlEment) => {
        MainlEment.systemTemplateFormDtos.map((element) =>
          setData((items) => {
            items.push({
              systemReportFormId: 4,
              systemReportCategoryTypeId: MainlEment.systemTemplateCategoryId,
              formId: element.formId,
              tableName: element.formName,
              form: element.formName,
            });
            return [...items];
          })
        );
      });
  }, [resonse]);

  const getlTemplateCategory = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllTemplateCategoryServices(0, 10);
    const filteredData =
      res && res.result.filter((item) => item.categoryType === 'EmailBody');
    if (!(res && res.status && res.status !== 200))
      setResonse(filteredData || []);
    else setResonse([]);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (activeItem) {
      setEmailKeys(state && state.templateEmailKeys);
      setEmailBody(state && state.templateEmailBody);
    }
  }, [activeItem, state]);

  useEffect(() => {
    getlTemplateCategory();
  }, []);
  return (
    <XeleDialog
      titleText={(activeItem && 'edit-template-body') || 'add-template-body'}
      saveText={(activeItem && 'edit-template-body') || 'add-template-body'}
      maxWidth='xl'
      SmothMove
      disableEnforceFocus
      dialogContent={
        <div className='w-100'>
          <Spinner isActive={isLoading} isAbsolute />
          <div
            className='dialog-item-one-wrper'
            onDrop={(e) => {
              e.preventDefault();
              return false;
            }}
          />
          <div
            className='dialog-item'
            onDrop={(e) => {
              e.preventDefault();
              return false;
            }}
          />
          <div className='dialog-item-one'>
            <XeleInput
              idRef='mailSubjectRef'
              labelValue='E-mail-Subject'
              value={state.emailSubject || ''}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputChanged={(event) => {
                setState({ id: 'emailSubject', value: event.target.value });
              }}
            />{' '}
          </div>
          <TemplatebuilderComponent
            state={state}
            activeItem={activeItem}
            Data={Data}
            isTemplatesBody
            onStateChanged={onStateChanged}
            onContentChanged={onContentChanged}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        </div>
      }
      isOpen={isOpen}
      onSaveClicked={saveHandler}
      onCloseClicked={isOpenChanged}
      onCancelClicked={isOpenChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
