import React from "react";

export const TaskDynamicForm = ({
    dynamicFormOptions
}) => {
  return (
    <div className="view-wrapper pt-3">
      <div className="d-flex-column">
     
      </div>
    </div>
  );
};
