import React from 'react';
import ReactQuill from 'react-quill';
import { XeleDialog } from '../../Components';
import { Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
export const ActivityNotificationsDialog = ({
  isOpen,
  notifications,
  setIsOpen,
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared'], {
    keyPrefix: translationPath,
  });

  return (
    <XeleDialog
      disableBackdropClick
      isOpen={isOpen}
      translationPath={translationPath}
      parentTranslationPath='Shared'
      titleClasses='DialogComponent-WorkingHoursDialogView'
      wrapperClasses='wrapperClasses-WorkingHoursDialogView'
      titleText={t(`activities-notifications`)}
      onCloseClicked={() => setIsOpen(false)}
      maxWidth='sm'
      dialogContent={
        <>
          <div className='dialog-content-item w-100'>
            <div className='message-header-wrapper'>
              <label>{t(`date`)}</label>
              <div className='message-header'>
                {notifications && notifications.data && notifications.data.date}
              </div>
            </div>

            <div className='message-header-wrapper'>
              <label>{t(`from`)}</label>
              <div className='message-header'>
                <Chip
                  className='message-chip'
                  label={notifications && notifications.fullName}
                />
              </div>
            </div>

            <div className='message-body'>
              <ReactQuill
                idRef='emailref'
                readOnly
                placeholder={t(`Message`)}
                value={(notifications && notifications.body) || ''}
              />
            </div>
          </div>
        </>
      }
    />
  );
};
