import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  XeleDialog,
  Spinner,
  XeleAutocomplete,
} from '../../../../../../../Components';
import {
  AssignPolicyToInActiveUser,
  RemovePolicyFromInActiveUser,
} from '../../../../../../../Services/userServices';
import { showSuccess, showError } from '../../../../../../../Helper';
import {
  AssignPolicyToUserService,
  getAllPoliciesServices,
  RemovePolicyFromUserService,
} from '../../../../../../../Services/PoliciesServices';

export const AssignPolicyDialog = ({
  isOpen,
  onClose,
  onSave,
  userAPPId,
  userGUID,
  userPolicy,
  userStatus,
}) => {
  const translationPath = 'AssignPolicyDialog';
  const { t } = useTranslation('UsersView', {
    keyPrefix: translationPath,
  });
  const [isLoading, setIsLoading] = useState(false);
  const searchTimer = useRef(null);
  const [allPolicies, setAllPolicies] = useState([]);
  const [addPolicyUser, setAddPolicyUser] = useState(userPolicy);

  const assignPolicyToUser = async () => {
    setIsLoading(true);
    const params = {
      userId: userStatus === 'Active' ? userAPPId : userGUID,
      policyId: addPolicyUser?.policyId || null,
    };

    const res =
      userStatus === 'Active'
        ? await AssignPolicyToUserService(params)
        : await AssignPolicyToInActiveUser(params);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`Assign-Policy-To-User-successfully`));
      onSave();
    } else showError(t(`Assign-Policy-To-User-failed`));

    setIsLoading(false);
  };

  const removePolicyFromUser = async () => {
    setIsLoading(true);
    const res =
      userStatus === 'Active'
        ? await RemovePolicyFromUserService(
            userPolicy && userPolicy.policyUserId
          )
        : await RemovePolicyFromInActiveUser(
            userGUID,
            userPolicy?.policyUserId
          );
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`Remove-Policy-To-User-successfully`));
      onSave();
    } else showError(t(`Remove-Policy-To-User-failed`));
    setIsLoading(false);
  };

  const saveHandler = async () => {
    if (addPolicyUser) assignPolicyToUser();
    else removePolicyFromUser();
  };

  const getAllPolicies = async (search) => {
    setIsLoading(true);
    const res = await getAllPoliciesServices({
      pageIndex: 1,
      pageSize: 999999,
      search,
    });
    if (!(res && res.status && res.status !== 200)) {
      setAllPolicies(
        res.result.map((item) => ({
          policyId: item.policyDataId,
          policyName: item.policyDataName,
        }))
      );
    } else setAllPolicies([]);
    setIsLoading(false);
  };

  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    },
    []
  );

  useEffect(() => {
    getAllPolicies();
  }, []);

  return (
    <XeleDialog
      titleText={t(`user-policy`)}
      saveText={t(`confirm`)}
      saveType='button'
      maxWidth='sm'
      dialogContent={
        <div className='d-flex-column-center'>
          <Spinner isActive={isLoading} isAbsolute />
          <XeleAutocomplete
            idRef='AssignPolicyRef'
            labelValue={t(`AssignPolicy`)}
            data={allPolicies}
            multiple={false}
            displayLabel={(option) => (option && option.policyName) || ''}
            chipsLabel={(option) => (option && option.policyName) || ''}
            withoutSearchButton
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                getAllPolicies(value);
              }, 700);
            }}
            selectedValues={addPolicyUser}
            value={addPolicyUser}
            onChange={(e, newValue) => {
              setAddPolicyUser(newValue);
            }}
            isLoading={isLoading}
            withLoader
          />
        </div>
      }
      saveClasses='btns theme-solid bg-parimary  w-100 mx-2 mb-2'
      isOpen={isOpen}
      onSaveClicked={() => saveHandler()}
      onCloseClicked={() => onClose()}
      onCancelClicked={() => onClose()}
    />
  );
};
