import React, { memo, useState, useCallback, useEffect } from 'react';
import Collapse from '@material-ui/core/Collapse';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ButtonBase from '@material-ui/core/ButtonBase';
import { GlobalNavigate } from '../../../../Helper';
import { XeleCalendar } from '../../../../Components/CalendarComponent/XeleCalendar';
import { ActivitiesManagementDialog } from '../../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog';
import { Spinner } from '../../../../Components';
import { GetMyActivities } from '../../../../Services/ActivitiesServices';

const translationPath = 'eventsView';
export const EventsView = memo(({ isOpen, top }) => {
  const { t } = useTranslation('HeaderView', {
    keyPrefix: translationPath,
  });
  const [date, setDate] = useState(
    moment().format('YYYY-MM-DDTHH:mm:ssZ').toString()
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [activeMonth, setActiveMonth] = useState(
    moment().format('YYYY-MM-DDTHH:mm:ssZ')
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [activity, setActivity] = useState(null);
  const [events, setEvents] = useState([]);
  const getMyActivities = useCallback(async () => {
    setIsLoading(true);

    const isCurrentMonth = moment().month() === moment(activeMonth).month();
    const activeMonthIndex = isCurrentMonth
      ? new Date(activeMonth).getMonth()
      : new Date(activeMonth).getMonth() - 1;

    const startDate = moment(
      new Date(new Date(activeMonth).getFullYear(), activeMonthIndex, 1)
    ).format();
    const endDate = moment(
      new Date(new Date(activeMonth).getFullYear(), activeMonthIndex + 1, 0)
    ).format();
    const result = await GetMyActivities(startDate, endDate);
    if (!((result && result.data && result.data.ErrorId) || !result)) {
      if (!result) return;
      const newEvents = [];
      const dates = [];
      if (result && result[0]) {
        result.map((item) => {
          dates.push(+moment(item.activityDate).format('D'));
          newEvents.push({
            hour: moment(item.activityDate).format('hh:mm'),
            date: item.activityDate,
            subject: item.subject,
            activity: item,
          });
        });
      }
      setSelectedDates(dates);
      setEvents(newEvents);
    }
    setIsLoading(false);
  }, [activeMonth]);
  const calendarHandler = () => GlobalNavigate('/home/monthly-calendar-view');
  useEffect(() => {
    getMyActivities();
  }, [getMyActivities, activeMonth]);
  const onMonthChange = useCallback((value) => {
    setActiveMonth(value);
  }, []);
  return (
    <>
      <Collapse
        in={isOpen}
        className='collapses absolute-collapse activity-events-wrapper'
        style={{ top }}
      >
        <div className='cards notification-popup'>
          <Spinner isActive={isLoading} isAbsolute />
          <div className='card-header'>
            <p className='texts-large'>{`${t(
              `user-notification`
            )}`}</p>
            <p>{`${events && events.length} ${t(`new`)}`}</p>
          </div>
          <div className='card-content p-0'>
            <XeleCalendar
              selectedDateChanged={(newDate) => setDate(new Date(newDate))}
              selectedDate={date}
              withNumber
              onMonthChange={onMonthChange}
              activities={events}
              events={{ selectedDays: selectedDates }}
              wrapperClasses='transparent-calender'
            />
            <div className='events-container'>
              {events
                .filter(
                  (item) =>
                    moment(item.date).format('D') === moment(date).format('D')
                )
                .map((item, index) => (
                  <React.Fragment key={`notifications${index + 1}`}>
                    <ButtonBase
                      onClick={() => {
                        setActivity(item.activity);
                        setOpenDialog(true);
                      }}
                      className='btns theme-wide events-button px-3'
                    >
                      <div className='event-item-wrapper'>
                        <div className='event-badge-subject-wrapper'>
                          <div className='events-badge' />
                          <div className='d-inline-flex-column-center-v mr-4 ml-4'>
                            <span className='texts-large event-badge-text mb-2'>
                              {item.subject}
                            </span>
                            <span className='c-gray-light'>
                              {moment(item.date).format('DD/MM/YYYY')}
                            </span>
                          </div>
                        </div>
                        <div className='events-hour-badge'>{item.hour}</div>
                      </div>
                    </ButtonBase>
                  </React.Fragment>
                ))}
            </div>
            <div className='separator-h mb-3 top-m5px' />
          </div>
          <div className='view-all-wrapper'>
            <ButtonBase
              className='btns theme-outline'
              onClick={calendarHandler}
            >
              <span>{t('notificationsView.view-all')}</span>
            </ButtonBase>
          </div>
        </div>
      </Collapse>
      {openDialog && (
        <ActivitiesManagementDialog
          open={openDialog}
          activeItem={activity && activity}
          onSave={() => {
            setOpenDialog(false);
            setActivity(null);
          }}
          close={() => {
            setOpenDialog(false);
            setActivity(null);
          }}
          parentTranslationPath='ActivitiesView'
          translationPath=''
          isGeneralDialog
        />
      )}
    </>
  );
});
EventsView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  top: PropTypes.number.isRequired,
};
