import React from 'react';
import './PageHeading.scss';
import { useTranslation } from 'react-i18next';

export const PageHeading = ({
  parentTranslationPath,
  translationPath,
  headerTitle,
  subTitle,
  subTitleClasses,
  headerTitleClasses,
  wrapperClasses,
  titleWithPercentage,
}) => {
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });

  return (
    <div className={`PageHeading mb-3 ${wrapperClasses || ''}`}>
      {!titleWithPercentage && titleWithPercentage !== '' ? (
        <h5 className={`fw-simi-bold m-0 mb-1 ${headerTitleClasses || ''}`}>
          {headerTitle ? t(`${headerTitle}`) : ''}
        </h5>
      ) : (
        <div className='title-with-chip mb-2'>
          <h5 className={`fw-simi-bold m-0 mb-1 header-title`}>
            {headerTitle ? t(`${headerTitle}`) : ''}
          </h5>
          {titleWithPercentage !== '' ? (
            <span className='title-chip'> {titleWithPercentage}</span>
          ) : (
            <span className=''></span>
          )}
        </div>
      )}
      <p className={`text-tertiary heading-subtitle ${subTitleClasses || ''}`}>
        {subTitle ? t(`${subTitle}`) : ''}
      </p>
    </div>
  );
};
