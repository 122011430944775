import React, { useRef } from 'react';
import {
  XeleAutocomplete,
  XeleDatePicker,
  PermissionsComponent,
  XeleSwitch,
} from '../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { UsersManagementPermissions } from '../../../../../../../Permissions';
import { getErrorByName } from '../../../../../../../Helper';
import { UserOperationTypeEnum } from '../../../../../../../Enums';
import moment from 'moment';

export const DialogBottomFields = ({
  translationPath,
  parentTranslationPath,
  setSelected,
  selected,
  data,
  schema,
  isSubmitted,
  functions,
}) => {
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });
  const searchTimer = useRef(null);

  return (
    <>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>
            {t(`Line-manager*`)}
          </span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <XeleAutocomplete
            idRef='LineManagerRef'
            inputPlaceholder={t(`select`)}
            wrapperClasses='w-100 mb-2'
            selectedValues={selected.reportsTo}
            data={data.systemUsers || []}
            displayLabel={(option) =>
              (option && option.fullName) || (option && option.firstName) || ''
            }
            multiple={false}
            withoutSearchButton
            isWithError
            isSubmitted={isSubmitted}
            helperText={getErrorByName(schema, 'reportsTo').message}
            error={getErrorByName(schema, 'reportsTo').error}
            onOpen={() => {
              if (data.systemUsers && data.systemUsers.length === 0)
                functions.getSystemUsers();
            }}
            onChange={(event, newValue) => {
              setSelected({ id: 'reportsTo', value: newValue });
            }}
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (searchTimer) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                functions.getSystemUsers(value);
              }, 1200);
            }}
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>
            {t(`reraExpiryDate`)}
          </span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <XeleDatePicker
            idRef='reraExpiryDateRef'
            placeholder='DD/MM/YYYY'
            value={selected.reraExpiryDate || null}
            onDateChanged={(newValue) => {
              setSelected({
                id: 'reraExpiryDate',
                value:
                  (newValue &&
                    moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) ||
                  null,
              });
            }}
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>
            {t(`Operation-type`)}
          </span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <XeleAutocomplete
            idRef='OperationTypeRef'
            inputPlaceholder={t(`select`)}
            wrapperClasses='w-100 mb-2'
            selectedValues={selected.operationType}
            data={Object.values(UserOperationTypeEnum)}
            displayLabel={(option) => option.title || ''}
            multiple={false}
            withoutSearchButton
            onChange={(_, newValue) => {
              setSelected({ id: 'operationType', value: newValue });
            }}
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>
            {t(`unitSaleType`)}
          </span>
        </div>
        <div className='w-75 mt-2 mb-1'>
          <XeleAutocomplete
            idRef='unitSaleTypeRef'
            inputPlaceholder={t(`select`)}
            wrapperClasses='w-100 mb-2'
            selectedValues={selected.unitSaleType}
            data={data.unitSaleTypes || []}
            displayLabel={(option) => option.lookupItemName || ''}
            multiple={false}
            withoutSearchButton
            onOpen={() => {
              if (data.unitSaleTypes && data.unitSaleTypes.length === 0)
                functions.getUnitSaleTypes();
            }}
            onChange={(_, newValue) => {
              setSelected({ id: 'unitSaleType', value: newValue });
            }}
          />
        </div>
      </div>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>
            {t(`Specialist-for`)}
          </span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <XeleAutocomplete
            idRef='developer-specialist-forRef'
            wrapperClasses='w-min-unset mb-2'
            selectedValues={selected.developerSpecialistFor}
            labelValue={t(`developer`)}
            multiple={false}
            data={data.developers || []}
            displayLabel={(option) =>
              option?.contact?.company_name ||
              (option?.contact?.first_name &&
                `${option?.contact?.first_name} ${option?.contact?.last_name}`) ||
              option.name ||
              ''
            }
            onOpen={() => {}}
            onChange={(_, newValue) => {
              setSelected({ id: 'developerSpecialistFor', value: newValue });
            }}
            onInputChange={(e) => {}}
            withoutSearchButton
            parentTranslationPath={parentTranslationPath}
          />
          <XeleAutocomplete
            idRef='property-specialist-forRef'
            wrapperClasses='w-min-unset mb-2'
            selectedValues={selected.propertySpecialistFor}
            labelValue={t(`property`)}
            multiple={false}
            data={data.properties || []}
            displayLabel={(option) =>
              option?.property?.property_name || option.name || ''
            }
            onChange={(_, newValue) => {
              setSelected({ id: 'propertySpecialistFor', value: newValue });
            }}
            withoutSearchButton
            parentTranslationPath={parentTranslationPath}
          />
        </div>
      </div>
      <PermissionsComponent
        permissionsList={Object.values(UsersManagementPermissions)}
        permissionsId={[UsersManagementPermissions.EditGroup.permissionsId]}
      >
        <div className='d-flex fj-start b-bottom'>
          <div className='w-33 mt-2 pt-1'>
            <span className='fw-simi-bold'>{t(`group`)}</span>
          </div>
          <div className='w-75 mt-2 mb-1 pt-1'>
            <XeleAutocomplete
              idRef='userGroupRef'
              inputPlaceholder={t(`select`)}
              selectedValues={selected.userGroup || []}
              wrapperClasses='w-100 mb-2'
              data={data.userGroups || []}
              displayLabel={(option) => t(`${option.name || ''}`)}
              withoutSearchButton
              multiple={false}
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelected({
                    id: 'edit',
                    value: {
                      ...selected,
                      userGroup: newValue,
                      policy: {
                        policyDataId: newValue.policyId,
                        policyDataName: newValue.policyName,
                      },
                      roles: newValue.userGroupRoles || [],
                    },
                  });
                } else {
                  setSelected({
                    id: 'edit',
                    value: {
                      ...selected,
                      userGroup: null,
                      policy: [],
                      roles: null,
                    },
                  });
                }
              }}
              onOpen={() => {
                if (data.userGroups && data.userGroups.length === 0)
                  functions.getUserGroups();
              }}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  functions.getUserGroups(value);
                }, 1200);
              }}
            />
          </div>
        </div>
      </PermissionsComponent>
      <PermissionsComponent
        permissionsList={Object.values(UsersManagementPermissions)}
        permissionsId={[UsersManagementPermissions.EditRoles.permissionsId]}
      >
        <div className='d-flex fj-start b-bottom'>
          <div className='w-33 mt-2 pt-1'>
            <span className='fw-simi-bold'>{t(`roles`)}</span>
          </div>
          <div className='w-75 mt-2 mb-1 pt-1'>
            <XeleAutocomplete
              idRef='rolesRef'
              inputPlaceholder={t(`select`)}
              selectedValues={selected.roles || []}
              wrapperClasses='w-100 mb-2'
              data={data.systemRoles || []}
              chipsLabel={(option) => option.rolesName || ''}
              displayLabel={(option) => t(`${option.rolesName || ''}`)}
              withoutSearchButton
              multiple
              getOptionSelected={(option) =>
                selected?.roles?.findIndex(
                  (item) => item.rolesId === option.rolesId
                ) !== -1 || ''
              }
              onChange={(event, newValue) => {
                setSelected({
                  id: 'edit',
                  value: {
                    ...selected,
                    roles: newValue,
                    userGroup: null,
                  },
                });
              }}
              onOpen={() => {
                if (data.systemRoles && data.systemRoles.length === 0)
                  functions.getSystemRoles();
              }}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  functions.getSystemRoles(value);
                }, 1200);
              }}
            />
          </div>
        </div>
      </PermissionsComponent>
      <PermissionsComponent
        permissionsList={Object.values(UsersManagementPermissions)}
        permissionsId={[UsersManagementPermissions.EditPolicy.permissionsId]}
      >
        <div className='d-flex fj-start b-bottom'>
          <div className='w-33 mt-2 pt-1'>
            <span className='fw-simi-bold'>
              {t(`policy`)}
            </span>
          </div>
          <div className='w-75 mt-2 mb-1 pt-1'>
            <XeleAutocomplete
              idRef='policysRef'
              inputPlaceholder={t(`select`)}
              selectedValues={selected.policy}
              wrapperClasses='w-100 mb-2'
              data={data.systemPolicies || []}
              displayLabel={(option) => option?.policyDataName || ''}
              withoutSearchButton
              multiple={false}
              onChange={(event, newValue) => {
                setSelected({
                  id: 'edit',
                  value: {
                    ...selected,
                    policy: newValue,
                    userGroup: null,
                  },
                });
              }}
              onOpen={() => {
                if (data.systemPolicies && data.systemPolicies.length === 0)
                  functions.getSystemPolicies();
              }}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  functions.getSystemPolicies(value);
                }, 1200);
              }}
            />
          </div>
        </div>
      </PermissionsComponent>
      <div className='d-flex fj-start b-bottom'>
        <div className='w-33 mt-2 pt-1'>
          <span className='fw-simi-bold'>
            {t(`required-login`)}
          </span>
        </div>
        <div className='w-75 mt-2 mb-1 pt-1'>
          <div>
            <XeleSwitch
              idRef='CRMRef'
              themeClass='thick-theme'
              isChecked={selected.crmLogin || false}
              labelValue='CRM'
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onChangeHandler={(event, isChecked) => {
                setSelected({ id: 'crmLogin', value: isChecked });
              }}
            />
          </div>
          <div>
            <XeleSwitch
              idRef='MyleadsRef'
              themeClass='thick-theme'
              isChecked={selected.myLeadsLogin || false}
              labelValue='Myleads'
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onChangeHandler={(event, isChecked) => {
                setSelected({ id: 'myLeadsLogin', value: isChecked });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
