import React, { useEffect, useState } from 'react'; // useEffect,
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-cycle
import { XeleInput, UploaderComponent } from '..';
import { getDownloadableLink } from '../../Helper';
import { MediaPreviewDialog } from '../../Views/Home/TemplatesView/Dialogs';

export const UploaderActivitieFileComponent = ({
  state,
  uploadedFile,
  onCloseClicked,
  onUploaderActivitieChange,
}) => {
  const { t } = useTranslation('Shared');
  const [inputList, setInputList] = useState([
    {
      fileName: '',
      uuid: null,
      fullfileName: null,
    },
  ]);
  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [newFileName, setNewFileName] = useState('');
  const handleInputChange = (e, name, index) => {
    const value = e;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        fileName: '',
        uuid: null,
        fullfileName: null,
      },
    ]);
  };

  useEffect(() => {
    if (uploadedFile && uploadedFile.length > 0) {
      const initialUploadedFiles = uploadedFile.map((element) => ({
        fileName: element.fileName || '',
        uuid: element.fileId || element.uuid,
        fullfileName: element.fullfileName,
        isNotUploaded: element.isNotUploaded,
      }));

      setInputList([...initialUploadedFiles]);
    }
  }, [state]);

  return (
    <div className='UploaderActivitieFileComponent-wrapper'>
      <span className='d-flex mb-2 fw-bold c-black-light'>
        {t(`uplode-File`)}
      </span>
      <div className='form-wrapper'>
        {inputList.map((item, i) => (
          <>
            <div className='form-item'>
              {/* <div className='part-1 Inputs mt-2 w-100'>
                <Inputs
                  idRef='infantFee'
                  translationPath={translationPath}
                  parentTranslationPath='Shared'
                  isWithError
                  inputPlaceholder='File-name'
                  value={item.fileName || ''}
                  onInputChanged={(event) => {
                    handleInputChange(event.target.value, 'fileName', i);
                    setNewFileName(event.target.value)
                  }}
                />
              </div> */}
              <div className='Uploader w-100'>
                <UploaderComponent
                  idRef='leadsImportRef'
                  accept='*'
                  chipHandler={(value) => () => {
                    const link = document.createElement('a');
                    link.setAttribute('download', value.fileName);
                    link.href = getDownloadableLink(value.uuid);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                  }}
                  // initUploadedFiles={item.uuid || []}
                  initUploadedFiles={
                    (item.uuid && [
                      {
                        uuid: item.uuid,
                        fileName: item.fileName,
                      },
                    ]) ||
                    []
                  }
                  uploadedChanged={(files) => {
                    handleInputChange(
                      (files && files.length > 0 && files[0].uuid) || null,
                      'uuid',
                      i
                    );
                    handleInputChange(
                      (files && files.length > 0 && files[0].fileName) || null,
                      'fileName',
                      i
                    );
                  }}
                  //  newFileName = {newFileName}
                  // allFilesChanged={(files) => setCurrentUploadedFiles(files)}
                  // defaultImage={DefaultImagesEnum.individual.key}
                  // uploaderTheme={UploaderThemesEnum.boitem.key}
                  isDisabled={item.isNotUploaded}
                />
              </div>

              {inputList.length !== 1 && (
                <div className='part-add mx-1'>
                  <Tooltip
                    size='small'
                    title={t(`delete`)}
                    onClick={() => handleRemoveClick(i)}
                  >
                    <Fab disabled={item.isNotUploaded}>
                      <DeleteIcon fontSize='small' />
                    </Fab>
                  </Tooltip>
                </div>
              )}

              <div className='part-add mx-1'>
                <Tooltip
                  size='small'
                  title={t(`view`)}
                  aria-label='view'
                  onClick={() => {
                    setIsOpenPreviewDialog(true);
                    setActiveItem({
                      templateFileId: item.uuid,
                      templateFileName: item.fileName,
                      templateText: '',
                    });
                  }}
                >
                  <Fab
                    className={
                      item.fileName === ''
                        ? 'addIcondisactive'
                        : 'addIconactive'
                    }
                    disabled={item.fileName === null}
                  >
                    <span className='table-action-icon mdi mdi-eye-outline' />
                  </Fab>
                </Tooltip>
              </div>
              {/* Disable to upload more one file untile api */}
              {inputList.length - 1 === i && (
                <div className='part-add'>
                  <Tooltip
                    size='small'
                    title={t(`add`)}
                    aria-label='add'
                    onClick={handleAddClick}
                  >
                    <Fab
                      className={
                        item.fileName === ''
                          ? 'addIcondisactive'
                          : 'addIconactive'
                      }
                      disabled={item.uuid === null}
                    >
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </div>
              )}
            </div>
          </>
        ))}
      </div>
      {isOpenPreviewDialog && (
        <MediaPreviewDialog
          activeItem={activeItem}
          maintitleText='FILE-VIEW'
          isOpen={isOpenPreviewDialog}
          isOpenChanged={() => {
            setIsOpenPreviewDialog(false);
            setActiveItem(null);
          }}
          parentTranslationPath='Shared'
        />
      )}
      <div className='MuiDialogActions-root MuiDialogActions-spacing mt-3'>
        <ButtonBase
          className='MuiButtonBase-root btns theme-solid bg-cancel'
          onClick={onCloseClicked}
        >
          {t(`cancel`)}
          <span className='MuiTouchRipple-root' />
        </ButtonBase>
        <ButtonBase
          className='MuiButtonBase-root btns theme-solid'
          disabled={inputList.length === 0 || inputList[0].uuid === null}
          onClick={() => {
            onUploaderActivitieChange(inputList);
            onCloseClicked();
          }}
        >
          <span className='mdi mdi-folder-plus-outline px-2' />{' '}
          <span className='px-2'> {t(`Add-files`)} </span>
          <span className='MuiTouchRipple-root ' />
        </ButtonBase>
      </div>
    </div>
  );
};

UploaderActivitieFileComponent.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  onCloseClicked: PropTypes.func.isRequired,
  onUploaderActivitieChange: PropTypes.func.isRequired,
};
