import React, { useCallback, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess } from '../../../../../Helper';
import {
  XeleAutocomplete,
  XeleDialog,
  Spinner,
} from '../../../../../Components';
import CreateFolder from '../../../../../assets/images/GIF_images/Create_Folder.gif';
import { GetAllSystemTemplateFolders } from '../../../../../Services/SystemTemplateServices';
import {
  GetTamplateDetailsById,
  UpdateTemplate,
} from '../../../../../Services/TemplatesServices';

export const MoveToFolderDialog = ({
  isOpen,
  isOpenChanged,
  reloadData,
  parentTranslationPath,
  translationPath,
  activeItem,
}) => {
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });
  const [SystemTemplateUnitStatuses, setSystemTemplateUnitStatuses] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedtemplateFolderId, setselectedtemplateFolderId] = useState([
    {
      systemTemplateFolderName: null,
      systemTemplateFolderId: null,
    },
  ]);
  const [FolderData, setFolderData] = useState([]);
  const GetAllSystemTemplateFoldersAPI = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllSystemTemplateFolders(1, 50000);
    if (!(res && res.status && res.status !== 200))
      setFolderData((res && res.result) || []);
    else setFolderData([]);
    setIsLoading(false);
  }, []);
  useEffect(() => {
    GetAllSystemTemplateFoldersAPI();
  }, []);

  const CreateFolderHandler = async () => {
    setIsLoading(true);
    const res = await UpdateTemplate(activeItem.systemTemplateId, {
      templateName: activeItem.templateName,
      templateBody: activeItem.templateBody,
      templateKeys: activeItem.templateKeys,
      templateParams: activeItem.templateParams,
      templateTypeId: activeItem.templateTypeId,
      unitTypesIds: activeItem.unitTypesIds,
      systemTemplateFolderId: selectedtemplateFolderId.systemTemplateFolderId,
      emailSubject: activeItem.emailSubject,
      templateExtraCategoryIds: activeItem.templateExtraCategoryId,
      templateCategoryId:
        activeItem.systemTemplateCategoryDto.systemTemplateCategoryId,
      systemTemplateUnitStatuses: SystemTemplateUnitStatuses || [],
    });
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) {
      if (activeItem)
        showSuccess(t(`template-moived-successfully`));
      else showSuccess(t(`template-moived-successfully`));
      reloadData();
      isOpenChanged();
    } else if (activeItem)
      showError(t(`template-moived-failed`));
    else showError(t(`template-moived-failed`));
    setIsLoading(false);
  };
  // eslint-disable-next-line camelcase

  const GetTamplateDetailsByIdAPI = useCallback(async (Id) => {
    const response = await GetTamplateDetailsById(Id);
    if (!(response && response.status && response.status !== 200))
      setSystemTemplateUnitStatuses(
        (response && response.systemTemplateUnitStatuses) || []
      );
    else setSystemTemplateUnitStatuses([]);
  }, []);

  useEffect(() => {
    if (activeItem)
      GetTamplateDetailsByIdAPI(activeItem && activeItem.systemTemplateId);
  }, [activeItem]);

  return (
    <XeleDialog
      titleText='confirm-message'
      saveText='confirm'
      saveType='button'
      maxWidth='sm'
      dialogContent={
        <div className='d-flex-column-center'>
          <Spinner isActive={isLoading} isAbsolute />
          <img
            alt='CreateFolder'
            src={CreateFolder || null}
            style={{ width: '99px', height: '82px' }}
          />
          <XeleAutocomplete
            inputPlaceholder='select-folder'
            idRef='folder-Type'
            labelValue='folder'
            selectedValues={selectedtemplateFolderId || []}
            multiple={false}
            data={FolderData || []}
            displayLabel={(option) => option.systemTemplateFolderName || ''}
            getOptionSelected={(option) =>
              option.systemTemplateFolderId ===
              selectedtemplateFolderId.systemTemplateFolderId
            }
            withoutSearchButton
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChange={(event, newValue) => {
              if (newValue) {
                setselectedtemplateFolderId({
                  systemTemplateFolderName:
                    (newValue && newValue.systemTemplateFolderName) || null,
                  systemTemplateFolderId:
                    (newValue && newValue.systemTemplateFolderId) || null,
                });
              } else setselectedtemplateFolderId([]);

              // setState({ id: 'systemTemplateFolderId', value: newValue && +newValue.systemTemplateFolderId || '' });
            }}
          />
        </div>
      }
      saveClasses='btns theme-solid bg-primary w-100 mx-2 mb-2'
      isOpen={isOpen}
      onSaveClicked={CreateFolderHandler}
      onCloseClicked={isOpenChanged}
      onCancelClicked={isOpenChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
MoveToFolderDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
