import { config } from '../../config';
import { HttpServices } from '../../Helper';

export const CreateProjectTask = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/ProjectTasks/CreateProjectTask`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetProjectTaskAssignees = async () => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/ProjectTasks/GetProjectTaskAssignees`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetMyTasks = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/ProjectTasks/GetMyTasks`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetProjectTaskById = async (id) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/ProjectTasks/GetProjectTaskById/${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetProjectTasks = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/ProjectTasks/GetProjectTasks`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const ChangeProjectTaskStatus = async (body, isCompleted) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/ProjectTasks/ChangeProjectTaskStatus?isCompleted=${isCompleted}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
