import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Spinner } from '../../../Components';
import { FormsIdsEnum } from '../../../Enums';
import { useTitle } from '../../../Hooks';
import { sideMenuIsOpenUpdate, sideMenuComponentUpdate } from '../../../Helper';
import {
  MyReferralsCardDetailsComponent,
  MyReferralsTableView,
  UpdateActivityDialog,
} from './MyReferralsUtilities';
import { GetAllSearchableFormFieldsByFormId } from '../../../Services/FormsServices';

const parentTranslationPath = 'MyReferralsView';
export const MyReferralsView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [searchableFormFields, setSearchableFormFields] = useState([]);
  const [filterSearchDto, setFilterSearchDto] = useState(null);
  const [activeFormType, setActiveFormType] = useState(0);
  const [leadType, setLeadType] = useState(0);
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: orderFilter.MyReferralsFilter.filterBy,
    orderBy: orderFilter.MyReferralsFilter.orderBy,
  });
  const [orderBy, setOrderBy] = useState(
    selectedOrderBy.filterBy ? selectedOrderBy : {}
  );
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
    search: '',
  });
  const [detailsLeadsList, setDetailsLeadsList] = useState(() => ({
    result: [],
    totalCount: 0,
  }));
  const getAllSearchableFormFieldsByFormId = useCallback(async () => {
    const result = await GetAllSearchableFormFieldsByFormId(
      FormsIdsEnum.leadsOwner.id
    );
    if (!(result && result.status && result.status !== 200)) {
      setSearchableFormFields(
        result &&
          result
            .filter((item) => item.isSearchable)
            .map((item) => ({
              key: item.searchableKey,
              title: item.formFieldTitle,
            }))
      );
    } else setSearchableFormFields([]);
  }, []);
  const focusedRowChanged = useCallback(
    (rowIndex) => {
      setActiveItem(detailsLeadsList.result[rowIndex]);
      if (rowIndex !== -1) {
        sideMenuComponentUpdate(
          <MyReferralsCardDetailsComponent
            setOpenConfirmDialog={setOpenConfirmDialog}
            parentTranslationPath={parentTranslationPath}
            activeData={detailsLeadsList.result[rowIndex]}
          />
        );
        sideMenuIsOpenUpdate(true);
      } else {
        sideMenuComponentUpdate(<></>);
        sideMenuIsOpenUpdate(false);
      }
    },
    [detailsLeadsList.result]
  );
  useEffect(
    () => () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );
  useEffect(() => {
    getAllSearchableFormFieldsByFormId();
  }, [getAllSearchableFormFieldsByFormId]);
  useTitle(t(`my-referrals`));
  const getAllMyReferrals = useCallback(async () => {
    setIsLoading(true);
    const res = [];
    if (!(res && res.status && res.status !== 200)) {
      setDetailsLeadsList({
        result: ((res && res.result) || []).map((item) => ''),
      });
    } else {
      setDetailsLeadsList({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, [activeFormType, filter, filterSearchDto, leadType, orderBy, t]);
  useEffect(() => {
    getAllMyReferrals();
  }, [filter, getAllMyReferrals]);
  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    },
    []
  );
  return (
    <div className='my-leads-wrapper view-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='d-flex-column'>
        <div className='header-section'>
          <div className='filter-section px-1'>
            <div className='section pt-3'></div>
          </div>
        </div>
        <MyReferralsTableView
          filter={filter}
          myLeads={detailsLeadsList}
          setFilter={setFilter}
          focusedRowChanged={focusedRowChanged}
          parentTranslationPath={parentTranslationPath}
        />
      </div>
      {openConfirmDialog && (
        <UpdateActivityDialog
          isOpen={openConfirmDialog}
          activeItem={activeItem}
          reloadData={() => {
            setActiveItem(null);
            setOpenConfirmDialog(false);
            sideMenuComponentUpdate(null);
            sideMenuIsOpenUpdate(false);
          }}
          isOpenChanged={() => {
            setActiveItem(null);
            setOpenConfirmDialog(false);
          }}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};
