import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getDownloadableLink } from '../../Helper';
import { ButtonBase } from '@material-ui/core';
import { LoadableDocumentComponent } from '..';
import { MediaPreviewDialog } from '../../Views/Home/TemplatesView/Dialogs';
import { ActivityNotificationsDialog } from '../../SharedComponents/ActivityNotificationsDialog/ActivityNotificationsDialog';
import { InputThemeComponent } from '../UploaderComponent/UploaderThemes/InputThemeComponent/InputThemeComponent';
import { GetActivityNotification } from '../../Services/ActivitiesServices';
import { uploadFile } from '../../Services/FileManagerServices/FileManagerServices';

export const ActivityFilesUploader = ({
  state,
  setState,
  accept,
  translationPathShared,
  chipsDisabled,
  chipClicked,
  activeItem,
  isReplyDialog,
  canSelectOneDocument,
  multiple,
  initFilesChangeAllowed,
  isDisabled,
  isLoadingModelsImageUploading,
  setIsLoadingModelsImageUploading,
}) => {
  const translationPath = '';
  const { t } = useTranslation('Shared', { keyPrefix: translationPath });
  const { pathname } = useLocation();
  const uploadRef = useRef(null);
  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false);
  const [isNotificationDialogOpen, setIsNotificationDialogOpen] =
    useState(false);
  const [viewedFile, setViewedFile] = useState(null);
  const [activityNotifications, setActivityNotifications] = useState(null);

  const handleDownloadClick = (value) => () => {
    const link = document.createElement('a');
    link.setAttribute('download', value.fileName);
    link.href = getDownloadableLink(value.uuid ?? value.fileId);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleDeleteClick = useCallback((deleteFile) => () => {
    const deleteFileIndex =
      state.files &&
      state.files.findIndex(
        (item) => deleteFile.uuid === item.uuid ?? item.fileId
      );

    if (deleteFileIndex !== -1) {
      const localFiles = [...(state.files || [])];
      localFiles.splice(deleteFileIndex, 1);

      setState({ id: 'files', value: localFiles });
    }
  });

  const handleUploadChange = async (event) => {
    if (!event.target.files) return;

    setIsLoadingModelsImageUploading((item) => ({
      ...item,
      uploadingImages: true,
    }));

    const filesToUpload = Array.from(event.target.files);
    let stateFiles = state.files || [];

    for (const file of filesToUpload) {
      try {
        const response = await uploadFile({ file });
        const uploadedFile = {
          ...response,
          isUploaded: true,
          fileId: response.uuid,
        };
        stateFiles.push(uploadedFile);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }

    setState({ id: 'files', value: stateFiles });
    setIsLoadingModelsImageUploading((item) => ({
      ...item,
      uploadingImages: false,
    }));
  };

  const getIsFilesChangeAllowed = (item) => {
    return !chipsDisabled()
      ? initFilesChangeAllowed || (!initFilesChangeAllowed && item.isUploaded)
      : false;
  };
  const getActivityNotification = useCallback(async (activityId) => {
    const response = await GetActivityNotification(activityId);
    if (!(response && response.status && response.status !== 200)) {
      setActivityNotifications(response || null);
    } else setActivityNotifications(null);
  }, []);

  useEffect(() => {
    if (activeItem && !isReplyDialog) {
      const isContactActivitiesView = pathname.includes('contact-profile-edit');
      const isContactCenterActivitiesView =
        pathname.split('/home/')[1] === 'Activities';

      if (isContactActivitiesView || isContactCenterActivitiesView)
        getActivityNotification(activeItem.activityId);
    }
  }, [activeItem]);

  return (
    <div className='ActivityFilesUploader-wrapper'>
      <div className='Uploader w-100'>
        <input
          ref={uploadRef}
          type='file'
          multiple={multiple}
          accept={accept}
          onChange={handleUploadChange}
          style={{ display: 'none' }}
          disabled={isDisabled}
        />
        <InputThemeComponent
          allFiles={state.files || []}
          uploadRef={uploadRef}
          translationPathShared={translationPathShared}
          fileDeleted={handleDeleteClick}
          multiple={multiple}
          accept={accept}
          chipsDisabled={chipsDisabled}
          chipClicked={chipClicked}
          canSelectOneDocument={canSelectOneDocument}
        />
      </div>

      <div className='uploaded-files-container'>
        {state.files &&
          state.files.map((item) => (
            <div className='file-card'>
              <div className='card-body'>
                <div className='card-image'>
                  <LoadableDocumentComponent
                    classes='doc-image'
                    fileName={item.fileName}
                    alt={t(`cover-image`)}
                  />
                  <span
                    className='mdi mdi-eye'
                    onClick={() => {
                      setIsOpenPreviewDialog(true);
                      setViewedFile({
                        templateFileId: item.uuid ?? item.fileId,
                        templateFileName: item.fileName,
                        templateText: '',
                      });
                    }}
                    title='View File'
                  ></span>
                </div>
                <span onClick={handleDownloadClick(item)} title={item.fileName}>
                  {item.fileName}
                </span>
              </div>
              {getIsFilesChangeAllowed(item) && (
                <div className='delete-btn' onClick={handleDeleteClick(item)}>
                  <span className='mdi mdi-close'></span>
                </div>
              )}
            </div>
          ))}
      </div>
      {activityNotifications && (
        <ButtonBase
          onClick={() => {
            setIsNotificationDialogOpen(true);
          }}
          className='btns theme-solid bg-cancel notification-btn'
        >
          <span className='mdi mdi-bell c-warning px-2' />{' '}
          {t(`view-notifications`)}
        </ButtonBase>
      )}
      {isOpenPreviewDialog && (
        <MediaPreviewDialog
          activeItem={viewedFile}
          maintitleText='FILE-VIEW'
          isOpen={isOpenPreviewDialog}
          isOpenChanged={() => {
            setIsOpenPreviewDialog(false);
            setViewedFile(null);
          }}
          parentTranslationPath='Shared'
          translationPath={translationPath}
        />
      )}
      <ActivityNotificationsDialog
        isOpen={isNotificationDialogOpen}
        setIsOpen={(value) => {
          setIsNotificationDialogOpen(value);
        }}
        notifications={activityNotifications}
        translationPath={translationPath}
        // parentTranslationPath={parentTranslationPath}
      />
    </div>
  );
};

ActivityFilesUploader.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
};
ActivityFilesUploader.defaultProps = {
  chipsDisabled: () => false,
  chipClicked: () => false,
  isDisabled: false,
  setIsLoadingModelsImageUploading: () => {},
  initFilesChangeAllowed: true,
  accept:
    'image/*,application/pdf,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  translationPathShared: 'Shared:uploaderComponent.',
};
